import artifacts from './LiquidityMiningCampaign.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { Duration, durationToUnit, DurationUnits } from '../helpers/duration';
import { sendMultisig } from '../helpers/sendMultisig';

export class LiquidityMiningCampaign {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'LiquidityMiningCampaignV2',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deploy(
    stakingToken: string,
    rewardsTokens: Array<string>,
    stakeLimit: BigNumber,
    contractStakeLimit: BigNumber,
    name: string,
  ): Promise<void> {
    this.contract = await this.loader.deploy([stakingToken, rewardsTokens, stakeLimit, contractStakeLimit, name]);
  }

  async extend(duration: Duration, rewards: BigNumber[]): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const numSeconds = Math.round(durationToUnit(duration, DurationUnits.seconds));
    const rewardsPerSecond = rewards.map((reward) => reward.div(numSeconds).toString());

    return await sendMultisig(this.contract, 'extend', [numSeconds, rewardsPerSecond]);
  }

  async cancelExtension(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'cancelExtension', []);
  }

  async withdrawExcessRewards(recipient: string | undefined): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');
    if (recipient === undefined) throw new Error('Trying to use a undefined wallet');

    return await sendMultisig(this.contract, 'withdrawExcessRewards', [recipient]);
  }
}
