import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarProps } from '../../components/Snackbar';

export interface SnackbarState {
  snackbars: SnackbarProps[];
}

const initialState: SnackbarState = {
  snackbars: [],
};

export const snackbarSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addSnackbar: (state, action: PayloadAction<SnackbarProps>): void => {
      state.snackbars.push(action.payload);
    },
    removeSnackbar: (state, action: PayloadAction<number>): void => {
      state.snackbars.splice(action.payload, 1);
    },
  },
});
