import React from 'react';
import { InfiniteStakingPoolList } from '../../../components/InfiniteStakingPoolList';
import { InfiniteStakingPool, InfiniteStakingState } from '../../../components/InfiniteStakingPool';

export const InfiniteStakingPaused: React.FC = () => {
  return (
    <InfiniteStakingPoolList
      title="Paused pools"
      subTitle="List of paused pools"
      filter={(pool: InfiniteStakingPool): boolean => pool.state === InfiniteStakingState.EXPIRED}
    />
  );
};
