import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  AllEffect,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import {
  clearTempAdmins,
  confirmAdminsEdit,
  getAdminData,
  processAdminsEdit,
  setAdminAddresses,
  setAdminsMeta,
  setTempAdmins,
} from './actions';
import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import { getAuthAddress } from '../auth/selectors';
import { getEthersWeb3Provider } from '../ethers/web3/selectors';
import { Contract, ContractTransaction } from 'ethers';
import { geAdminAddresses } from './selectors';
import { getWalletContract } from '../ethers/multisig/selectors';
import { getUserByAddress, addUser, removeUser } from '../../services/user';
import { User } from '../../types/dto/User.dto';
import { BackendAdmin } from './slice';
import { addSnackbar } from '../snackbar/action';
import { METAMASK_POSSIBLE_ERRORS } from '../../utils/errorCodes';
import { sendMultisig } from '../../sdk/helpers/sendMultisig';

function* fetchAdminData(): Generator<
  PutEffect | CallEffect | SelectEffect | Promise<(User | undefined)[]>,
  void,
  string[] & MultiSigWallet & User[]
> {
  try {
    const contract = yield select(getWalletContract);
    const fromContract = yield call(contract.getSigners);
    yield put(setAdminAddresses(fromContract));

    const getUsers = [];
    for (let i = 0; i < fromContract.length; i++) {
      getUsers.push(getUserByAddress(fromContract[i]));
    }
    const users: User[] = yield Promise.all(getUsers);
    const fromBackend = users.map((user, index) => ({
      name: user?.user,
      email: user?.email,
      address: fromContract[index],
    }));
    yield put(setAdminsMeta(fromBackend));
  } catch (e) {
    console.error(e);
  }
}

function* updateAdmins({
  payload,
}: ReturnType<typeof processAdminsEdit>): Generator<
  | PutEffect
  | TakeEffect
  | CallEffect
  | SelectEffect
  | AllEffect<CallEffect>
  | Promise<(User | undefined)[]>
  | Promise<string[]>,
  void,
  ReturnType<typeof confirmAdminsEdit> & string[] & MultiSigWallet & ContractTransaction[] & User[]
> {
  try {
    yield put(
      setTempAdmins(payload.map(({ name, email, address }) => ({ address: address, name: name, email: email }))),
    );

    const confirm = yield take(confirmAdminsEdit);

    const correctAddresses = yield select(geAdminAddresses);
    // diff admin changes
    let added = payload
      .filter((signer) => !correctAddresses.some((address) => address === signer.address))
      .map((signer) => signer.address);
    const removed = correctAddresses.filter((address) => !payload.some((signer) => signer.address === address));
    const provider = yield select(getEthersWeb3Provider);
    if (!provider) throw Error('no provider');
    const wallet = yield select(getAuthAddress);
    const timestamp = Date.now();

    const signature = yield (provider.signer as any).signMessage(`Verification message: ${timestamp.toString()}`);

    const addedFull = payload.filter((signer) => !correctAddresses.some((address) => address === signer.address));

    let checkUser;

    for (let i = 0; i < addedFull.length; i++) {
      checkUser = yield call(
        addUser,
        addedFull[i].name,
        addedFull[i].email,
        addedFull[i].address,
        wallet,
        signature,
        timestamp,
      );
      if (!checkUser) {
        yield put(
          addSnackbar({
            type: 'error',
            title: 'Adding admin failed',
            content: addedFull[i].address + ': Wallet has been already registered.',
          }),
        );
        added = added.filter((signer) => addedFull[i].address !== signer);
      }
    }
    const contract = (yield select(getWalletContract)) as Contract;
    yield Promise.all(added.map((address) => sendMultisig(contract, 'addSigner', [address])));
    const removedtransactions = yield Promise.all(
      removed.map((address) => sendMultisig(contract, 'removeSigner', [address])),
    );

    if (typeof removedtransactions !== 'undefined' && removedtransactions.length > 0) {
      yield all(
        removedtransactions.map((transaction, index) =>
          call(removeUser, wallet, removed[index], signature, timestamp, transaction),
        ),
      );
    }
    // Wait for confirmations
    const getUsers = [];
    for (let i = 0; i < payload.length; i++) {
      getUsers.push(getUserByAddress(payload[i].address));
    }
    const users: User[] = yield Promise.all(getUsers);
    const fromBackend: BackendAdmin[] = [];
    const fromContract = payload.map(({ address }, index) => {
      fromBackend.push({ name: users[index].user, email: users[index].email, address });
      return address;
    });

    yield put(setAdminsMeta(fromBackend));
    // yield put(setAdminAddresses(fromContract));
    yield put(clearTempAdmins());

    confirm.payload();
  } catch (e: any) {
    console.error(e);
    if (METAMASK_POSSIBLE_ERRORS[e.code] !== undefined) {
      yield put(
        addSnackbar({
          type: 'error',
          title: 'Failed to add admin',
          content: e.code === 4001 ? e.message : METAMASK_POSSIBLE_ERRORS[e.code].message,
        }),
      );
    } else {
      yield put(
        addSnackbar({
          type: 'error',
          title: 'Failed to add admin',
          content: 'Transaction failed due to an error',
        }),
      );
    }
  }
}

export function* adminsSagaWatcher(): Generator {
  yield all([takeEvery(getAdminData, fetchAdminData), takeLatest(processAdminsEdit, updateAdmins)]);
}
