import React from 'react';
import { Icons } from '../../assets/icons/index';

export interface SeparatorProps {
  /*
      Left component
  */
  leftComponent?: React.ReactNode;
  /*
      Right component
  */
  rightComponent?: React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const Separator: React.FC<SeparatorProps> = ({ leftComponent = null, rightComponent = null, ...props }) => {
  return (
    <div className="separator">
      <div className="left-component">{leftComponent}</div>
      <div className="filled" />
      <div className="right-component">{rightComponent}</div>
    </div>
  );
};
