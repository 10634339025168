import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeLatest,
} from 'redux-saga/effects';
import { SocialMedia } from '../../pages/Configuration/SocialMedia/FormInput';
import { ThirdPartyIntegrations } from '../../pages/Configuration/ThirdPartyIntegrations/FormInput';
import {
  confirmCreateMultisig,
  fetchProject,
  initializedProject,
  processCreateMultisig,
  setMultisig,
  setProject,
} from './action';
import { getProject, postProjectMultisig } from '../../services/tenant';
import { GetProject } from '../../types/dto/Project.dto';
import { AxiosResponse } from 'axios';
import { clearTempAdmins, setAdminAddresses, setAdminsMeta, setTempAdmins } from '../admins/actions';
import { Contract, ContractTransaction, ContractReceipt, providers } from 'ethers';
import { getEthersWeb3Provider } from '../ethers/web3/selectors';
import { Web3ProviderState } from '../ethers/web3/slice';
import { addUser } from '../../services/user';
import { getAuthAddress, getNetwork } from '../auth/selectors';
import { MultiSigWalletFactory } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWalletFactory';
import { getMultisigFactoryContract } from './getMultisigFactory';
import { Network } from '@ethersproject/networks';
import { MultisigNetworkds, ProjectState } from './slice';
import { getMultisigs } from './selectors';
import { addSnackbar } from '../snackbar/action';
import { hexToRgb, themeColorVariations } from '../../utils/colorManipulations';
import { getNetworkName } from '../../sdk/helpers/network';
import { setNetwork } from '../auth/actions';
import { getUsersByProjectId } from '../../services/users';
import { getProjectId } from '../../ducks/project/selectors';
import { DappsIntegration } from '../../pages/Configuration/DAppsIntegration/FormInput';

export function* updateProject({
  payload: { projectId, network },
}: ReturnType<typeof fetchProject>): Generator<
  CallEffect | PutEffect | SelectEffect | Promise<boolean> | Promise<string>,
  void,
  AxiosResponse<GetProject> & string
> {
  try {
    projectId = projectId || (yield select(getProjectId));

    network = network || (yield select(getNetwork));

    if (!network || !projectId) {
      throw new Error('network and/or projectid is not defined/resolved');
    }

    const response = yield call(getProject, projectId);

    const tokensConfig = response.data.project.config.tokens;
    const tokens: {
      [network: string]: {
        name: string;
        symbol: string;
        address: string;
      };
    } = (tokensConfig || []).reduce((acc, item) => {
      if (item.projectToken) {
        acc = {
          ...acc,
          [item.network]: {
            name: item.address,
            symbol: item.symbol,
            address: item.address,
          },
        };
      }
      return acc;
    }, {});

    yield put(
      setProject({
        pending: false,
        id: response.data.project._id,
        name: response.data.project.name || 'unknown',
        website: response.data.project.website || 'unknown',
        coinGeckoID: response.data.project.coinGeckoID || 'unknown',
        token: tokens[network]
          ? tokens[network]
          : {
              name: '',
              symbol: '',
              address: '',
            },
        tokens: tokens,
        multisig: {
          bsc: response.data.project.multisig?.bsc || null,
          eth: response.data.project.multisig?.eth || null,
          polygon: response.data.project.multisig?.polygon || null,
          avalanche: response.data.project.multisig?.avalanche || null,
          ewc: response.data.project.multisig?.ewc || null,
          moonbeam: response.data.project.multisig?.moonbeam || null,
          songbird: response.data.project.multisig?.songbird || null,
        },
        config: {
          factory: response.data.project.config?.factory || null,
          stakingFactory: response.data.project.config?.stakingFactory || null,
          uniswap: response.data.project.config?.fe?.uniswap || null,
          balancer: response.data.project.config?.fe?.balancer || null,
          pancakeswap: response.data.project.config?.fe?.pancakeswap || null,
          quickswap: response.data.project.config?.fe?.quickswap || null,
          pangolin: response.data.project.config?.fe?.pangolin || null,
          traderjoe: response.data.project.config?.fe?.traderjoe || null,
          pairsArray: response.data.project.config?.fe?.pairsArray || null,
          stakerArray: response.data.project.config?.fe?.stakerArray || null,
          staker: response.data.project.config?.fe?.staker || null,
          tokens: response.data.project.config?.tokens || [],
          campaignsInfiniteStaking: response.data.project.config?.campaignsInfiniteStaking || [],
          campaignsStaking: response.data.project.config?.campaignsStaking || [],
          campaignsLM: response.data.project.config?.campaignsLM || [],
        },
        brandColor: response.data.project.brandColor || '',
        contactEmail: response.data.project.contactEmail || '',
        logoUrl: response.data.project.logoUrl || '',
        socialMedia: '' || (JSON.parse((response.data.project.socialMedia || '{}').toString()) as SocialMedia),
        thirdPartyIntegrations:
          '' ||
          (JSON.parse((response.data.project.thirdPartyIntegrations || '{}').toString()) as ThirdPartyIntegrations),
        theme: response.data.project.theme || '',
        dappsIntegration:
          '' || (JSON.parse((response.data.project.dappsIntegration || '{}').toString()) as DappsIntegration),
      }),
    );

    localStorage.setItem('theme', response.data.project.theme || '');
    localStorage.setItem('brand_color', response.data.project.brandColor || '');
    if (response.data.project.brandColor) {
      localStorage.setItem('brand_primary', themeColorVariations[response.data.project.brandColor]['primary']);
      localStorage.setItem('brand_hover', themeColorVariations[response.data.project.brandColor]['hover']);
      localStorage.setItem('brand_secondary', themeColorVariations[response.data.project.brandColor]['secondary']);
      localStorage.setItem('brand_thirtiary', themeColorVariations[response.data.project.brandColor]['thirtiary']);
      localStorage.setItem('brand_element', themeColorVariations[response.data.project.brandColor]['element']);
      localStorage.setItem('brand_background', themeColorVariations[response.data.project.brandColor]['background']);
    }
    localStorage.setItem(
      'brand_color_shadow',
      response.data.project.brandColor && (hexToRgb(response.data.project.brandColor, 0.57) || ''),
    );
    localStorage.setItem('dapps_integration', JSON.stringify(response.data.project.dappsIntegration) || '');
    localStorage.setItem('contact_email', response.data.project.contactEmail || '');
    localStorage.setItem(
      'third_party_integrations',
      JSON.stringify(response.data.project.thirdPartyIntegrations) || '',
    );
    localStorage.setItem('social_media', JSON.stringify(response.data.project.socialMedia) || '');
    localStorage.setItem('logo_url', response.data.project.logoUrl || '');

    document.body.className = localStorage.getItem('theme') || 'light';
    if (localStorage.getItem('brand_color')) {
      document.body.style.setProperty('--brand-primary', localStorage.getItem('brand_primary'));
      document.body.style.setProperty('--brand-hover', localStorage.getItem('brand_hover'));
      document.body.style.setProperty('--brand-secondary', localStorage.getItem('brand_secondary'));
      document.body.style.setProperty('--brand-thirtiary', localStorage.getItem('brand_thirtiary'));
      document.body.style.setProperty('--brand-element', localStorage.getItem('brand_element'));
      document.body.style.setProperty('--brand-background', localStorage.getItem('brand_background'));
      document.body.style.setProperty('--blue-shadow', '0px 7px 28px ' + localStorage.getItem('brand_color_shadow'));
      document.body.style.setProperty(
        '--blue-tile-shadow',
        '0px 7px 19px ' + localStorage.getItem('brand_color_shadow'),
      );
      document.body.style.setProperty(
        '--blue-tile-hover-shadow',
        '0px 7px 22px ' + localStorage.getItem('brand_color_shadow'),
      );
    }

    yield put(initializedProject());
  } catch (e) {
    console.error(e);
  }
}

export function* initializeProject(
  projectId: string,
): Generator<
  CallEffect | PutEffect | SelectEffect | Promise<boolean> | Promise<string>,
  void,
  AxiosResponse<GetProject> & string
> {
  try {
    const network = yield getNetworkName();
    yield put(setNetwork(network));

    yield call(updateProject as any, { payload: { projectId, network } });
  } catch (e) {
    console.error(e);
  }
}

function* addMultisig({
  payload,
}: ReturnType<typeof processCreateMultisig>): Generator<
  CallEffect | PutEffect | TakeEffect | SelectEffect | Promise<string> | Promise<Network>,
  void,
  Web3ProviderState & string & ContractTransaction & ContractReceipt & Network & ProjectState['multisig']
> {
  try {
    yield put(setTempAdmins(payload.map(({ name, email, address }) => ({ address, name: name, email: email }))));

    yield take(confirmCreateMultisig);

    const web3 = yield select(getEthersWeb3Provider);
    if (!web3) throw Error('no web3');
    let addresses = payload.map(({ address }) => address);

    const timestamp = Date.now();
    const wallet = yield select(getAuthAddress);
    const signature = yield web3.signer.signMessage(`Verification message: ${timestamp.toString()}`);

    let checkUser;
    const projectId = yield select(getProjectId);
    const users = yield call(getUsersByProjectId, projectId);

    const userAddresses = (users as any).map(
      (user: { username: string; email: string; wallet: string }) => user.wallet,
    );

    const added = payload.map(({ name, email, address }) => ({ address, name: name, email: email }));
    for (let i = 0; i < added.length; i++) {
      if (added[i].address !== wallet && !userAddresses.includes(added[i].address)) {
        checkUser = yield call(addUser, added[i].name, added[i].email, added[i].address, wallet, signature, timestamp);
        if (!checkUser) {
          yield put(
            addSnackbar({
              type: 'error',
              title: 'Adding multisig failed',
              content: added[i].address + ': Wallet has been already registered.',
            }),
          );
          payload = payload.filter((signer) => added[i].address !== signer.address);
        }
      }
    }

    // get updated addresses
    addresses = payload.map(({ address }) => address);

    const network = yield web3.provider.getNetwork();
    const { abi, address } = getMultisigFactoryContract(network.chainId);
    const contract = new Contract(address, abi, web3.signer) as MultiSigWalletFactory;
    const tx = yield call(contract.deploy, addresses);

    const confirmation = yield call(tx.wait);
    const [event] = confirmation.events as Required<ContractReceipt>['events'];

    const networkName = yield select(getNetwork);
    const multisigs: ProjectState['multisig'] = yield select(getMultisigs);
    yield call(
      postProjectMultisig,
      signature,
      wallet,
      {
        ...Object.keys(multisigs).reduce((p, k) => ({ ...p, [k]: multisigs[k as MultisigNetworkds] || undefined }), {}),
        [networkName]: event.args?.multiSigAddress,
      },
      timestamp,
      addresses,
      networkName,
    );

    yield put(setMultisig({ address: event.args?.multiSigAddress, network: networkName as MultisigNetworkds }));
    yield put(setAdminsMeta(payload));
    yield put(setAdminAddresses(addresses));
    yield put(clearTempAdmins());
  } catch (e) {
    console.error(e);
  }
}

export function* projectSagaWatcher(): Generator {
  yield all([takeLatest(processCreateMultisig, addMultisig), takeLatest(fetchProject, updateProject)]);
}
