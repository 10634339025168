import React, { FC, useEffect, useState } from 'react';
import { Route, useLocation, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../ducks/auth/selectors';
import { AuthState } from '../../ducks/auth/slice';
import { getProjectConfigAll } from '../../ducks/project/selectors';
import { getNetworkName } from '../../sdk/helpers/network';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';

interface PrivateRouteProps extends RouteProps {
  redirectTo: string;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, redirectTo, ...props }) => {
  const location = useLocation();
  const authState = useSelector(getAuthState);
  const projectConfig = useSelector(getProjectConfigAll);

  const [network, setNetwork] = useState('');
  const provider = useSelector(getEthersWeb3Provider);

  useEffect(() => {
    if (!provider) return;
    getNetworkName().then((net): void => {
      setNetwork(net);
    });
    return (): void => {
      setNetwork('');
    };
  }, [provider]);

  const hasMultisig = !!projectConfig.multisig[
    network as 'eth' | 'bsc' | 'polygon' | 'avalanche' | 'ewc' | 'moonbeam' | 'songbird'
  ];
  /*   // leave this here just for development propouses
  return <Route {...props}>{children}</Route>; */

  if (authState === AuthState.INITIALIZATION || projectConfig.pending) {
    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: {
            from: location.pathname,
          },
        }}
      />
    );
  }

  if (authState === AuthState.AUTHORIZED) {
    return <Route {...props}>{children}</Route>;
  }

  return (
    <Redirect
      to={{
        pathname: redirectTo,
        state: {
          from: location.pathname,
        },
      }}
    />
  );
};
