import React from 'react';
import { Icons } from '../../assets/icons';
import { createDuration, durationToUnit, DurationUnits } from '../../sdk/helpers/duration';
import { formatDuration } from '../../utils/formatDuration';
import { formatNumber } from '../../utils/formatNumber';

export const PoolExpiration: React.FC<{ end: Date; start: Date }> = (props) => {
  const isExpired = props.end.getTime() - new Date().getTime() < 0;
  const expirationDuration = createDuration(props.end.getTime() - Date.now(), DurationUnits.milliseconds);
  const duration = createDuration(props.end.getTime() - props.start.getTime(), DurationUnits.milliseconds);
  return (
    <div className="pool-expiration">
      <div className="pool-expiration-text flex pool-content-header">Campaign Duration</div>
      <div className="pool-expiration-time flex">
        <div className="pool-expiration-time-date">{formatDuration(duration)}</div>
        <div className="pool-expiration-time-time">
          {formatNumber(durationToUnit(duration, DurationUnits.hours) % 24, 0)}h{' '}
          {formatNumber(durationToUnit(duration, DurationUnits.minutes) % 60, 0)}m{' '}
          {formatNumber(durationToUnit(duration, DurationUnits.seconds) % 60, 0)}s
        </div>
      </div>
      <div className="pool-expiration-text flex pool-content-header" style={{ marginTop: 24 }}>
        Expiration time
      </div>
      {isExpired ? (
        <div className="pool-expiration-time flex">
          <div className="pool-expiration-time-date">Expired</div>
        </div>
      ) : (
        <div className="pool-expiration-time flex">
          <div className="pool-expiration-time-date">{formatDuration(expirationDuration)}</div>
          <div className="pool-expiration-time-time">
            {formatNumber(durationToUnit(expirationDuration, DurationUnits.hours) % 24, 0)}h{' '}
            {formatNumber(durationToUnit(expirationDuration, DurationUnits.minutes) % 60, 0)}m{' '}
            {formatNumber(durationToUnit(expirationDuration, DurationUnits.seconds) % 60, 0)}s
          </div>
        </div>
      )}
    </div>
  );
};
