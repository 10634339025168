import { Contract, providers } from 'ethers';

export const getTierCampaign = async (campaignAddress: string, provider: providers.Provider): Promise<number> => {
  let tierCampaign = 5;

  try {
    tierCampaign = Number(
      await new Contract(
        campaignAddress,
        [
          {
            inputs: [],
            name: 'tierCampaign',
            outputs: [
              {
                name: '',
                type: 'uint8',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        provider,
      ).tierCampaign(),
    );
  } catch (e) {
    console.error(e);
  }

  return tierCampaign;
};
