import { UNSUPPORTED_NETWORK } from '../constants/errors';
import { networkConfigurations, NetworkConfiguration } from '../constants/networkConfig';
import { useWeb3 } from './useWeb3';

export const getNetworkId = async (): Promise<string> => {
  const web3 = useWeb3();
  const network = await web3.provider.getNetwork();
  const networkId = network.chainId;

  return networkId.toString();
};

export const getNetworkConfig = async (): Promise<NetworkConfiguration> => {
  const networkId = await getNetworkId();
  const networkConfiguration = networkConfigurations[networkId];

  if (!networkConfigurations[networkId]) {
    throw new Error(UNSUPPORTED_NETWORK);
  }

  return networkConfiguration;
};

export const getNetworkName = async (): Promise<any> => {
  const id = await getNetworkId();

  if (id === '97' || id === '56') {
    return 'bsc';
  }

  if (id === '137') {
    return 'polygon';
  }

  if (id === '43114' || id === '43113') {
    return 'avalanche';
  }

  if (id === '246') {
    return 'ewc';
  }

  if (id === '1284') {
    return 'moonbeam';
  }

  if (id === '19') {
    return 'songbird';
  }

  return 'eth';
};

export const getNetworkIdByName = (name: string): string => {
  switch (name) {
    case 'bsc':
      return '56';
    case 'polygon':
      return '137';
    case 'avalanche':
      return '43114';
    case 'ewc':
      return '246';
    case 'moonbeam':
      return '1284';
    case 'songbird':
      return '19';
    default:
      return '1';
  }
};
