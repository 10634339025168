import React, { useState, useEffect } from 'react';
import { Button } from '../Button';
export type TextFieldType = 'text' | 'password';

export interface TextFieldProps {
  /*
      Label string
  */
  label: string;
  /*
      Optional required
  */
  required?: boolean;
  /*
      Input name
  */
  name: string;
  /*
      unique id
  */
  id: string;
  disabled?: boolean;

  handleChange: (e: any) => void;
  maxHeight: number;
  maxWidth: number;
}

/**
 * Primary UI component for user interaction
 */
export const AddIconButton: React.FC<TextFieldProps> = ({
  label,
  maxHeight,
  maxWidth,
  required,
  id,
  disabled,
  handleChange,
  ...props
}) => {
  //
  const [value, setValue] = useState('');

  const onChangeFunc = (e: any): void => {
    if (e.target.files[0]) {
      setValue(URL.createObjectURL(e.target.files[0]));
    }
    handleChange(e);
  };

  return (
    <div
      className={`icon-button add-icon-button  ${value ? 'value' : disabled ? 'disabled' : ''}`}
      style={{ backgroundImage: `url(${value})` }}
    >
      <label className={`label-container header-light-medium ${disabled && 'disabled'}`} htmlFor={id}>
        {value ? 'Change' : label}
      </label>
      <input disabled={disabled} id={id} type="file" onChange={onChangeFunc} required={required} {...props} />
    </div>
  );
};
