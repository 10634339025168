import React from 'react';
import { LiquidityMiningPoolList } from '../../../components/LiquidityMiningPoolList';
import { LiquidityMiningPool } from '../../../components/LiquidityMiningPool';

export const AwaitingPools: React.FC = () => {
  return (
    <LiquidityMiningPoolList
      title="Awaiting pools"
      subTitle="List of awaiting pools (new pools can take a few minutes to show up here)"
      filter={(pool: LiquidityMiningPool): boolean => {
        return pool.start.getTime() === 0;
      }}
    />
  );
};
