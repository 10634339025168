import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from './FormInput';
import { StakingTitle } from '../../../components/StakingTitle';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getEthersWeb3Provider } from '../../../ducks/ethers/web3/selectors';
import axios from 'axios';
import { addSnackbar } from '../../../ducks/snackbar/action';
import { getSignature } from '../../../utils/getSignature';
import { METAMASK_POSSIBLE_ERRORS } from '../../../utils/errorCodes';
import { handleMetamaskError } from '../../../utils/handleError';
import { useAccount } from 'wagmi';

export const SocialMedia: React.FC = () => {
  const { address: wallet } = useAccount();

  const dispatch = useDispatch();

  const history = useHistory();
  const state: any = history.location.state;
  const web3 = useSelector(getEthersWeb3Provider);
  const config = useSelector(getProjectConfigAll);

  useEffect(() => {
    if (!state) history.push('/configuration/brand');
  }, [state]);

  const onSubmit = (data: any): void => {
    const newData = { ...state, social_media: data };
    // TODO: move this logic to sagas
    // const socialMedia = localStorage.getItem('social_media');
    newData['theme'] = newData['theme'] || localStorage.getItem('theme');
    newData['colors'] = newData['colors'] || localStorage.getItem('brand_color');
    newData['contact_email'] = newData['contact_email'] || localStorage.getItem('contact_email');
    newData['third_party_integrations'] = JSON.stringify(
      newData['third_party_integrations'] || localStorage.getItem('third_party_integrations'),
    );
    newData['social_media'] = JSON.stringify(data);

    (async (): Promise<void> => {
      const { signature, timestamp } = await getSignature(web3, dispatch);

      const formData = new FormData();
      formData.append('logo', newData['brand']);
      formData.append('theme', newData['theme']);
      formData.append('brandColor', newData['colors']);
      formData.append('contactEmail', newData['contact_email']);
      formData.append('socialMedia', newData['social_media']);
      formData.append('thirdPartyIntegrations', newData['third_party_integrations']);
      formData.append('wallet', wallet as string);
      formData.append('signature', signature);
      formData.append('timestamp', timestamp.toString());

      await axios.post(process.env.REACT_APP_API + '/project/theme-config', formData).then((resp) => {
        // not working so disabling. I have added document.location to re initialize project on congratualtions screen with timeout
        // initializeProject(config['id']);
        history.push('/configuration/congratulations', newData);
      });
    })().catch((e) => {
      handleMetamaskError(e, dispatch, 'Configuration save');
    });
  };
  const goBack = (): void => {
    history.push('/configuration/third_party_integrations', { ...state });
  };

  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Social media"
          message="Please provide social media links or leave blank if you wish."
          steps={8}
          currentStep={7}
        />
        {/* savedField may could change to the config data from back end as the rest of the other props */}
        <Form onSubmit={onSubmit} goBack={goBack} savedField={state?.social_media} />
      </div>
    </>
  );
};
