import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { store } from '../../ducks/store';
import { ERROR_NO_WEB3 } from '../constants/errors';
import { Web3ProviderState } from '../../ducks/ethers/web3/slice';

export const useWeb3 = (): Web3ProviderState => {
  const state = store.getState();
  const web3 = getEthersWeb3Provider(state);

  if (!web3) {
    throw new Error(ERROR_NO_WEB3);
  }

  return web3;
};
