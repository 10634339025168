import React from 'react';
import { createDuration, Duration, DurationUnit, DurationUnits } from '../../sdk/helpers/duration';
import { Dropdown } from '../Dropdown';
import { TextField } from '../TextField';

export interface DurationProps {
  value: Duration;
  onChange?: (value: Duration) => void;
  readOnly?: boolean;
  dataCy?: string;
  label?: string;
}

export const DurationInput: React.FC<DurationProps> = (props) => {
  const change = (amount: number, unit: string): void => {
    if (props.onChange) props.onChange(createDuration(amount, unit as DurationUnit));
  };

  return (
    <React.Fragment>
      <TextField
        value={isNaN(props.value.value) ? '' : props.value.value}
        label={props.label || 'Duration'}
        type="number"
        icon="clock"
        min="0"
        step="1"
        onChange={(value): void => {
          change(value ? parseFloat(value) : NaN, props.value.unit);
        }}
        onValidate={(value): string => {
          if (!value) return 'Please enter a valid number';
          return '';
        }}
        readOnly={props.readOnly}
        data-cy={props.dataCy}
      />

      <Dropdown
        value={props.value.unit}
        label=""
        icon="calendar_black"
        options={[
          {
            value: DurationUnits.seconds,
            name: 'Seconds',
          },
          {
            value: DurationUnits.minutes,
            name: 'Minutes',
          },
          {
            value: DurationUnits.hours,
            name: 'Hours',
          },
          {
            value: DurationUnits.days,
            name: 'Days',
          },
          {
            value: DurationUnits.weeks,
            name: 'Weeks',
          },
          {
            value: DurationUnits.months,
            name: 'Months',
          },
          {
            value: DurationUnits.years,
            name: 'Years',
          },
        ]}
        onChange={(value): void => {
          change(props.value.value, value.toString());
        }}
        disabled={props.readOnly}
      />
    </React.Fragment>
  );
};
