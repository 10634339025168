import React from 'react';

export interface TabMenuProps {
  baseLocation: string;
}

export const TabMenu: React.FC<TabMenuProps> = ({ baseLocation, children }) => {
  const childrenWithBaseLocation = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { baseLocation });
    }

    return child;
  });

  return <div className={'tab-menu'}>{childrenWithBaseLocation}</div>;
};
