import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionContext } from '../../utils/decoder';

export interface RawTransactions {
  submitted: {
    transactionId: number;
    blockNumber: number;
    transactionHash: string;
    transactionIndex: number;
    context: TransactionContext;
  }[];
  confirmed: { transactionId: number; sender: string; context: TransactionContext }[];
  failed: { transactionId: number; context: TransactionContext }[];
  executed: { transactionId: number; context: TransactionContext }[];
}

export type TransactionsBlocks = {
  [block: number]: { timestamp: number };
};

interface TransactionState {
  raw: RawTransactions;
  blocks: TransactionsBlocks;
  hiddenNotifications: number[];
  processing: number[];
}

const initialState: TransactionState = {
  raw: {
    submitted: [],
    confirmed: [],
    failed: [],
    executed: [],
  },
  blocks: {},
  hiddenNotifications: [],
  processing: [],
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    storeRawTransactions: (state, action: PayloadAction<RawTransactions>): void => {
      state.raw = action.payload;
    },
    storeBlocks: (state, action: PayloadAction<TransactionsBlocks>): void => {
      state.blocks = action.payload;
    },
    hideNotification: (state, action: PayloadAction<number>): void => {
      state.hiddenNotifications.push(action.payload);
    },
    saveHiddenNotifications: (state, action: PayloadAction<number[]>): void => {
      state.hiddenNotifications = action.payload;
    },
    addToProcessing: (state, action: PayloadAction<number>): void => {
      state.processing.push(action.payload);
    },
    removeFromProcessing: (state, action: PayloadAction<number>): void => {
      const index = state.processing.indexOf(action.payload);
      if (index > -1) {
        state.processing.splice(index, 1);
      }
    },
  },
});
