import React from 'react';
import { Icons } from '../../assets/icons';
import { Stepper } from '../Stepper';
export interface Props {
  title: string;
  message: string;
  steps?: number;
  currentStep?: number;
  hideInfo?: boolean;
  showRefresh?: boolean;
  onRefresh?: () => void;
}
export const StakingTitle: React.FC<Props> = ({
  title,
  message,
  steps,
  currentStep,
  hideInfo = false,
  showRefresh,
  onRefresh,
}) => {
  return (
    <div className="staking-card-title">
      <div className="staking-title">
        <div className="col">
          <h3>{title}</h3>
        </div>
        <div className="col">
          {steps && currentStep && (
            <div className="container-steper">
              <Stepper steps={steps} currentStep={currentStep} />
            </div>
          )}

          {!hideInfo && <img className="info-icon" src={Icons.info} />}
          {/* {showRefresh && <img className="refresh-icon" src={Icons.reload} onClick={onRefresh} />} */}
        </div>
      </div>
      <div className="paragraph-regular">{message}</div>
    </div>
  );
};
