import { ContractInterface } from 'ethers';
import jsonDev from '@stichting-allianceblock-foundation/multisig-contracts/artifacts/contracts/MultiSigWallet.sol/MultiSigWallet.json';
import { getNetworkConfig } from '../../../sdk/helpers/network';

export interface MultisigContract {
  abi: ContractInterface;
  blockNumber: number;
}

export const getMultisigContract = async (): Promise<MultisigContract> => {
  const networkConfig = await getNetworkConfig();
  return {
    abi: jsonDev.abi as ContractInterface,
    blockNumber: networkConfig.START_BLOCK,
  };
};
