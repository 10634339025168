export const integerRegex = new RegExp('^(?:[1-9]\\d*|0)$');
export const numberRegex = new RegExp('^0$|^[1-9]\\d*$|^\\.\\d+$|^0\\.\\d*$|^[1-9]\\d*\\.\\d*$');

export const emailRegex = new RegExp('[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-zA-Z0-9]+[.][a-zA-Z0-9]{2,5}');

export const twitterRegex = new RegExp('^$|((?:www.|(?!www))twitter.com/[a-zA-Z0-9_]{1,})');
export const telegramRegex = new RegExp('^$|((?:www.|(?!www))t.me/[a-zA-Z0-9_]{1,})');
export const discordRegex = new RegExp('^$|((?:www.|(?!www))discord.com/[a-zA-Z0-9_]{1,})');

export const usernameRegex = new RegExp('^[A-Za-z0-9_-]{3,32}$');
export const projectNameRegex = new RegExp('^[a-zA-Z0-9- _]{3,64}$');
export const websiteRegex = new RegExp(
  '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
);
export const symbolRegex = new RegExp('^[A-Za-z0-9_]{2,16}$');
export const coingeckoRegex = new RegExp('(https?://(?:www.|(?!www))coingecko.com/[a-zA-Z0-9_]{1,})');
export const decimalsRegex = new RegExp('^(0?[1-9]|[1-9][0-9])$');
