import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addSnackbar } from '../../ducks/snackbar/action';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { getSignature } from '../../utils/getSignature';
import { Button } from '../Button';
import { Icons } from '../../assets/icons';
import { TextField } from '../TextField';
import Tippy from '@tippyjs/react';
import BadWordsFilter from 'bad-words';
import { useAccount } from 'wagmi';
import { StakingPool } from '../StakingPool';
import { LiquidityMiningPool } from '../LiquidityMiningPool';

export const PoolCampaignMessage: React.FC<{ pool: StakingPool | LiquidityMiningPool }> = ({ pool }) => {
  const { address: wallet } = useAccount();

  const dispatch = useDispatch();
  const web3 = useSelector(getEthersWeb3Provider);

  const [campaignMessage, setCampaignMessage] = useState(pool.campaignMessage || '');
  const [savedCampaignMessage, setSavedCampaignMessage] = useState(pool.campaignMessage || '');
  const [editable, setEditable] = useState(false);
  const bwFilter = new BadWordsFilter();

  const saveCampaignMessage = async (): Promise<void> => {
    if (!pool) {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'No pool loaded',
          content: 'Please create a pool before editing campaign message.',
        }),
      );
      return;
    }

    setEditable(false);

    // any changes
    (async (): Promise<void> => {
      const { signature, timestamp } = await getSignature(web3, dispatch);

      const formData = new FormData();
      formData.append('wallet', wallet as string);
      formData.append('signature', signature);
      formData.append('campaignAddress', pool.address);
      formData.append('timestamp', timestamp.toString());

      // if deleting campaign message, end point changes and
      // we should not send campaign message variable
      if (campaignMessage) formData.append('campaignMessage', campaignMessage);

      await axios
        .post(
          process.env.REACT_APP_API +
            `/project/${campaignMessage ? 'edit-campaign-message' : 'delete-campaign-message'}`,
          formData,
        )
        .then((resp) => {
          setSavedCampaignMessage(campaignMessage);

          dispatch(
            addSnackbar({
              type: 'info',
              title: 'Success',
              content: 'Campaign message has been saved.',
            }),
          );
        });
    })().catch((e) => {
      // revert back the campaign message
      setCampaignMessage(savedCampaignMessage);

      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Error',
          content: 'Nothing changed.',
        }),
      );
    });
  };

  return (
    <div className="campaign-message">
      {!editable ? (
        <>
          <div className="campaign-message-preview">
            {campaignMessage}
            <Tippy placement="bottom" content={`Change campaign message`}>
              <img
                className="edit-icon"
                src={Icons.edit}
                onClick={(): void => {
                  setEditable(true);
                }}
              />
            </Tippy>
          </div>
        </>
      ) : (
        <>
          <div className="campaign-message-area">
            <TextField
              className={`textArea ${editable ? '' : 'disabled'}`}
              label="Optional informational message"
              maxLength={150}
              textArea={true}
              value={campaignMessage}
              type="text"
              disabled={!editable}
              onChange={(value): void => {
                setCampaignMessage(value);
              }}
            />
          </div>
          {bwFilter.isProfane(campaignMessage) && (
            <div className="pool-form-error">You cannot use profane words in your optional informational message</div>
          )}
          <div className="campaign-message-buttons">
            <Button
              color="negative"
              size="medium"
              onClick={(): void => {
                setCampaignMessage(savedCampaignMessage);
                setEditable(false);
              }}
              label="Cancel"
              icon="times"
              iconposition="left"
            />
            <Button
              color="primary"
              size="medium"
              disabled={bwFilter.isProfane(campaignMessage)}
              onClick={(): void => {
                saveCampaignMessage();
              }}
              label="Save"
              icon="check"
              iconposition="left"
            />
          </div>
        </>
      )}
    </div>
  );
};
