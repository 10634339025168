import React, { useState } from 'react';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useSelector } from 'react-redux';
import { twitterRegex, telegramRegex, discordRegex, websiteRegex } from '../../../sdk/constants/regex';

export interface Props {
  onSubmit: (data: any) => void;
  goBack: () => void;
  savedField: any;
}

export interface SocialMedia {
  twitter: string;
  telegram: string;
  discord: string;
  website: string;
}

export const socialMediaInitialState: SocialMedia = {
  twitter: '',
  telegram: '',
  discord: '',
  website: '',
};

export const Form: React.FC<Props> = ({ onSubmit, goBack }): JSX.Element => {
  const config = useSelector(getProjectConfigAll);

  const [value, setValue] = useState<SocialMedia>(
    Object.keys(config.socialMedia).length ? (config.socialMedia as SocialMedia) : socialMediaInitialState,
  );
  const handleChange = (v: string, e: any): void => {
    setValue({ ...value, [e.target.name]: v });
  };

  return (
    <>
      <div className="text-field-container">
        <TextField
          icon="website"
          label="Website"
          onChange={handleChange}
          required={true}
          id="website"
          name="website"
          type="text"
          value={value.website}
          onValidate={(value): string => (!value || websiteRegex.test(value) ? '' : 'Invalid website')}
        />
        <TextField
          icon="twitter_icon_input"
          label="Twitter"
          onChange={handleChange}
          required={true}
          id="twitter"
          name="twitter"
          type="text"
          value={value.twitter}
          onValidate={(value): string => (twitterRegex.test(value) ? '' : 'Invalid twitter account')}
        />
        <TextField
          icon="telegram_icon_input"
          label="Telegram"
          onChange={handleChange}
          required={true}
          id="telegram"
          name="telegram"
          type="text"
          value={value.telegram}
          onValidate={(value): string => (telegramRegex.test(value) ? '' : 'Invalid telegram account')}
        />
        <TextField
          icon="discord_icon_input"
          label="Discord"
          onChange={handleChange}
          required={true}
          id="discord"
          name="discord"
          type="text"
          value={value.discord}
          onValidate={(value): string => (discordRegex.test(value) ? '' : 'Invalid discord account')}
        />
      </div>
      <div className="button-container">
        <Button
          color="tertiary"
          iconposition="left"
          icon="arrow_left"
          label="Go back"
          onClick={(): void => goBack()}
          size="large"
        />
        <Button
          data-cy="configuration-save"
          color="primary"
          label="Save Configuration"
          icon="arrow_right"
          onClick={(): void => onSubmit(value)}
          size="large"
          iconposition="right"
          disabled={
            !twitterRegex.test(value.twitter) ||
            !telegramRegex.test(value.telegram) ||
            !discordRegex.test(value.discord) ||
            !(!value.website || websiteRegex.test(value.website))
          }
        />
      </div>
    </>
  );
};
