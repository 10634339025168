import React from 'react';
import { StakingPoolList } from '../../../components/StakingPoolList';

export const StakingActive: React.FC = () => {
  return (
    <StakingPoolList
      title="Active pools"
      subTitle="List of active pools (new pools can take a few minutes to show up here)"
      filter={(pool: any): boolean => {
        return pool.start.getTime() !== 0 && pool.start < new Date() && pool.end > new Date();
      }}
    />
  );
};
