import React, { ChangeEvent, useState, useEffect } from 'react';
import { AddIconButton } from '../AddIconButton';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import { ethers, utils } from 'ethers';
import { getSignature } from '../../utils/getSignature';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { useAccount, useProvider } from 'wagmi';

export type TokenInputFieldType = 'text' | 'password';

export interface TokenInputFieldProps {
  /*
      Label string
  */
  label?: string;
  /*
      Input type
  */
  type: TokenInputFieldType;

  /*
      Optional default value
  */
  initialValue?: string;
  /*
    Optional readOnly
  */
  readOnly?: boolean;
  /*
       value
  */
  value: any;
  /*
      Optional required
  */
  required?: boolean;
  /*
      Left icon name
  */
  icon?: string;
  /*
      Optional name string
  */
  name?: string;
  /*
      unique id
  */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  /*
      Optional right component handler
  */
  fileInputName: string;
  onChangeFile: (event: any) => void;
  fileValue: any;
  hideAddIcon?: boolean;
  rpcUrl?: string;
  className?: string;
  iconUrl?: string;
  tokenSymbol?: string;
}

/**
 * Primary UI component for user interaction
 */
export const TokenInputField: React.FC<TokenInputFieldProps> = ({
  label,
  icon,
  required,
  type,
  onChange,
  onChangeFile,
  fileValue,
  fileInputName,
  value,
  readOnly = false,
  rpcUrl,
  iconUrl,
  tokenSymbol,
  ...props
}) => {
  const { address: wallet } = useAccount();
  const provider = useProvider();

  const dispatch = useDispatch();
  const [tokenName, setTokenName] = useState('...');

  const web3 = useSelector(getEthersWeb3Provider);

  const setTokenSymbol = (tokenAddress: string): void => {
    if (tokenSymbol) {
      setTokenName(tokenSymbol);
    } else if (utils.isAddress(tokenAddress)) {
      try {
        const tokenContract = loadERC20(tokenAddress);

        if (rpcUrl) {
          if (!tokenContract.contract) throw new Error('ERC20 token could not be loaded');
          tokenContract.contract = tokenContract.contract.connect(provider);
        }

        tokenContract
          .getSymbol()
          .then((symbol) => setTokenName(symbol))
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log(e);
        dispatch(
          addSnackbar({
            type: 'error',
            title: 'Unable to fetch token symbol',
            content: 'Please check if the address is an ERC20 compatible token.',
          }),
        );
      }
    }
  };
  const handleChange = (e: any): void => {
    if (onChange) {
      onChange(e);
    }

    if (utils.isAddress(e.target.value)) {
      setTokenSymbol(e.target.value);
    }
  };

  useEffect(() => {
    if (value) setTokenSymbol(value);

    return (): void => {
      // isApiSubscribed = false;
      setTokenName('...');
    };
  }, [value]);

  const handleFileChange = (e: any): void => {
    const uploadFile = async (): Promise<void> => {
      const { signature, timestamp } = await getSignature(web3, dispatch);

      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('address', value);
      formData.append('wallet', wallet as string);
      formData.append('signature', signature);
      formData.append('timestamp', timestamp.toString());
      await axios.post(process.env.REACT_APP_API + '/logo/token-logo', formData);
    };

    uploadFile().catch((e) => {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Upload failed',
          content: e.response?.data ? e.response.data.error : 'There was an error during upload',
        }),
      );
    });
  };

  return (
    <div className={`token-input-field-container ${props.className}`}>
      <div className="col">
        <div className="textField">
          <input
            type={type}
            style={{
              backgroundImage: `url('${iconUrl ?? process.env.REACT_APP_ASSETS}/${value.toLocaleLowerCase()}')`,
            }}
            className={`textField--input token`}
            placeholder={label}
            required={required}
            value={value}
            onChange={handleChange}
            readOnly={readOnly}
          />
          <span>{tokenName}</span>
          {label && <label>{label}</label>}
        </div>
      </div>
      {!props.hideAddIcon && (
        <div className="col token-input-add-icon">
          <AddIconButton
            label="Add icon"
            maxHeight={500}
            maxWidth={500}
            required
            name={fileInputName}
            id={fileInputName}
            handleChange={handleFileChange}
          />
        </div>
      )}
    </div>
  );
};
