import { instance } from './public';
import { Users } from '../types/dto/Users.dto';

export const getUsersByProjectId = async (projectId: string): Promise<Users | undefined> => {
  try {
    const response = await instance.get('/user/users', {
      params: {
        projectId: projectId,
      },
    });

    if (!response.data.error) {
      return response.data.usersArray;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
