import { RootState, store } from '../store';
import { RawTransactions, TransactionsBlocks } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { getAdmins } from '../admins/selectors';
import { BadgeType } from '../../components/Badge';
import { getAuthAddress } from '../auth/selectors';
import { truncateAddress } from '../../utils/address';
import { IAdmins } from '../../components/Notification/NotificationTransaction';
import { getWalletContract } from '../ethers/multisig/selectors';
import { getIdFromProps } from '../fromProps';
import { formatDate } from '../../utils/formatDate';

const TRANSACTION_EXPIRATION_PERIOD = 30; //days

export const getRawTransactionsData = (state: RootState): RawTransactions => state.transactions.raw;

export const getTransactionsBlocks = (state: RootState): TransactionsBlocks => state.transactions.blocks;

export const getHiddenNotifications = (state: RootState): number[] => state.transactions.hiddenNotifications;

export const getNotificationProcessing = (state: RootState): number[] => state.transactions.processing;

export interface TransactionForSaga {
  transaction?: {
    transactionId: number;
    blockNumber: number;
    transactionHash: string;
    transactionIndex: number;
    context: {
      actionName: string;
    };
  };
  votes: { transactionId: number; sender: string }[];
  voted: boolean;
}

export const getTransactionForSaga = createSelector(
  getRawTransactionsData,
  getAuthAddress,
  getIdFromProps,
  ({ submitted, confirmed }, accPubKey, id): TransactionForSaga => {
    const transaction = submitted.find(({ transactionId }) => transactionId === id);
    const votes = confirmed.filter(({ transactionId }) => transactionId === id);
    const voted = votes.some(({ sender }) => sender === accPubKey);
    return { transaction, votes, voted };
  },
);

interface TransactionRow {
  id: string;
  date: string;
  type: string;
  transactionHash: string;
  amount?: {
    value: number | string;
    icon?: string;
    coin: string;
  };
  status: BadgeType; // TODO: Probably will be enum
  admins: {
    img: string;
    name: string;
    status: BadgeType; // TODO: Probably will be enum
    self: boolean;
  }[];
}

export const getTransactionsRows = createSelector(
  getRawTransactionsData,
  getAdmins,
  getAuthAddress,
  getTransactionsBlocks,
  ({ submitted, confirmed, failed, executed }, admins, accPubKey, blocks) => {
    const requiredApproval = Math.floor(admins.length / 2 + 1);

    const getTransactionStatus = (id: number, date: string): BadgeType => {
      if (failed.some(({ transactionId }) => transactionId === id))
        return getTransactionExpired(date) ? 'expired' : 'failed';
      if (executed.some(({ transactionId }) => transactionId === id)) return 'finished';
      if (!executed.some(({ transactionId }) => transactionId === id) && admins.length === 1)
        return getTransactionExpired(date) ? 'expired' : 'awaiting';
      const votes = confirmed.filter(({ transactionId }) => transactionId === id);
      if (votes.length >= requiredApproval) return 'approved';
      return getTransactionExpired(date) ? 'expired' : 'awaiting';
    };

    const getAdminStatus = (id: number, admin: string, date: string): BadgeType => {
      if (
        admins.length === 1 &&
        !executed.some(({ transactionId }) => transactionId === id) &&
        confirmed.some(({ transactionId, sender }) => transactionId === id && sender === admin)
      )
        return getTransactionExpired(date) ? 'expired' : 'awaiting';
      if (confirmed.some(({ transactionId, sender }) => transactionId === id && sender === admin)) return 'approved';
      return getTransactionExpired(date) ? 'expired' : 'awaiting';
    };

    const getAdmin = (id: number, date: string) => ({
      address,
      name,
    }: {
      address: string;
      name?: string;
    }): TransactionRow['admins'][0] => {
      const status = getAdminStatus(id, address, date);
      return {
        img: '',
        name: name || truncateAddress(address, 4, 6),
        status,
        self: address === accPubKey && status === 'awaiting',
      };
    };

    const getDate = (blockNumber: number): string => {
      if (!blocks[blockNumber]) return 'loading...';
      const date = new Date(blocks[blockNumber].timestamp);
      return formatDate(date);
    };

    const getTransactionExpired = (date: string): boolean => {
      const expirationDate = new Date(date);
      expirationDate.setDate(expirationDate.getDate() + TRANSACTION_EXPIRATION_PERIOD);
      return new Date() > expirationDate;
    };

    return submitted
      .map(
        (data): TransactionRow => ({
          id: data.transactionId.toString(),
          transactionHash: data.transactionHash,
          date: getDate(data.blockNumber),
          type: data.context.actionName,
          amount: { value: data.context.amount, coin: data.context.assetName || '' },
          status: getTransactionStatus(data.transactionId, getDate(data.blockNumber)),
          admins: admins.map(getAdmin(data.transactionId, getDate(data.blockNumber))),
        }),
      )
      .reverse();
  },
);

export const getActiveNotifications = createSelector(
  getRawTransactionsData,
  getHiddenNotifications,
  getAdmins,
  getAuthAddress,
  ({ submitted, failed, executed, confirmed: confirmedData }, hidden, admins, accPubKey) =>
    submitted.filter(({ transactionId: id }) => {
      if (
        hidden.some((hidden) => hidden === id) ||
        executed.some(({ transactionId }) => transactionId === id) ||
        failed.some(({ transactionId }) => transactionId === id)
      )
        return false;

      const requiredApproval = Math.floor(admins.length / 2 + 1);
      const confirmed = confirmedData.filter(({ transactionId }) => transactionId === id).length;
      if (confirmed >= requiredApproval) return true;

      return !confirmedData.some(({ transactionId, sender }) => transactionId === id && sender === accPubKey);
    }),
);

export const getNotificationCount = (state: RootState): number => getActiveNotifications(state).length;

export const getNotifications = createSelector(
  getRawTransactionsData,
  getActiveNotifications,
  getAdmins,
  getTransactionsBlocks,
  // TODO: this is not valid case...
  getWalletContract,
  ({ confirmed: confirmedData }, notifications, admins, blocks, contract) =>
    notifications.map((notification) => {
      const confirmed: IAdmins[] = [];
      const awaiting: IAdmins[] = [];
      admins.forEach((admin) => {
        if (
          confirmedData.some(
            ({ transactionId, sender }) => transactionId === notification.transactionId && sender === admin.address,
          )
        ) {
          confirmed.push({
            name: admin.name,
            address: admin.address,
          });
        } else {
          awaiting.push({
            name: admin.name || truncateAddress(admin.address, 8, 8),
            address: admin.address,
          });
        }
      });

      const transactionInfo = [
        { title: '#ID', value: notification.transactionId },
        { title: 'Type', value: notification.context.actionName },
        { title: 'Hash', value: truncateAddress(notification.transactionHash, 8, 16) },
        { title: 'Index', value: notification.transactionIndex },
        { title: 'Block', value: notification.blockNumber },
      ];
      if (blocks[notification.blockNumber]) {
        const date = new Date(blocks[notification.blockNumber].timestamp);
        transactionInfo.push({ title: 'Created', value: formatDate(date) });
      }

      const sendingTo = {
        address: contract?.address || 'unknown',
        name: notification.context.contractName,
      };
      const assetName = notification.context.assetName ? ` ${notification.context.assetName}` : '';

      return {
        transactionId: notification.transactionId,
        transaction: `${notification.context.actionName} ${notification.context.amount}${assetName}`,
        sendingTo,
        confirmed,
        awaiting,
        transactionInfo,
      };
    }),
);
