import React from 'react';

interface IProps {
  list: {
    title: string;
    value: string | number;
  }[];
}

export const NotificationInfoList: React.FC<IProps> = ({ list }) => (
  <div className="info-container">
    {list.map(({ title, value }, index) => (
      <div key={index} className="info-row">
        <span className="header-big">{title}</span>
        <span>{value}</span>
      </div>
    ))}
  </div>
);
