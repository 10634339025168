/*
 * @param {string} locale If no locale is provided, it will fallback to the default locale automatically
 */
const currencyFormatterFactory = (locale?: string): ((value: number) => string) => {
  const formatter = new Intl.NumberFormat(locale || undefined, {
    minimumFractionDigits: 2,
  });

  return (value: number): string => formatter.format(value);
};

const currencyFormatter = currencyFormatterFactory();

export { currencyFormatterFactory, currencyFormatter };
