import React from 'react';
import { LiquidityMiningPoolList } from '../../../components/LiquidityMiningPoolList';
import { LiquidityMiningPool } from '../../../components/LiquidityMiningPool';

export const ActivePools: React.FC = () => {
  return (
    <LiquidityMiningPoolList
      title="Active pools"
      subTitle="List of active pools (new pools can take a few minutes to show up here)"
      filter={(pool: LiquidityMiningPool): boolean => {
        return pool.start < new Date() && pool.end > new Date();
      }}
    />
  );
};
