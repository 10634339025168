import React, { useState, useEffect } from 'react';
import { Icons } from '../../assets/icons';
import cls from 'classnames';

export interface SnackbarProps {
  type?: 'info' | 'error';
  title: string;
  content: string;
}

/**
 * Primary UI component for user interaction
 */
export const Snackbar: React.FC<SnackbarProps> = ({ type, title, content }) => {
  const [hide, setHide] = useState(false);

  const handleClick = (e: any): void => {
    setHide(true);
  };

  useEffect(() => {
    if (type === 'info') setTimeout(() => setHide(true), 30000);
  }, [hide]);

  return (
    <div className={cls('snackbar', `snackbar--${type}`, { 'snackbar--hide': hide })} onClick={handleClick}>
      <div>
        <div>
          <h3>{title}</h3>
          <p className="snackbar-content">{content}</p>
        </div>
        {type === 'error' && <span className="close">&times;</span>}
      </div>
    </div>
  );
};
