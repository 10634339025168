import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';

export const Fonts: React.FC = () => (
  <GoogleFontLoader
    fonts={[
      {
        font: 'Roboto',
        weights: [700],
      },
    ]}
  />
);
