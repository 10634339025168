import React, { ReactNode, useEffect } from 'react';
import { Icons } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { BackendAdmin } from '../../../ducks/admins/slice';
import { TextField } from '../../../components/TextField';
import * as yup from 'yup';
import '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { processAdminsEdit } from '../../../ducks/admins/actions';
import { geAdminAddresses } from '../../../ducks/admins/selectors';

interface IProps {
  onReviewClick: () => void;
  onCancelClick: () => void;
  signers: BackendAdmin[];
  newSigners?: BackendAdmin[];
}

const adminsEditSchema = yup.object().shape({
  signers: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().required('Email is required').email(),
        address: yup.string().required('Address is required').isEthAddress(),
      }),
    )
    .min(1),
});

export interface AdminRow {
  name: string;
  email: string;
  address: string;
}

export const AdminsEdit: React.FC<IProps> = ({ signers, newSigners, onCancelClick, onReviewClick }) => {
  const dispatch = useDispatch();

  const correctAddresses = useSelector(geAdminAddresses);

  const formik = useFormik<{ signers: AdminRow[] }>({
    initialValues: {
      signers: signers.map(({ name, email, address }) => ({ name: name, email: email, address })),
    },
    validationSchema: adminsEditSchema,
    initialTouched: { signers: signers.map(() => ({ name: true, email: true, address: true })) },
    onSubmit: (values) => {
      dispatch(processAdminsEdit(values.signers));
      onReviewClick();
    },
  });

  useEffect(() => {
    // Create more natural feeling of coming back here from "Review View"
    if (newSigners) {
      formik.setFieldValue('signers', newSigners);
      formik.setTouched({ signers: newSigners.map(() => ({ name: true, email: true, address: true })) });
    }
  }, [newSigners]);

  const fieldStatus = (index: number, field: keyof BackendAdmin): ReactNode => {
    if (formik.touched.signers && formik.touched.signers[index] && formik.touched.signers[index][field])
      return formik.errors.signers?.[index] && (formik.errors.signers[index] as FormikErrors<BackendAdmin>)[field] ? (
        <img className="admin-input-badge admin-input-bad" src={Icons.declined} />
      ) : (
        <img className="admin-input-badge admin-input-good" src={Icons.approved_white} />
      );
    return null;
  };

  const stillHasExistingSigner = (addressToBeRemoved: string): boolean => {
    return (
      formik.values.signers.filter(
        (signer) =>
          signer.address !== addressToBeRemoved && correctAddresses.some((address) => address === signer.address),
      ).length >= 1
    );
  };

  return (
    <>
      <div className="admins-section">
        <h3>Admins</h3>
      </div>
      <div>Review or edit current configured administrators of your project&apos;s multisig wallet.</div>

      <FormikProvider value={formik}>
        <FieldArray name="signers">
          {({ remove, push }): any => (
            <div className="admin-input-container">
              {formik.values.signers.map(({ name, email, address }, index) => (
                <div key={index} className="admin-input-row">
                  <TextField
                    name={`signers.${index}.name`}
                    type="text"
                    value={name}
                    icon="admins"
                    label="Admin name"
                    onChange={(_value, e): void => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    rightComponent={fieldStatus(index, 'name')}
                  />
                  <TextField
                    name={`signers.${index}.email`}
                    type="text"
                    value={email}
                    icon="admins"
                    label="Admin email"
                    onChange={(_value, e): void => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    rightComponent={fieldStatus(index, 'email')}
                  />
                  <TextField
                    name={`signers.${index}.address`}
                    type="text"
                    value={address}
                    icon="wallet_black"
                    label="Wallet address"
                    onChange={(_value, e): void => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    rightComponent={fieldStatus(index, 'address')}
                  />
                  {stillHasExistingSigner(address) ? (
                    <img src={Icons.bin} className="delete-icon" onClick={(): void => remove(index)} />
                  ) : (
                    <img src={Icons.bin} className="delete-icon--disabled" />
                  )}
                </div>
              ))}

              <div className="admin-input-add">
                <Button
                  label="Add admin"
                  color="primary-empty"
                  size="medium"
                  icon="plus"
                  iconposition="left"
                  onClick={(): void => push({ name: '', address: '' })}
                />
              </div>
            </div>
          )}
        </FieldArray>
      </FormikProvider>

      <div className="admins-button-container">
        <Button
          label="Cancel"
          size="large"
          icon="declined_black"
          iconposition="left"
          color="negative"
          onClick={onCancelClick}
        />
        <Button
          label="Review"
          size="large"
          icon="arrow_right"
          iconposition="right"
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
          shadow
        />
      </div>
    </>
  );
};
