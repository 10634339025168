import {
  all,
  takeEvery,
  put,
  PutEffect,
  call,
  CallEffect,
  ForkEffect,
  takeLatest,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import {
  setAuthorizeState,
  requireAuthorization,
  revokeAuthorization,
  removeAuthorization,
  addAuthorizationRole,
  connectWallet,
  setPublicKey,
  setName,
  setEmail,
} from './actions';
import { AuthState } from './slice';
import { connect, WagmiConnectionResult } from '../ethers/web3/sagas';
import { initializeProject } from '../project/sagas';
import { getUserByAddress } from '../../services/user';
import { Web3ProviderState } from '../ethers/web3/slice';
import { getEthersWeb3Provider } from '../ethers/web3/selectors';
import { User } from '../../types/dto/User.dto';
import { addSnackbar } from '../snackbar/action';
import { getNetworkId } from '../../sdk/helpers/network';
import { networkConfigurations } from '../../sdk/constants/networkConfig';

const AUTH_STORAGE_KEY = 'authorization_key_v1';

function* authorize({
  payload: { email, password },
}: ReturnType<typeof requireAuthorization>): Generator<ForkEffect | CallEffect | PutEffect, void, string> {
  try {
    localStorage.setItem(AUTH_STORAGE_KEY, '');
  } catch {
    yield put(
      addSnackbar({
        type: 'error',
        title: 'Authorization failed',
        content: 'Unable to store token in localStorage',
      }),
    );
  }
}

function* deauthorize(): Generator<PutEffect, void> {
  try {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  } catch {
    yield put(
      addSnackbar({
        type: 'error',
        title: 'Deauthorization failed',
        content: 'Unable to remove token from localStorage',
      }),
    );
  }
  yield put(removeAuthorization());
}

function* authorizeUser({
  payload: provider,
}: {
  payload: WagmiConnectionResult['provider'];
}): Generator<CallEffect | PutEffect | SelectEffect | Promise<string>, void, string & Web3ProviderState & User> {
  try {
    yield call(connect, { payload: provider });

    const web3: Web3ProviderState = yield select(getEthersWeb3Provider);
    if (web3 === null) {
      throw new Error('Missing web3, ensure user web3 is connected before');
    }

    if (!web3?.signer) {
      return;
    }

    const signer = web3.signer;
    const address: string = yield signer.getAddress();
    yield put(setPublicKey(address));

    const user = yield call(getUserByAddress, address);
    const networkId = yield call(getNetworkId);
    if (!networkConfigurations[networkId]) {
      yield put(setAuthorizeState(AuthState.UNSUPPORTED_NETWORK));
    } else if (user && user.projectId) {
      yield call(initializeProject, user.projectId);

      yield put(setName(user.user));
      yield put(setEmail(user.email));
      yield put(addAuthorizationRole('web3'));
      yield put(setAuthorizeState(AuthState.AUTHORIZED));
    } else {
      yield put(setAuthorizeState(AuthState.GUEST));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* authSagaWatcher(): Generator {
  yield all([
    takeLatest(connectWallet, authorizeUser),
    takeEvery(requireAuthorization, authorize),
    takeEvery(revokeAuthorization, deauthorize),
  ]);
}
