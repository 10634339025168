import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { BackendAdmin } from './slice';

export const geAdminAddresses = (state: RootState): string[] => state.admins.addresses;

export const getAdminsMeta = (state: RootState): BackendAdmin[] => state.admins.meta;

export const getTemporaryAdmins = (state: RootState): BackendAdmin[] | undefined =>
  state.admins.temp.length ? state.admins.temp : undefined;

export const getAdmins = createSelector(geAdminAddresses, getAdminsMeta, (addresses, meta) =>
  addresses.map((address) => ({
    name: meta.find((admin) => admin.address === address)?.name || '',
    email: meta.find((admin) => admin.address === address)?.email || '',
    address,
  })),
);
