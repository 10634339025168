import React, { useState, useEffect } from 'react';

export type TextFieldType = 'text' | 'password';

export interface TextFieldProps {
  /*
      Label string
  */
  label: string;
  /*
      Optional required
  */
  required?: boolean;
  /*
      Input name
  */
  name: string;
  /*
      unique id
  */
  id: string;
  existingFile: any;
  handleChange: (e: any) => void;
  maxHeight: number;
  maxWidth: number;
  onError?: (value: boolean) => void;
}

/**
 * Primary UI component for user interaction
 */
export const InputPhoto: React.FC<TextFieldProps> = ({
  label,
  maxHeight,
  maxWidth,
  required,
  id,
  existingFile,
  handleChange,
  onError,
  ...props
}) => {
  //
  const [value, setValue] = useState('');
  const acceptableFileTypes = ['image/png', 'image/svg+xml', 'png', 'svg'];

  useEffect(() => {
    if (existingFile) {
      setValue(URL.createObjectURL(existingFile));
    }
  }, [existingFile]);

  const onChangeFunc = (e: any): void => {
    if (e.target.files[0]) {
      if (!acceptableFileTypes.includes(e.target.files[0].type)) {
        setValue(URL.createObjectURL(existingFile));
        onError?.(true);
      } else {
        setValue(URL.createObjectURL(e.target.files[0]));
        handleChange(e);
      }
    }
  };

  return (
    <div className="inputPhoto" style={{ backgroundImage: `url(${value})` }}>
      <label className="label-container" htmlFor={id}>
        {value ? (
          <div className="void-div"></div>
        ) : (
          <div className="label-text">
            <div className="circle"></div>
            <h6>{label}</h6>
          </div>
        )}
      </label>
      <input
        id={id}
        type="file"
        onChange={onChangeFunc}
        required={required}
        accept={acceptableFileTypes.join(', ')}
        {...props}
      />
    </div>
  );
};
