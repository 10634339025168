import React, { useState } from 'react';
import { Transactions as TransactionsContainer } from '../../../containers/Transactions';
import { Button } from '../../../components/Button';
import { Icons } from '../../../assets/icons';
import { AccountCard } from '../../../components/AccountCard';
import { TransactionsSend } from './TransactionsSend';
import { useSelector } from 'react-redux';
import { getAdmins } from '../../../ducks/admins/selectors';
import { TransactionsReview } from './TransactionsReview';
import { getWalletContract } from '../../../ducks/ethers/multisig/selectors';

enum Screen {
  View,
  Send,
  Review,
}

export const Transactions: React.FC = () => {
  const [screen, setScreen] = useState<Screen>(Screen.View);

  const signers = useSelector(getAdmins);
  const approves = Math.floor(signers.length / 2 + 1);

  const wallet = useSelector(getWalletContract);

  const handleSendClick = (): void => {
    setScreen(Screen.Send);
  };

  const handleReviewClick = (): void => {
    setScreen(Screen.Review);
  };

  const handleCancelClick = (): void => {
    setScreen(Screen.View);
  };

  const handleBackClick = (): void => {
    setScreen(Screen.View);
  };

  const handleSubmitClick = (): void => {
    setScreen(Screen.View);
  };

  const handleCopyClick = (): Promise<void> => navigator.clipboard.writeText(wallet?.address || 'unknown');

  switch (screen) {
    case Screen.View:
      return (
        <>
          <div className="transactions-buttons">
            {/*<Button
              disabled
              label="Send"
              size="medium"
              icon="right_arrow"
              iconposition="right"
              onClick={handleSendClick}
            />*/}
          </div>
          <TransactionsContainer />
        </>
      );
    case Screen.Send:
      return (
        <TransactionsSend
          approves={approves}
          onReviewClick={handleReviewClick}
          onCancelClick={handleCancelClick}
          onBack={handleBackClick}
        />
      );
    case Screen.Review:
      return (
        <TransactionsReview
          approves={approves}
          signers={signers}
          onSubmitClick={handleSubmitClick}
          onCancelClick={handleCancelClick}
          onBack={(): void => setScreen(Screen.Send)}
        />
      );
  }
};
