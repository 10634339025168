import React from 'react';
import { Md5 } from 'ts-md5/dist/md5';
import { Icons } from '../../../assets/icons';
import { useSelector } from 'react-redux';
import { getExplorer } from '../../../ducks/auth/selectors';
import { ClipboardTooltip } from '../../ClipboardTooltip';

export interface AddressProps {
  address: string;
  compact: boolean;
  length?: number;
  email?: string;
}

const Address: React.FC<AddressProps> = ({ address, compact, length = 22 }) => {
  const truncateAddress = (): string => {
    return `${address.substring(0, length)} ... ${address.substring(37, address.length - 1)}`;
  };

  const displayAddress = compact ? truncateAddress() : address;

  return <div className="user-address">{displayAddress}</div>;
};

interface AvatarProps {
  imageUrl?: string;
}

const Avatar: React.FC<AvatarProps> = ({ imageUrl }) => {
  return <div className="avatar-container">{imageUrl && <img className="avatar-image" src={imageUrl} />}</div>;
};

interface ControlsProps {
  address: string;
  compact: boolean;
}

const Controls: React.FC<ControlsProps> = ({ address, compact }) => {
  const copyIcon = compact ? Icons['copy_light'] : Icons['copy_dark'];
  const openLinkIcon = compact ? Icons['open_link_light'] : Icons['open_link_dark'];

  const link = `${useSelector(getExplorer)}/address/${address}`;

  return (
    <div className="controls">
      <ClipboardTooltip content={address}>
        <img className="copy-icon" src={copyIcon}></img>
      </ClipboardTooltip>
      <a href={link} target="_blank" rel="noreferrer">
        <img className="open-link-icon" src={openLinkIcon}></img>
      </a>
    </div>
  );
};

interface NameProps {
  name?: string;
}

const Name: React.FC<NameProps> = ({ name }) => {
  name = name === undefined ? 'UNKNOWN' : name;

  return <div className="list-item-user--details-name">{name}</div>;
};

export interface ListItemUserProps extends AddressProps, AvatarProps, ControlsProps, NameProps {
  value?: JSX.Element;
}

export const ListItemUser: React.FC<ListItemUserProps> = ({
  address,
  compact,
  imageUrl,
  name = '',
  value,
  length,
  email = '',
}) => {
  return (
    <div className="list-item-user">
      <div className="list-item-user--left">
        <Avatar
          imageUrl={
            imageUrl
              ? imageUrl
              : 'https://www.gravatar.com/avatar/' + (email ? Md5.hashStr(email) : Md5.hashStr(name)) + '?d=identicon'
          }
        />

        <div className="list-item-user--details">
          <Name name={name} />
          <div className="list-item-user--details--address">
            <Address address={address} compact={compact} length={length} />
            {compact && <Controls address={address} compact={compact} />}
          </div>
        </div>
      </div>
      <div className="list-item-user--right">
        {!compact && <Controls address={address} compact={compact} />}
        {!!value && value}
      </div>
    </div>
  );
};
