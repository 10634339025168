import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/Button';
import { InputPhoto } from '../../../components/InputPhoto';
import { addSnackbar } from '../../../ducks/snackbar/action';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useSelector, useDispatch } from 'react-redux';

export interface Props {
  onSubmit: (data: any) => void;
  savedFile?: any;
}

export const Form: React.FC<Props> = ({ onSubmit, savedFile }): JSX.Element => {
  const config = useSelector(getProjectConfigAll);
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>('');
  useEffect(() => {
    const fetchLogo = async (): Promise<void> => {
      if (config.logoUrl) {
        const a = fetch(config.logoUrl)
          .then((e) => {
            return e.blob();
          })
          .then((blob) => {
            const b: any = blob;
            b.lastModifiedDate = new Date();
            b.name = 'logo-file';
            const c = new File([b], 'logo-file', { type: blob.type });
            setValue(c);
          })
          .catch((error) => console.error(error)); // Catching any errors here, if fails to load the blob.
      }
    };
    // when config.logoUrl fixed
    if (config.logoUrl) fetchLogo();

    if (savedFile) {
      setValue(savedFile);
    }
  }, [savedFile]);

  const handleChange = (e: any): void => {
    if (e.target.files[0]) {
      setValue(e.target.files[0]);
    }
  };
  const handleOnSubmit = (): void => {
    onSubmit(value);
  };

  return (
    <>
      <div className="text-field-container">
        <InputPhoto
          label="Click here to upload logo in SVG or transparent PNG."
          maxHeight={500}
          maxWidth={500}
          required
          name="pic"
          id="pic"
          handleChange={handleChange}
          existingFile={value}
          onError={(value: boolean): void => {
            dispatch(
              addSnackbar({
                type: 'error',
                title: 'Invalid file type',
                content: 'You should select SVG or PNG files only.',
              }),
            );
          }}
        />
      </div>

      <div className="button-container">
        <Button
          data-cy="configuration-brand-next"
          color="primary"
          label="Next"
          icon="arrow_right"
          onClick={handleOnSubmit}
          size="large"
          iconposition="right"
          disabled={!value}
        />
      </div>
    </>
  );
};
