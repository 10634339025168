import { RootState } from '../store';
import { ProjectState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { getNetwork } from '../auth/selectors';

export const getMultisigs = (state: RootState): ProjectState['multisig'] => state.project.multisig;

export const getEthMultisig = (state: RootState): null | string => state.project.multisig.eth;

export const getBscMultisig = (state: RootState): null | string => state.project.multisig.bsc;

export const getProjectId = (state: RootState): null | string => state.project.id;
export const getProjectName = (state: RootState): null | string => state.project.name;
export const getProjectConfig = (state: RootState): ProjectState['config'] => state.project.config;
export const getProjectConfigAll = (state: RootState): ProjectState => state.project;

export const getMultisig = createSelector(
  getMultisigs,
  getNetwork,
  (multisigs, network) =>
    multisigs[network as 'bsc' | 'eth' | 'polygon' | 'avalanche' | 'ewc' | 'moonbeam' | 'songbird'],
);
