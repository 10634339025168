import React from 'react';
import { Icons } from '../../assets/icons';
import cls from 'classnames';

export type BadgeType = 'failed' | 'finished' | 'awaiting' | 'approved' | 'declined' | 'expired';

export interface BadgeProps {
  /*
      Color palette
  */
  type?: BadgeType;
  /*
      Optional click handler
  */
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Badge: React.FC<BadgeProps> = ({ type = 'failed', ...props }) => {
  return (
    <div className={cls('badge', `badge--${type}`, 'header-smallest')} {...props}>
      <img src={Icons[type]} alt="" />
      {type.toUpperCase()}
    </div>
  );
};
