import React from 'react';
import { Svg } from '../../assets/svg';

export interface Props {
  value: string;
  selectValue: (e: string) => void;
}

/**
 * Primary UI component for user interaction
 */
const images = ['light', 'dark', 'dark_red', 'dark_blue'];

export const ThemeSelector: React.FC<Props> = ({ value, selectValue }) => {
  const onClickFunc = (e: any): void => {
    selectValue && selectValue(e.target.id);
  };

  const renderImages = images.map((res, key) => {
    return (
      <div key={key} className="image-container" onClick={onClickFunc}>
        <img className="theme-img" src={Svg[`theme_${res}`]} alt={res} id={res} />
        {value === res && <img className="check" src={Svg['theme_check_blue']} alt="theme_check_blue" />}
      </div>
    );
  });
  return <div className="theme-selector-container">{renderImages}</div>;
};
