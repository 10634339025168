import React from 'react';
import { StakingPoolList } from '../../../components/StakingPoolList';

export const StakingAwaiting: React.FC = () => {
  return (
    <StakingPoolList
      title="Awaiting pools"
      subTitle="List of awaiting pools (new pools can take a few minutes to show up here)"
      filter={(pool: any): boolean => {
        return pool.start.getTime() === 0;
      }}
    />
  );
};
