import { BigNumber } from 'ethers';

export const calculatePercentage = (inputAmount: BigNumber, totalAmount: BigNumber): number => {
  let percentage = 0;
  const inputAmountBN = BigNumber.from(inputAmount);
  const totalAmountBN = BigNumber.from(totalAmount);
  const hundredBN = BigNumber.from(100);
  const zeroBN = BigNumber.from(0);

  if (inputAmountBN.gt(zeroBN) && totalAmountBN.gt(zeroBN)) {
    const resultDivBN = inputAmountBN.div(totalAmountBN);
    const percentBN = resultDivBN.mul(hundredBN);
    percentage = Math.round(Number(percentBN));
  }

  return percentage;
};
