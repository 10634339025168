import { Icons } from '../../assets/icons';
import { DropdownOption } from '../Dropdown';

export const tierTenant = 'bonq';

export function getTierOptions(): DropdownOption[] {
  return [
    {
      value: 'default',
      icon: Icons['pool'],
      name: 'Default',
    },
    {
      value: 'member',
      icon: Icons['pool'],
      name: 'Member',
    },
    {
      value: 'bronze',
      icon: Icons['pool'],
      name: 'Bronze',
    },
    {
      value: 'silver',
      icon: Icons['pool'],
      name: 'Silver',
    },
    {
      value: 'gold',
      icon: Icons['pool'],
      name: 'Gold',
    },
    {
      value: 'platinum',
      icon: Icons['pool'],
      name: 'Platinum',
    },
  ];
}

export function getTierValue(tier: string | number): number | string {
  const tierValue: { [key: string]: number } = {
    member: 0,
    bronze: 1,
    silver: 2,
    gold: 3,
    platinum: 4,
    default: 5,
  };

  if (typeof tier === 'string') return tierValue[tier];

  const tempObj: { [key: number]: string } = {};

  for (const key in tierValue) {
    tempObj[tierValue[key]] = key;
  }

  return tempObj[tier];
}
