import { adminsSlice, BackendAdmin } from './slice';
import { createAction } from '@reduxjs/toolkit';

export const {
  setAdminAddresses,
  addAdminAddress,
  deleteAdminAddress,
  setAdminsMeta,
  addAdminMeta,
  deleteAdminMeta,
  setTempAdmins,
  clearTempAdmins,
} = adminsSlice.actions;

export const getAdminData = createAction('admins/getAdmin-data');

export const processAdminsEdit = createAction<BackendAdmin[]>('admins/process-edit');

export const confirmAdminsEdit = createAction<() => void>('admins/confirm-edit');
