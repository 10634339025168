import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StakingTitle } from '../../../components/StakingTitle';
import { Form } from './FormInput';

export const ThirdPartyIntegrations: React.FC = () => {
  const history = useHistory();
  const state: any = history.location.state;

  useEffect(() => {
    if (!state) history.push('/configuration/brand');
  }, [state]);

  const onSubmit = (data: any): void => {
    const newData = { ...state, third_party_integrations: data };
    // TODO: move this logic to sagas
    localStorage.setItem('third_party_integrations', data);
    history.push('/configuration/social_media', newData);
  };

  const goBack = (): void => {
    history.push('/configuration/contact_email', { ...state });
  };

  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Third Party Integrations"
          message="Please provide your third party integration details."
          steps={8}
          currentStep={6}
        />
        <Form onSubmit={onSubmit} goBack={goBack} savedField={state?.third_party_integrations} />
      </div>
    </>
  );
};
