import React from 'react';
import { Icons } from '../../assets/icons';

type ChildrenProps = JSX.Element | JSX.Element[] | string;

export interface ModernStepperProps {
  children: JSX.Element | JSX.Element[];
}

export const ModernStepper: React.FC<ModernStepperProps> = ({ children }) => {
  return (
    <>
      <div className="modern-stepper">{children}</div>
    </>
  );
};

interface ModernStepProps {
  children: ChildrenProps;
  stepNumber: number;
  activeStep: number;
  title: string;
  subtitle: string;
}

export const ModernStep: React.FC<ModernStepProps> = ({ children, stepNumber, activeStep, title, subtitle }) => {
  return (
    <>
      <div
        className={`modern-step ${stepNumber === activeStep && 'modern-step-active'} ${
          (stepNumber > activeStep || stepNumber < activeStep - 1) && 'modern-step-away'
        }`}
      >
        <div className="modern-step-head">
          <div className="modern-step-number">{stepNumber + 1}</div>
          <div className="modern-step-header">
            <div className="modern-step-title">{title}</div>
            <div className="modern-step-subtitle">{subtitle}</div>
          </div>
          {stepNumber < activeStep && <img src={Icons.nav_circle_ok} className="modern-step-check-icon" />}
        </div>

        <div className="modern-step-body">
          <div className="modern-step-line"></div>
          <div className="modern-step-content">
            <div className="modern-step-subtitle">{subtitle}</div>
            {children}
            <div className="modern-step-divider"></div>
          </div>
        </div>
      </div>
    </>
  );
};

interface ModernStepPanelProps {
  children: ChildrenProps;
}

export const ModernStepPanel: React.FC<ModernStepPanelProps> = ({ children }) => {
  return <div className="modern-step-panel">{children}</div>;
};

interface ModernStepButtonsProps {
  children: ChildrenProps;
}

export const ModernStepButtons: React.FC<ModernStepButtonsProps> = ({ children }) => {
  return <div className="modern-step-buttons">{children}</div>;
};
