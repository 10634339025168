import {
  all,
  fork,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeLatest,
} from 'redux-saga/effects';
import { Web3ProviderState } from '../web3/slice';
import { getEthersWeb3Provider } from '../web3/selectors';
import { getMultisigContract, MultisigContract } from './getMultisigContract';
import { Contract, ContractInterface, providers } from 'ethers';
import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import { storeContract } from '../contracts/actions';
import { EventChannel } from '@redux-saga/core';
import { ChannelTakeEffect } from '@redux-saga/core/effects';
import { eventChannel } from 'redux-saga';
import { getAdminData } from '../../admins/actions';
import { getTransactionsData } from '../../transactions/actions';
import { initializedProject, setMultisig } from '../../project/action';
import { getMultisig } from '../../project/selectors';

function* initializeContract(): Generator<
  SelectEffect | PutEffect | ForkEffect | Promise<MultisigContract>,
  void,
  Web3ProviderState & (string | null) & MultisigContract
> {
  try {
    const web3 = yield select(getEthersWeb3Provider);
    if (!web3) throw Error('no web3');

    const address = yield select(getMultisig);
    if (!address) throw Error('no multisig address');

    const { abi } = yield getMultisigContract();
    const contract = new Contract(address, abi, web3.signer);
    yield put(storeContract('wallet', contract));

    yield put(getAdminData());
    yield put(getTransactionsData());
    yield fork(watchOnMessage, contract as MultiSigWallet);
  } catch (e) {
    console.error(e);
  }
}

type Events =
  | SignerAddedEvent
  | SignerRemovedEvent
  | TransactionConfirmedEvent
  | TransactionExecutedEvent
  | TransactionFailedEvent
  | TransactionSubmittedEvent;

interface SignerAddedEvent {
  event: 'SignerAdded';
  payload: string;
}

interface SignerRemovedEvent {
  event: 'SignerRemoved';
  payload: string;
}

interface TransactionConfirmedEvent {
  event: 'TransactionConfirmed';
  payload: {
    signer: string;
    transactionId: number;
  };
}

interface TransactionExecutedEvent {
  event: 'TransactionExecuted';
  payload: number;
}

interface TransactionFailedEvent {
  event: 'TransactionFailed';
  payload: number;
}

interface TransactionSubmittedEvent {
  event: 'TransactionSubmitted';
  payload: number;
}

function* watchOnMessage(
  contract: MultiSigWallet,
): Generator<
  EventChannel<Events> | ChannelTakeEffect<Events> | PutEffect | TakeEffect,
  void,
  EventChannel<Events> & Events
> {
  const channel = yield eventChannel((emit) => {
    const messageListener: providers.Listener = (event) => {
      if (event.event === 'SignerAdded' || event.event === 'SignerRemoved') {
        emit({ event: event.event, payload: event.args.signer });
      }
      if (event.event === 'TransactionConfirmed') {
        emit({
          event: event.event,
          payload: {
            signer: event.args.signer,
            transactionId: event.args.transactionId,
          },
        });
      }
      if (
        event.event === 'TransactionExecuted' ||
        event.event === 'TransactionFailed' ||
        event.event === 'TransactionSubmitted'
      ) {
        emit({ event: event.event, payload: event.args.transactionId.toNumber() });
      }
    };
    contract.on('*', messageListener);

    return (): void => {
      contract.off('*', messageListener);
    };
  });

  while (true) {
    try {
      const { event, payload } = yield take(channel);
      if (event === 'SignerAdded' || event === 'SignerRemoved') {
        yield put(getAdminData());
      }
      if (
        event === 'TransactionConfirmed' ||
        event === 'TransactionExecuted' ||
        event === 'TransactionFailed' ||
        event === 'TransactionSubmitted'
      ) {
        yield put(getTransactionsData());
      }
    } catch (err) {
      console.error('contract on:', err);
    }
  }
}

export function* multisigSagaWatcher(): Generator {
  yield all([takeLatest(initializedProject, initializeContract), takeLatest(setMultisig, initializeContract)]);
}
