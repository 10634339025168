import { BytesLike, Contract, ContractInterface, providers, Signer } from 'ethers';
import { deployContract } from './deployContract';
import { loadContract } from './loadContract';
import { useWeb3 } from './useWeb3';

interface ContractLoaderProps {
  name: string;
  bytecode: BytesLike;
  abi: ContractInterface;
}

export class ContractLoader {
  contractData: ContractLoaderProps;

  constructor(contractData: ContractLoaderProps) {
    this.contractData = contractData;
  }

  async deploy(args: Array<any>): Promise<Contract> {
    return await deployContract(this.contractData.name, this.contractData.abi, this.contractData.bytecode, args);
  }

  load(address: string): Contract {
    return loadContract(this.contractData.name, address, this.contractData.abi);
  }
}
