import React, { useState, useEffect } from 'react';
import { ThemeSelector } from '../../../components/ThemeSelector';
import { Button } from '../../../components/Button';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useSelector } from 'react-redux';

export interface Props {
  onSubmit: (data: any) => void;
  goBack: () => void;
  savedField: string;
}

export const Form: React.FC<Props> = ({ onSubmit, goBack, savedField }): JSX.Element => {
  const config = useSelector(getProjectConfigAll);

  // TODO: get theme state from redux
  const [value, setValue] = useState(localStorage.getItem('theme') || config.theme || 'light');

  useEffect(() => {
    if (savedField) {
      setValue(savedField);
    }
  }, [savedField]);

  useEffect(() => {
    return (): void => {
      document.body.className = localStorage.getItem('theme') || 'light';
    };
  }, []);

  const handleChange = (e: string): void => {
    setValue(e);
    document.body.className = e;
  };

  const handleOnSubmit = (): void => {
    onSubmit(value);
  };
  const BackFunc = (): void => {
    goBack();
  };
  return (
    <>
      <div className="text-field-container">
        <ThemeSelector value={value} selectValue={handleChange} />
      </div>
      <div className="button-container">
        <Button
          color="tertiary"
          iconposition="left"
          icon="arrow_left"
          label="Go back"
          onClick={BackFunc}
          size="large"
        />
        <Button
          data-cy="configuration-theme-next"
          color="primary"
          label="Next"
          icon="arrow_right"
          onClick={handleOnSubmit}
          size="large"
          iconposition="right"
        />
      </div>
    </>
  );
};
