import React from 'react';
import { LiquidityMiningPoolList } from '../../../components/LiquidityMiningPoolList';
import { LiquidityMiningPool } from '../../../components/LiquidityMiningPool';

export const ExpiredPools: React.FC = () => {
  return (
    <LiquidityMiningPoolList
      title="Expired pools"
      subTitle="List of expired pools"
      filter={(pool: LiquidityMiningPool): boolean => {
        return pool.start.getTime() !== 0 && pool.end < new Date();
      }}
    />
  );
};
