import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import { ClipboardTooltip } from '../ClipboardTooltip';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-light.svg';
import { getNetworkId } from '../../sdk/helpers/network';
import { getNetworkAndProtocol } from '../../utils/helpers';
import { PoolAsset } from '../PoolAsset';
import { Icons } from '../../assets/icons';
import { NonCompoundingRewardsPoolInfinite } from '../../sdk/staking-v2/NonCompoundingRewardsPoolInfinite';
import { InfiniteStakingPool } from '../InfiniteStakingPool';
import { TextField } from '../TextField';
import { Button } from '../Button';
import { checkRewardBalanceV2 } from '../../sdk/helpers/checkRewardBalance';
import { useDispatch } from 'react-redux';
import { WeiAmount, WeiInput } from '../WeiInput';
import './index.scss';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { addSnackbar } from '../../ducks/snackbar/action';
import { addDecimals } from '../../sdk/helpers/addDecimals';
import { fetchProject } from '../../ducks/project/action';

export const PoolFundRewards: React.FC<{
  pool: InfiniteStakingPool;
  onClose: (wasWhitelisted: boolean) => void;
}> = ({ pool: poolProps, onClose }) => {
  const dispatch = useDispatch();
  const [network, setNetwork] = useState('');
  const [pool, setPool] = useState<NonCompoundingRewardsPoolInfinite>();
  const [availableBalances, setAvailableBalances] = useState<BigNumber[] | undefined>();
  const [sendingRewards, setSendingRewards] = useState<boolean>(false);
  const [weiRewards, setWeiRewards] = useState<WeiAmount[]>(
    poolProps.campaignRewards.map(({ decimals }) => ({ amount: 0, decimals })),
  );

  useEffect(() => {
    getNetworkId().then((id) => {
      const [network] = getNetworkAndProtocol(id);
      setNetwork(network);
    });
    return (): void => {
      setNetwork('');
    };
  }, []);

  useEffect(() => {
    const pool = new NonCompoundingRewardsPoolInfinite();
    pool.load(poolProps.address);

    setPool(pool);
  }, [poolProps]);

  useEffect(() => {
    const checkBalance = async (): Promise<void> => {
      if (!pool || !pool.contract) return;

      const availableBalances: BigNumber[] = [];
      for (let index = 0; index < poolProps.campaignRewards.length; index++) {
        const availableBalance = await pool.getAvailableBalance(index);
        availableBalances.push(availableBalance);
      }

      setAvailableBalances(availableBalances);
    };

    checkBalance().catch(console.error);

    const intervalId = setInterval(() => {
      checkBalance().catch(console.error);
    }, 5000);

    return (): void => {
      clearInterval(intervalId);
    };
  }, [pool, network, sendingRewards]);

  return (
    <Modal title="Fund rewards" onClose={(): void => onClose(false)}>
      <div className="modal-text">
        In order to be able to start a new epoch, the pool needs to have a suffient amount of rewards to handout at the
        end of each epoch.
      </div>

      <div className="modal-form">
        <div className="mb-24">
          <div className="text-bold">Deposit amount</div>
        </div>
        <div>
          <div className="pool-content-header">Total rewards next epoch</div>
          {poolProps.campaignRewards.map((reward, index) => (
            <div key={index} className="flex pool-content-row">
              <PoolAsset
                asset={{
                  balance: availableBalances?.[index] || 0,
                  symbol: reward.symbol,
                  image: Icons[reward.symbol.toLowerCase()],
                  address: reward.address,
                }}
                key={index}
              />
              <WeiInput
                label={reward.symbol + ' reward'}
                value={weiRewards[index]}
                onChange={(value): void => {
                  setWeiRewards(
                    weiRewards.map((r, i) => {
                      if (i === index) r = value;
                      return r;
                    }),
                  );
                }}
              />
              <Button
                size="medium"
                label={sendingRewards ? 'Sending' : 'Send funds'}
                icon={sendingRewards ? 'reload' : 'pool_blue'}
                disabled={sendingRewards}
                spin={sendingRewards}
                iconposition="right"
                onClick={async (): Promise<void> => {
                  setSendingRewards(true);
                  const rewardContract = loadERC20(reward.address);

                  const rewardAmount = addDecimals(weiRewards[index].amount || '0', weiRewards[index].decimals);
                  try {
                    await (await rewardContract.transfer(poolProps.address, rewardAmount)).wait();
                    setWeiRewards(
                      weiRewards.map((r, i) => {
                        if (i === index) r = { amount: 0, decimals: reward.decimals };
                        return r;
                      }),
                    );
                  } catch {
                    dispatch(
                      addSnackbar({
                        type: 'error',
                        title: 'Error',
                        content: `Unable to transfer tokens`,
                      }),
                    );
                  }

                  setSendingRewards(false);
                  dispatch(fetchProject());
                }}
              />
            </div>
          ))}
        </div>
        <br />

        <div className="pool-form-info">
          Send tokens to contract for the next epoch:
          <br />
          <span>{poolProps.address}</span>{' '}
          <ClipboardTooltip content={poolProps.address}>
            <CopyIcon className="copy-icon" />
          </ClipboardTooltip>
        </div>
      </div>
    </Modal>
  );
};
