import React from 'react';
import { NavLink } from 'react-router-dom';

export interface TabMenuItemProps {
  id: string;
  title: string;
  baseLocation?: string;
  disabled?: boolean;
  dataCY?: string;
}

/**
 * Primary UI component for user interaction
 */
export const TabMenuItem: React.FC<TabMenuItemProps> = ({ id, baseLocation, title, disabled, dataCY = '' }) => {
  const onClick = (event: any): void => {
    if (disabled) event.preventDefault();
  };

  if (baseLocation === '/') {
    baseLocation = '';
  }

  return (
    <NavLink
      className={`tab-menu-item tab-text ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      to={`${baseLocation}/${id}`}
      data-cy={dataCY}
    >
      {title}
    </NavLink>
  );
};
