import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { approve, pay } from '../../../../../sdk/payment';
import { AsyncLoadingButton, Button } from '../../../../../components/Button';
import { Popover } from '../../../../../components/Popover';
import { Panel } from '../Panel';
import { Icons } from '../../../../../assets/icons';
import { parseTokenAmount } from '../../helpers/parseTokenAmount';
import { addSnackbar } from '../../../../../ducks/snackbar/action';
import { currencyFormatter } from '../../../../../utils/currency-formatter';
import { useDispatch } from 'react-redux';

import './PaymentPanel.scss';
import { loadERC20 } from '../../../../../sdk/helpers/loadERC20';
import { getAddress } from '../../../../../sdk/helpers/getAddress';

export interface PaymentPanelProps {
  title: string;
  description: string;
  icon: string;
  token: string;
  tokenPriceAmount: string;
  paymentAddress: string;
  tokenAddress: string;
  tokenPriceAmountBN: BigNumber;
  tokenDefaultPriceAmountBN: BigNumber;
  userToken: string;
  onPaid: () => void;
  setFetching: (value: boolean) => void;
  fetching: boolean;
  tokenDecimals: number;
  availableCredits: number;
  disabled: boolean;
  allowance: BigNumber;
  wrapped: boolean;
  days: number;
}

export const PaymentPanel: React.FC<PaymentPanelProps> = ({
  title,
  description,
  icon,
  token,
  tokenPriceAmount,
  tokenAddress,
  tokenPriceAmountBN,
  tokenDefaultPriceAmountBN,
  userToken,
  onPaid,
  setFetching,
  fetching,
  tokenDecimals,
  availableCredits,
  disabled,
  allowance,
  wrapped,
  days,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0));
  const dispatch = useDispatch();

  const makeApprove = async (): Promise<void> => {
    try {
      setFetching(true);
      setLoading(true);
      // allowance is atleast 3 times of actual price
      const tx = await approve(tokenAddress, tokenDefaultPriceAmountBN.mul(3));
      const receipt = await tx.wait();

      onPaid();
    } catch (e) {
      console.error(e);
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Transaction failed',
          content: 'The transaction failed, please check settings and try again',
        }),
      );
      setFetching(false);
    }
    setLoading(false);
  };

  const makePayment = async (): Promise<void> => {
    try {
      setFetching(true);
      setLoading(true);
      const tx = await pay(userToken, days, wrapped);
      const receipt = await tx.wait();

      retrieveBalance();
      onPaid();
    } catch (e) {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Transaction failed',
          content: 'The transaction failed, please check settings and try again',
        }),
      );
      setFetching(false);
    }
    setLoading(false);
  };

  const getPriceFromUsdt = (price: BigNumber): string => {
    return currencyFormatter(Number(price.div(BigNumber.from(10).pow(tokenDecimals))));
  };

  const retrieveBalance = async (): Promise<void> => {
    try {
      const token = loadERC20(tokenAddress);
      const user = await getAddress();
      const balanceBN = await token.balanceOf(user);
      setBalance(balanceBN);
    } catch (e) {}
  };

  useEffect(() => {
    retrieveBalance();

    return (): void => {
      setBalance(BigNumber.from(0));
    };
  }, [disabled]);

  useEffect(() => {
    if (tokenPriceAmountBN.gt(0))
      setIsApproved(tokenPriceAmountBN.gt(BigNumber.from(0)) && allowance.gte(tokenPriceAmountBN));
  }, [tokenPriceAmountBN, allowance]);

  useEffect(() => {
    if (tokenPriceAmountBN.gt(0) && tokenDefaultPriceAmountBN.gt(0) && availableCredits >= 0) setLoading(false);
  }, [tokenPriceAmountBN, tokenDefaultPriceAmountBN, availableCredits]);
  return (
    <Panel className="payment-panel">
      <h5 className="m-0 mb-16">{title}</h5>
      <p className="m-0 text-sm description">{description}</p>
      <div className="available-credits">
        <strong>{availableCredits}</strong> Credit(s)
      </div>
      <div className="horizontal-spacer" />
      <div className="token-price mt-4">
        <h5 className="m-0">
          {tokenPriceAmount}{' '}
          {!tokenDefaultPriceAmountBN.eq(tokenPriceAmountBN) && (
            <>
              <div className="discountInfo">
                <small>
                  <s>{getPriceFromUsdt(tokenDefaultPriceAmountBN)}</s>
                </small>
                <Popover
                  content={`Price is discounted because of earlier pools deployed on this network. If previously deployed between 2-3 pools, there is a 10% discount. If previously deployed between 3-5 pools, there is a 20% discount. If you have previously deployed more than 5 pools, there is a 35% discount`}
                >
                  <img src={Icons.info} className="discounted-info-icon" />
                </Popover>
              </div>
            </>
          )}
        </h5>
        <div className="ticker">
          <img src={Icons[icon]} alt="token icon" />
          <span className="text-secondary text-roboto-condensed">{token}</span>
        </div>
      </div>
      <div className="button-container">
        {disabled || loading ? (
          <AsyncLoadingButton
            onClick={isApproved ? makePayment : makeApprove}
            size="medium"
            disabled={balance.lt(tokenPriceAmountBN) || disabled || loading}
            label={isApproved ? 'Buy credit' : `Approve ${token}`}
            icon={(disabled || loading) && fetching ? 'reload' : ''}
            spin={disabled || loading}
          />
        ) : (
          <Button
            onClick={isApproved ? makePayment : makeApprove}
            size="medium"
            disabled={balance.lt(tokenPriceAmountBN) || disabled || loading}
            label={isApproved ? 'Buy credit' : `Approve ${token}`}
          />
        )}
        <div className="ps">{`${wrapped ? 'WUSDT' : 'USDT'} Balance: ${getPriceFromUsdt(balance)}`}</div>
      </div>
    </Panel>
  );
};
