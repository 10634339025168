import React from 'react';
import { Chip } from '../Chip';
import { Icons } from '../../assets/icons';
import cls from 'classnames';

interface IProps {
  current: number;
  max: number;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
  onChipClick: () => void;
}

export const NotificationHeader: React.FC<IProps> = ({
  current,
  max,
  onLeftArrowClick,
  onRightArrowClick,
  onChipClick,
}) => {
  const handleLeftArrowClick = (): void => {
    if (current != 1) onLeftArrowClick();
  };
  const handleRightArrowClick = (): void => {
    if (current != max) onRightArrowClick();
  };

  return (
    <div className="notification-header">
      <h4>
        Notification {current}/{max}
      </h4>
      <div className="notification-navigation">
        <img
          className={cls('flip', { 'disabled-arrow': current === 1 })}
          src={Icons.caret_right}
          onClick={handleLeftArrowClick}
        />
        <div className="arrow-separator" />
        <img
          className={cls({ 'disabled-arrow': current === max })}
          src={Icons.caret_right}
          onClick={handleRightArrowClick}
        />
      </div>
      <Chip count={max} onClick={onChipClick} />
    </div>
  );
};
