import { BigNumber } from 'ethers';
import { createDuration, DurationUnits, durationToUnit } from '../../sdk/helpers/duration';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { InfiniteStakingInterface } from '../../types/dto/Project.dto';
import { NonCompoundingRewardsPoolInfinite } from '../../sdk/staking-v2/NonCompoundingRewardsPoolInfinite';
import { LMReward, PoolVersions } from '../../utils/helpers';
import { InfiniteStakingPool, InfiniteStakingState } from '.';
import { getCampaignCredits } from '../../sdk/payment';

export async function loadV2(pool: InfiniteStakingInterface): Promise<InfiniteStakingPool> {
  const poolContract = new NonCompoundingRewardsPoolInfinite();
  await poolContract.load(pool.campaignAddress);

  const [
    startTimestamp,
    endTimestamp,
    rewardTokens,
    stakingToken,
    contractsStakeLimit,
    stakeLimit,
    epochDuration,
    name,
    totalStaked,
    hasCampaignStarted,
  ] = await Promise.all([
    poolContract.getStartTimestamp(),
    poolContract.getEndTimestamp(),
    poolContract.getRewardTokens(),
    poolContract.getStakingToken(),
    poolContract.getContractStakeLimit(),
    poolContract.getStakeLimit(),
    poolContract.getEpochDuration(),
    poolContract.getName(),
    poolContract.getTotalStaked(),
    poolContract.hasStakingStarted(),
  ]);

  const tokenContract = loadERC20(stakingToken);

  // Check for campaign scheduled
  const startDate = new Date(startTimestamp.toNumber() * 1000);
  const endDate = new Date(endTimestamp.toNumber() * 1000);
  const duration = createDuration(endDate.getTime() - startDate.getTime(), DurationUnits.milliseconds);

  const secondsPerWeek = 604800;
  const campaignMessage = pool.campaignMessage || '';
  const symbol = await tokenContract.getSymbol();
  const decimals = await tokenContract.getDecimals();

  // Rewards

  const rewardPromises: Promise<any>[] = [];

  async function getReward(index: number): Promise<BigNumber> {
    try {
      return await poolContract.getRewardPerSecond(index);
    } catch (e) {
      return BigNumber.from(0);
    }
  }

  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardContract = loadERC20(rewardTokens[i]);
    rewardPromises.push(
      getReward(i),
      rewardContract.getSymbol(),
      rewardContract.getDecimals(),
      poolContract.getAvailableBalance(i),
    );
  }
  const rewardResults: any[] = await Promise.all(rewardPromises);

  const remainingSeconds = endTimestamp.sub(Date.now()).toNumber();
  const campaignRewards: LMReward[] = [];
  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardPerSecond = rewardResults[i * 4] as BigNumber;
    campaignRewards.push({
      symbol: rewardResults[i * 4 + 1] as string,
      address: rewardTokens[i],
      decimals: rewardResults[i * 4 + 2] as number,
      excessRewards: rewardResults[i * 4 + 3] as BigNumber,
      hasExcessRewards: (rewardResults[i * 4 + 3] as BigNumber)
        .div(BigNumber.from(10).pow(rewardResults[i * 4 + 2]))
        .gte(1),
      rewardPerSecond: rewardPerSecond,
      totalRewards: rewardPerSecond
        .mul(durationToUnit(duration, DurationUnits.seconds))
        .div(BigNumber.from(10).pow(18)),
      weeklyRewards: BigNumber.from(rewardPerSecond).mul(secondsPerWeek).div(BigNumber.from(10).pow(18)),
      remainingRewardAmount:
        remainingSeconds > 0
          ? rewardPerSecond.mul(remainingSeconds).div(BigNumber.from(10).pow(18))
          : BigNumber.from(0),
    });
  }

  // Get now in seconds and convert to BN
  const now = Math.floor(Date.now() / 1000);
  const nowBN = BigNumber.from(now);
  const currentEpochAssigned = endTimestamp.gt(nowBN);

  const rewardsDistributing = rewardTokens.length > 0 && hasCampaignStarted && currentEpochAssigned;
  let unlockedRewards = false;
  if (hasCampaignStarted) {
    for (let index = 0; index < rewardTokens.length; index++) {
      const availableBalance: BigNumber = await poolContract.getAvailableBalance(index);

      unlockedRewards = unlockedRewards || availableBalance.div(epochDuration).gt(0);
    }
  }

  const poolChain = new NonCompoundingRewardsPoolInfinite();
  const state = await poolChain.load(pool.campaignAddress).then(() => poolChain.getState());

  return {
    address: pool.campaignAddress,
    symbol,
    decimals,
    campaignRewards,
    totalLimit: contractsStakeLimit,
    walletLimit: stakeLimit,
    duration,
    epochDuration: createDuration(epochDuration.toNumber(), DurationUnits.seconds),
    start: startDate,
    end: endDate,
    done: Date.now() > endDate.getTime(),
    version:
      pool.version === PoolVersions.v2
        ? PoolVersions.v2
        : pool.version === PoolVersions.v4
        ? PoolVersions.v4
        : PoolVersions.v3,
    name,
    campaignMessage: campaignMessage,
    stakingTokenAddress: stakingToken,
    state,
    totalStaked,
  };
}
