import React from 'react';
import { Icons } from '../../assets/icons';

export const Modal: React.FC<{ title: string; onClose: () => void }> = (props) => {
  return (
    <React.Fragment>
      <div className="modal">
        <div className="modal-header">
          {props.title}
          <div className="spacer"></div>
          <img className="modal-close" src={Icons.close} onClick={props.onClose} />
        </div>

        {props.children}
      </div>
    </React.Fragment>
  );
};
