import { Duration, durationToUnit, DurationUnits } from '../sdk/helpers/duration';
import { formatNumber } from './formatNumber';

export function formatDuration(duration: Duration, returnDuration = false): string | Duration {
  const years = durationToUnit(duration, DurationUnits.years);
  if (years >= 1) {
    return returnDuration
      ? { value: Number(formatNumber(years)), unit: DurationUnits.years }
      : formatNumber(years) + ' years';
  }

  const months = durationToUnit(duration, DurationUnits.months);
  if (months >= 1) {
    return returnDuration
      ? { value: Number(formatNumber(months)), unit: DurationUnits.months }
      : formatNumber(months) + ' months';
  }

  const days = durationToUnit(duration, DurationUnits.days);
  if (days >= 1) {
    return returnDuration
      ? { value: Number(formatNumber(days)), unit: DurationUnits.days }
      : formatNumber(days) + ' days';
  }

  const hours = durationToUnit(duration, DurationUnits.hours);
  if (hours >= 1) {
    return returnDuration
      ? { value: Number(formatNumber(hours)), unit: DurationUnits.hours }
      : formatNumber(hours) + ' hours';
  }

  const minutes = durationToUnit(duration, DurationUnits.minutes);
  if (minutes >= 1) {
    return returnDuration
      ? { value: Number(formatNumber(minutes)), unit: DurationUnits.minutes }
      : formatNumber(minutes) + ' minutes';
  }

  const seconds = durationToUnit(duration, DurationUnits.seconds);
  return returnDuration
    ? { value: Number(formatNumber(seconds)), unit: DurationUnits.seconds }
    : formatNumber(seconds) + ' seconds';
}
