import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DappsIntegration } from '../../pages/Configuration/DAppsIntegration/FormInput';
import { SocialMedia } from '../../pages/Configuration/SocialMedia/FormInput';
import { ThirdPartyIntegrations } from '../../pages/Configuration/ThirdPartyIntegrations/FormInput';
import { InfiniteStakingInterface, LMInterface, StakingInterface, Token } from '../../types/dto/Project.dto';

export interface ProjectState {
  pending: boolean;
  id: string;
  name: string;
  website: string;
  coinGeckoID: string;
  token: null | {
    name: string;
    symbol: string;
    address: string;
  };
  tokens: {
    [network: string]: {
      name: string;
      symbol: string;
      address: string;
    };
  };
  multisig: {
    bsc: null | string;
    eth: null | string;
    polygon: null | string;
    avalanche: null | string;
    ewc: null | string;
    moonbeam: null | string;
    songbird: null | string;
  };
  config: {
    factory: null | {
      [key: string]: string;
    };
    stakingFactory: null | {
      [key: string]: string;
    };
    uniswap: {
      poolTokens: {
        [key: string]: string;
      };
      rewardContracts: {
        [key: string]: string;
      };
      lockSchemes: {
        [key: string]: string;
      };
      rewardsAddress: {
        [key: string]: string;
      };
      tokenContracts: {
        [key: string]: string;
      };
      contractNames: {
        [key: string]: string;
      };
    } | null;
    balancer: {
      poolTokens: {
        [key: string]: string;
      };
      rewardContracts: {
        [key: string]: string;
      };
      lockSchemes: {
        [key: string]: string;
      };
      rewardsAddress: {
        [key: string]: string;
      };
      tokenContracts: {
        [key: string]: string;
      };
      contractNames: {
        [key: string]: string;
      };
    } | null;
    pancakeswap: {
      poolTokens: {
        [key: string]: string;
      };
      rewardContracts: {
        [key: string]: string;
      };
      lockSchemes: {
        [key: string]: string;
      };
      rewardsAddress: {
        [key: string]: string;
      };
      tokenContracts: {
        [key: string]: string;
      };
      contractNames: {
        [key: string]: string;
      };
    } | null;
    quickswap: {
      poolTokens: {
        [key: string]: string;
      };
      rewardContracts: {
        [key: string]: string;
      };
      lockSchemes: {
        [key: string]: string;
      };
      rewardsAddress: {
        [key: string]: string;
      };
      tokenContracts: {
        [key: string]: string;
      };
      contractNames: {
        [key: string]: string;
      };
    } | null;
    pangolin: {
      poolTokens: {
        [key: string]: string;
      };
      rewardContracts: {
        [key: string]: string;
      };
      lockSchemes: {
        [key: string]: string;
      };
      rewardsAddress: {
        [key: string]: string;
      };
      tokenContracts: {
        [key: string]: string;
      };
      contractNames: {
        [key: string]: string;
      };
    } | null;
    traderjoe: {
      poolTokens: {
        [key: string]: string;
      };
      rewardContracts: {
        [key: string]: string;
      };
      lockSchemes: {
        [key: string]: string;
      };
      rewardsAddress: {
        [key: string]: string;
      };
      tokenContracts: {
        [key: string]: string;
      };
      contractNames: {
        [key: string]: string;
      };
    } | null;
    pairsArray: {
      [key: string]: {
        [key: string]: string[][];
      };
    } | null;
    stakerArray: {
      [key: string]: string[][];
    } | null;
    staker: {
      eth: {
        stakeTokens: {
          [key: string]: string;
        };
        stakerContracts: {
          [key: string]: string;
        };
        rewards: {
          [key: string]: string;
        };
        helperContracts: {
          [key: string]: string;
        };
        tokenContracts: {
          [key: string]: string;
        };
        contractNames: {
          [key: string]: string;
        };
        period: {
          [key: string]: string;
        };
      };
      bsc: {
        stakeTokens: {
          [key: string]: string;
        };
        stakerContracts: {
          [key: string]: string;
        };
        rewards: {
          [key: string]: string;
        };
        helperContracts: {
          [key: string]: string;
        };
        tokenContracts: {
          [key: string]: string;
        };
        contractNames: {
          [key: string]: string;
        };
        period: {
          [key: string]: string;
        };
      };
    } | null;
    campaignsLM?: LMInterface[];
    campaignsStaking?: StakingInterface[];
    campaignsInfiniteStaking?: InfiniteStakingInterface[];
    tokens?: Token[];
  };
  brandColor: string;
  contactEmail: string;
  logoUrl: string;
  socialMedia: string | SocialMedia;
  thirdPartyIntegrations: string | ThirdPartyIntegrations;
  dappsIntegration: string | DappsIntegration;
  theme: string;
}

export type MultisigNetworkds = keyof ProjectState['multisig'];

const initialState: ProjectState = {
  pending: true,
  id: 'unknown',
  name: 'unknown',
  website: 'unknown',
  coinGeckoID: 'unknown',
  token: null,
  tokens: {},
  multisig: {
    bsc: null,
    eth: null,
    polygon: null,
    avalanche: null,
    ewc: null,
    moonbeam: null,
    songbird: null,
  },
  config: {
    factory: null,
    stakingFactory: null,
    uniswap: null,
    balancer: null,
    pancakeswap: null,
    quickswap: null,
    pangolin: null,
    traderjoe: null,
    pairsArray: null,
    stakerArray: null,
    staker: null,
    campaignsLM: [],
    campaignsStaking: [],
    campaignsInfiniteStaking: [],
    tokens: [],
  },
  brandColor: '',
  contactEmail: '',
  logoUrl: '',
  socialMedia: '',
  thirdPartyIntegrations: '',
  dappsIntegration: '',
  theme: '',
};

export const projectSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectState>): ProjectState => {
      return action.payload;
    },
    setMultisig: (state, action: PayloadAction<{ address: string; network: MultisigNetworkds }>): void => {
      state.multisig[action.payload.network] = action.payload.address;
    },
    setConfig: (
      state,
      action: PayloadAction<{
        key: 'factory' | 'stakingFactory';
        value: {
          [key: string]: string;
        };
      }>,
    ): void => {
      state.config[action.payload.key] = action.payload.value;
    },
    setConfigLM: (
      state,
      action: PayloadAction<{
        value: LMInterface[];
      }>,
    ): void => {
      state.config.campaignsLM = action.payload.value;
    },
  },
});
