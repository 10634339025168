import React, { useEffect, useState } from 'react';
import { Icons } from '../../assets/icons';
import cls from 'classnames';
import { projectSagaWatcher } from '../../ducks/project/sagas';

export interface ButtonProps {
  /*
      Color palette
  */
  color?: 'primary' | 'primary-empty' | 'secondary' | 'negative' | 'tertiary' | 'process';
  /*
      Button icon rotate
  */
  spin?: boolean;
  /*
      Button disabled
  */
  disabled?: boolean;
  /*
      Button size
  */
  size?: 'small' | 'medium' | 'large' | 'auto';
  /*
      Button content label
  */
  label?: string;
  /*
      Button shadow - optional
  */
  shadow?: boolean;
  /*
      Button icon - optional
  */
  icon?: string;
  /*
      Button icon position - optional
  */
  iconposition?: 'left' | 'right';
  /*
      Optional click handler
  */
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  size = 'small',
  shadow = false,
  spin = false,
  disabled = false,
  label,
  ...props
}) => (
  <button
    type="button"
    disabled={!!disabled}
    className={cls('button', `button--${color}`, `button--${size}`, 'button-text', {
      'button--shadow': shadow,
      'button--spin': spin,
    })}
    {...props}
  >
    {props.icon ? (
      <div className={!props.iconposition ? 'left' : props.iconposition}>
        <div
          className="button-icon"
          style={{
            WebkitMaskImage: `url('${Icons[props.icon]}')`,
            mask: `url('${Icons[props.icon]}') center center/100% 100% no-repeat`,
          }}
        ></div>
        {label}
      </div>
    ) : (
      label
    )}
  </button>
);

export interface AsyncButtonProps extends ButtonProps {
  /*
      Optional click handler
  */
  onClick?: () => Promise<void>;
}

export const AsyncLoadingButton: React.FC<AsyncButtonProps> = (props) => {
  const [augmentedProps, setAugmentedProps] = useState<ButtonProps>(props);

  const onClick = (): void => {
    if (!props.onClick) return;

    setAugmentedProps({
      ...augmentedProps,
      icon: 'reload',
      spin: true,
      disabled: true,
    });

    props
      .onClick()
      .catch((e) => {
        // console.error(e);
      })
      .finally(() => {
        setAugmentedProps({
          ...props,
          onClick,
        });
      });
  };

  useEffect(() => {
    setAugmentedProps({
      ...props,
      onClick,
    });
  }, []);

  return <Button {...augmentedProps} />;
};
