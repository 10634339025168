import React, { useState } from 'react';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useSelector } from 'react-redux';
import { emailRegex } from '../../../sdk/constants/regex';

export interface Props {
  onSubmit: (data: any) => void;
  goBack: () => void;
  savedField?: 'string';
}

export const Form: React.FC<Props> = ({ onSubmit, goBack, savedField }): JSX.Element => {
  const config = useSelector(getProjectConfigAll);

  const [value, setValue] = useState(localStorage.getItem('contact_email') || config.contactEmail);

  const handleChange = (value: string): void => {
    setValue(value);
  };

  const handleOnSubmit = (): void => {
    onSubmit(value);
  };
  const BackFunc = (): void => {
    goBack();
  };
  return (
    <>
      <div className="text-field-container">
        <TextField
          icon="email_icon_input"
          label="Contact email"
          onChange={handleChange}
          required={true}
          id="contact_email"
          name="contact_email"
          type="text"
          value={value}
          onValidate={(value): string => (emailRegex.test(value) ? '' : 'Invalid email address')}
        />
      </div>
      <div className="button-container">
        <Button
          color="tertiary"
          iconposition="left"
          icon="arrow_left"
          label="Go back"
          onClick={BackFunc}
          size="large"
        />
        <Button
          data-cy="configuration-contactEmail-next"
          color="primary"
          disabled={value === '' || !emailRegex.test(value)}
          label="Next"
          icon="arrow_right"
          onClick={handleOnSubmit}
          size="large"
          iconposition="right"
        />
      </div>
    </>
  );
};
