import cls from 'classnames';
import React from 'react';
import { ReactComponent as Arrows } from '../../assets/icons/arrows.svg';

const sortable = ['id', 'date', 'type'] as const;
export type TransactionTableSort = typeof sortable[number];
export type TransactionTableSortDirection = 'asc' | 'desc';

interface IProps {
  sort: TransactionTableSort;
  direction: TransactionTableSortDirection;
  onSortClick: (id: TransactionTableSort) => void;
}
const isSortable = (x: any): x is TransactionTableSort => sortable.includes(x);
export const TableHeader: React.FC<IProps> = ({ sort, direction, onSortClick }) => {
  const flip = direction === 'asc';
  const handleSortClick = (id: TransactionTableSort) => (): void => {
    if (isSortable(id)) onSortClick(id);
  };

  return (
    <div className="table-layout table-header">
      {(['id', 'date', 'type', 'amount'] as TransactionTableSort[]).map((name) => (
        <HeaderColumn key={name} name={name} sort={sort} onClick={handleSortClick(name)} flip={flip} />
      ))}
      <div />
      <div className="center">STATUS</div>
    </div>
  );
};

interface IHeaderColumnProps {
  name: string;
  sort: TransactionTableSort;
  onClick: () => void;
  flip: boolean;
}

const HeaderColumn: React.FC<IHeaderColumnProps> = ({ name, sort, onClick, flip }) => {
  return (
    <div className={cls(name !== 'amount' && 'selectable', { selected: sort === name, flip })} onClick={onClick}>
      {name.toUpperCase()}
      {sort === name && <Arrows />}
    </div>
  );
};
