import React from 'react';
import { InfiniteStakingPoolList } from '../../../components/InfiniteStakingPoolList';
import { InfiniteStakingPool, InfiniteStakingState } from '../../../components/InfiniteStakingPool';

export const InfiniteStakingActive: React.FC = () => {
  return (
    <InfiniteStakingPoolList
      title="Active pools"
      subTitle="List of active pools (new pools can take a few minutes to show up here)"
      filter={(pool: InfiniteStakingPool): boolean => pool.state === InfiniteStakingState.ACTIVE}
    />
  );
};
