//Ethereum addresses
const UNISWAP_V2_ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
const UNISWAP_V2_FACTORY_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f';
const ETH_CALCULATOR_ADDRESS_MAINNET = '0xE173c2c09C6882a88Edb0f057d1F344e33A64bd0';
const ETH_CALCULATOR_ADDRESS = '0x62e11c6f15897ad1c9f71bbeb810fda9cd88e238';

// BSC addresses
const PANCAKESWAP_ROUTER_ADDRESS = '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F';
const PANCAKESWAP_FACTORY_ADDRESS = '0xBCfCcbde45cE874adCB698cC183deBcF17952812';
const BSC_CALCULATOR_ADDRESS_MAINNET = '0xE173c2c09C6882a88Edb0f057d1F344e33A64bd0';

export interface NetworkConfiguration {
  ID: number;
  NAME: string;
  BLOCK_TIME: number;
  ROUTER_ADDRESS: string;
  FACTORY_ADDRESS: string;
  PAYMENT_ADDRESS: string;
  PAYMENT_USDT_ADDRESS: string;
  PAYMENT_WRAPPED_USDT_ADDRESS: string;
  CALCULATOR_ADDRESS: string;
  IS_TESTNET: boolean;
  START_BLOCK: number;
  COINGECKO_CHAIN_ID: string;
}

interface NetworkConfigurations {
  [key: string]: NetworkConfiguration;
}

export const stakingContractFundrs: { [key: string]: string } = {
  '43113': '0x8C7A00c25a7389522AD4c7b15B067D29eAEaC964',
  '137': '0xD5750209c816e498B36d360cD5e67184cAf79eAa',
};

export const networkConfigurations: NetworkConfigurations = {
  '1': {
    // eth mainnet
    ID: 1,
    NAME: 'main',
    BLOCK_TIME: 13,
    ROUTER_ADDRESS: UNISWAP_V2_ROUTER_ADDRESS,
    FACTORY_ADDRESS: UNISWAP_V2_FACTORY_ADDRESS,
    PAYMENT_ADDRESS: '0x3bc007c954751790Ca374ed58109e6E817F12D02',
    PAYMENT_USDT_ADDRESS: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    CALCULATOR_ADDRESS: ETH_CALCULATOR_ADDRESS_MAINNET,
    IS_TESTNET: false,
    START_BLOCK: 11560611,
    COINGECKO_CHAIN_ID: 'ethereum',
  },
  '11155111': {
    // eth sepolia
    ID: 11155111,
    NAME: 'sepolia',
    BLOCK_TIME: 15,
    ROUTER_ADDRESS: '0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008',
    FACTORY_ADDRESS: '0x7E0987E5b3a30e3f2828572Bb659A548460a3003',
    PAYMENT_ADDRESS: '0x4Dd7d4aB32B5E4ebaf4F3d032e4E3885c6E8571D',
    PAYMENT_USDT_ADDRESS: '0x5327b46F9F68B1Bd737dbFFeE6bd7d6865b7F15c',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x280E2A53E7c52064BB9AC7143E3B69519f048fb9',
    CALCULATOR_ADDRESS: ETH_CALCULATOR_ADDRESS,
    IS_TESTNET: true,
    START_BLOCK: 5006878,
    COINGECKO_CHAIN_ID: 'ethereum',
  },
  '56': {
    // bsc
    ID: 56,
    NAME: 'bsc',
    BLOCK_TIME: 3,
    ROUTER_ADDRESS: PANCAKESWAP_ROUTER_ADDRESS,
    FACTORY_ADDRESS: PANCAKESWAP_FACTORY_ADDRESS,
    PAYMENT_ADDRESS: '0xED687F293c5bb9367282a586533491E673CaF943',
    PAYMENT_USDT_ADDRESS: '0x55d398326f99059ff775485246999027b3197955',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x7CfdF292b18403Ff4C51AB7Aaeca051ef4a8F6cf',
    CALCULATOR_ADDRESS: BSC_CALCULATOR_ADDRESS_MAINNET,
    IS_TESTNET: false,
    START_BLOCK: 7469909,
    COINGECKO_CHAIN_ID: 'binance-smart-chain',
  },
  '97': {
    // bsc testnet
    ID: 97,
    NAME: 'bsc testnet',
    BLOCK_TIME: 3,
    ROUTER_ADDRESS: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
    FACTORY_ADDRESS: PANCAKESWAP_FACTORY_ADDRESS,
    PAYMENT_ADDRESS: '0x4Dd7d4aB32B5E4ebaf4F3d032e4E3885c6E8571D',
    PAYMENT_USDT_ADDRESS: '0x30FE67f91faBC7D50549af3290b590aef6Bd870c',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x17E29Fb5743cf7B519dB83040a3C3100933810d5',
    CALCULATOR_ADDRESS: '0x655234DDF4e095f03AA5be6CBa5D40a2995fF99b',
    IS_TESTNET: true,
    START_BLOCK: 8904208,
    COINGECKO_CHAIN_ID: 'binance-smart-chain',
  },
  '137': {
    //polygon mainnet
    ID: 137,
    NAME: 'polygon',
    BLOCK_TIME: 2,
    ROUTER_ADDRESS: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
    FACTORY_ADDRESS: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
    PAYMENT_ADDRESS: '0xc6e66549FF027B2AAF55F66a70b1B8d7cE9828eE',
    PAYMENT_USDT_ADDRESS: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x35E9F3005656C9564D6C98b45eb046FDeA1C0199',
    CALCULATOR_ADDRESS: '0xc4fA9b789a0E4100e6b34ab331BA96bcCFC613ae',
    IS_TESTNET: false,
    START_BLOCK: 17104857,
    COINGECKO_CHAIN_ID: 'polygon-pos',
  },
  '43114': {
    //avalanche mainnet
    ID: 43114,
    NAME: 'avalanche',
    BLOCK_TIME: 2,
    ROUTER_ADDRESS: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
    FACTORY_ADDRESS: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
    PAYMENT_ADDRESS: '0x52326CF6166Ec8fF89BD671B46B847745046115a',
    PAYMENT_USDT_ADDRESS: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x315aD5DF2b70A45310df333C8Be7c095de2EA774',
    //## TODO: GET CALCULATOR ADDRESS AFTER DEPLOYMENT
    CALCULATOR_ADDRESS: '0x8fb4C0f738af28797EE06C86836Ad8b237677251',
    IS_TESTNET: false,
    START_BLOCK: 2730390,
    COINGECKO_CHAIN_ID: 'avalanche',
  },
  '43113': {
    ID: 43113,
    NAME: 'avalanche testnet',
    BLOCK_TIME: 2,
    ROUTER_ADDRESS: '0x688d21b0B8Dc35971AF58cFF1F7Bf65639937860',
    FACTORY_ADDRESS: '0x2a496ec9e9be22e66c61d4eb9d316beaee31a77b',
    PAYMENT_ADDRESS: '0xFc4101776F0bE59b7b8A2c57aC84835f2a674150',
    PAYMENT_USDT_ADDRESS: '0x12A54c8b4FF9a480088c387Bb4496A963323b672',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x511BFC36cE54f82ab4DeF1c2804506f62A3A70a5',
    CALCULATOR_ADDRESS: '0x5A6a7d9fb176ca2B911E7862319f857B2F8CF03b',
    IS_TESTNET: true,
    START_BLOCK: 600000,
    COINGECKO_CHAIN_ID: 'avalanche',
  },
  '246': {
    //EWC mainnet
    ID: 246,
    NAME: 'EWC mainnet',
    BLOCK_TIME: 5,
    ROUTER_ADDRESS: '0x6751c00E8A0E25c39175168DE4D34C8c9713cA30',
    FACTORY_ADDRESS: '0x4072c974327722859798ac1722Cf6Ca92431d343',
    // PAYMENT_ADDRESS: '0x780503c3dB2A6C6447Db8D3CaD799bF7CE9b06E7',
    // PAYMENT_USDT_ADDRESS: '',
    // PAYMENT_WRAPPED_USDT_ADDRESS: '0x86109279df8270a16f3b41602e92d2453f3f6bac',
    PAYMENT_ADDRESS: '0x62592A293f81b94756d9acCcc7e8Eff4BD097d82',
    PAYMENT_USDT_ADDRESS: '0xA1B684a732cb4B0156835005D1948e4EEA4defD9',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0xAe2Af531463f81fB72dc983ca3D31D9ce3d466Cf',
    CALCULATOR_ADDRESS: '',
    IS_TESTNET: false,
    START_BLOCK: 16310199,
    COINGECKO_CHAIN_ID: '',
  },
  '1284': {
    //Moonbeam mainnet
    ID: 1284,
    NAME: 'Moonbeam mainnet',
    BLOCK_TIME: 12.1,
    ROUTER_ADDRESS: '0xd3B02Ff30c218c7f7756BA14bcA075Bf7C2C951e',
    FACTORY_ADDRESS: '0x19b85ae92947e0725d5265ffb3389e7e4f191fda',
    PAYMENT_ADDRESS: '0x27C265bDD3ABd2D9Ab818d3c6A5f9A21142fD020',
    PAYMENT_USDT_ADDRESS: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
    PAYMENT_WRAPPED_USDT_ADDRESS: '0x122CDB7aCfDa67Fb2Ae308a9F8819c962622C324',
    CALCULATOR_ADDRESS: '0x7378Aaa0f9C98dF0C557901C4eEB5ED48bE6e7Dc', //#TO DO
    IS_TESTNET: false,
    START_BLOCK: 483192,
    COINGECKO_CHAIN_ID: 'moonbeam',
  },
  // '19': {
  //   ID: 19,
  //   NAME: 'SongBird mainnet',
  //   BLOCK_TIME: 1.9,
  //   ROUTER_ADDRESS: '0x6591cf4E1CfDDEcB4Aa5946c033596635Ba6FB0F',
  //   FACTORY_ADDRESS: '0xB66E62b25c42D55655a82F8ebf699f2266f329FB',
  //   PAYMENT_ADDRESS: '0x73014aaAa4E0c7C2DfFaF5803F9f3aAc0a35C39A',
  //   PAYMENT_USDT_ADDRESS: '0x6eB34761e627285994C6bE0E0d4Ea20F7132A736',
  //   PAYMENT_WRAPPED_USDT_ADDRESS: '0x5f7fCE989543Da18A33E8F949196DDf44e3e5e8c',
  //   CALCULATOR_ADDRESS: '',
  //   IS_TESTNET: false,
  //   START_BLOCK: 23140731,
  //   COINGECKO_CHAIN_ID: 'flare-network',
  // },
};
