import { isAddress } from 'ethers/lib/utils';
import { networkConfigurations } from '../constants/networkConfig';
import { FactoryAb } from '../dex/FactoryAb';
import { FactoryUniswap } from '../dex/FactoryUniswap';
import { getNetworkId } from './network';

export const LPFinderAllowedProtocols = [
  'uniswap',
  'pancakeswap',
  'pangolin',
  'quickswap',
  'solarflare',
  'alliancedex',
  'traderjoe',
  'arrakis',
];

export const getLPContractAddress = async (protocol: string, tokenA: string, tokenB: string): Promise<string> => {
  if (!LPFinderAllowedProtocols.includes(protocol) || !isAddress(tokenA) || !isAddress(tokenB)) {
    return '';
  }

  const network: string = await getNetworkId();
  const factoryAddress: string = networkConfigurations[network].FACTORY_ADDRESS;

  if (protocol === 'alliancedex') {
    const factoryAb = new FactoryAb();
    await factoryAb.load(factoryAddress);

    return await factoryAb.getPair(tokenA, tokenB, '0.75');
  }

  const factoryUniswap = new FactoryUniswap();
  await factoryUniswap.load(factoryAddress);

  return await factoryUniswap.getPair(tokenA, tokenB);
};
