import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from '../../assets/icons';
import { List } from '../../components/List';
import { ListItemUser } from '../../components/List/ListItemUser';
import { Separator } from '../../components/Separator';
import { Button } from '../../components/Button';
import { getTemporaryAdmins } from '../../ducks/admins/selectors';
import { confirmCreateMultisig } from '../../ducks/project/action';

interface IProps {
  onBackClick: () => void;
}

export const MultisigRewiev: React.FC<IProps> = ({ onBackClick }) => {
  const dispatch = useDispatch();

  const newSigners = useSelector(getTemporaryAdmins);
  const approves = Math.floor((newSigners?.length || 1) / 2 + 1);

  const [processing, setProcessing] = useState(false);
  const handleSubmitClick = (): void => {
    setProcessing(true);
    dispatch(confirmCreateMultisig());
  };

  return (
    <>
      <div className="multisig-header">
        <h3>Review</h3>
      </div>
      <div>
        51% of Administrators (i.e. 2 out of 2, 3 out of 4 or 4 out of 7) are required for approve a transaction to be
        executed. Please provide their names and appropriate wallet address.
      </div>

      <div className="admins-review-section">
        <h5>Admins</h5>
      </div>

      <div className="list-container scroll-container scroll-style">
        <List background={false}>
          {(newSigners || []).map(({ name, address, email }) => (
            <ListItemUser key={address} name={name} address={address} email={email} compact={false} />
          ))}
        </List>
      </div>

      <div className="admins-review-section">
        <h5>TRANSACTION</h5>
      </div>
      <div className="admins-review-reviewers">
        <Separator
          leftComponent={
            <div>
              {approves} of {newSigners?.length} administrators need to approve
            </div>
          }
          rightComponent={
            <div className="small-avatar-list">
              {(newSigners || []).map(({ address }) => (
                <div key={address} className="admins-small-avatar-container">
                  {/*{signer && <img className="avatar-image" src={signer} />}*/}
                </div>
              ))}
            </div>
          }
        />
      </div>

      <div className="multisig-button-container">
        {processing ? (
          <Button label="Processing" color="process" size="large" icon="spinner" iconposition="left" spin />
        ) : (
          <>
            <Button
              label="Go back"
              size="large"
              icon="arrow_left_black"
              iconposition="left"
              color="tertiary"
              onClick={onBackClick}
            />
            <Button
              label="Submit"
              size="large"
              icon="arrow_right"
              iconposition="right"
              onClick={handleSubmitClick}
              shadow
            />
          </>
        )}
      </div>
    </>
  );
};
