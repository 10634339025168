import React, { useState } from 'react';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useSelector } from 'react-redux';
import { emailRegex } from '../../../sdk/constants/regex';

export interface Props {
  onSubmit: (data: any) => void;
  goBack: () => void;
  savedField?: 'string';
}

export interface ThirdPartyIntegrations {
  googleAnalyticsID: string;
  googleTagManagerID: string;
  freshworksWidgetID: string;
}

export const thirdPartyIntegrationsInitialState: ThirdPartyIntegrations = {
  googleAnalyticsID: '',
  googleTagManagerID: '',
  freshworksWidgetID: '',
};

export const Form: React.FC<Props> = ({ onSubmit, goBack, savedField }): JSX.Element => {
  const config = useSelector(getProjectConfigAll);

  const [value, setValue] = useState<ThirdPartyIntegrations>(
    Object.keys(config.thirdPartyIntegrations).length
      ? (config.thirdPartyIntegrations as ThirdPartyIntegrations)
      : thirdPartyIntegrationsInitialState,
  );
  const handleChange = (v: string, e: any): void => {
    setValue({ ...value, [e.target.name]: v });
  };

  const handleOnSubmit = (): void => {
    onSubmit(value);
  };
  const BackFunc = (): void => {
    goBack();
  };
  return (
    <>
      <div className="text-field-container">
        <TextField
          label="Google Analytics ID"
          onChange={handleChange}
          required={true}
          id="googleAnalyticsID"
          name="googleAnalyticsID"
          type="text"
          maxLength={16}
          value={value.googleAnalyticsID}
        />
        <TextField
          label="Google Tag Manager ID"
          onChange={handleChange}
          required={true}
          id="googleTagManagerID"
          name="googleTagManagerID"
          type="text"
          maxLength={16}
          value={value.googleTagManagerID}
        />
        <TextField
          label="Freshworks Widget ID"
          onChange={handleChange}
          required={true}
          id="freshworksWidgetID"
          name="freshworksWidgetID"
          type="text"
          maxLength={16}
          value={value.freshworksWidgetID}
        />
      </div>
      <div className="button-container">
        <Button
          color="tertiary"
          iconposition="left"
          icon="arrow_left"
          label="Go back"
          onClick={BackFunc}
          size="large"
        />
        <Button
          data-cy="configuration-googleAnalyticsID-next"
          color="primary"
          label="Next"
          icon="arrow_right"
          onClick={handleOnSubmit}
          size="large"
          iconposition="right"
        />
      </div>
    </>
  );
};
