const addZeros = (num: number, zeros: number): string => {
  let s = num.toString();
  while (s.length < zeros) s = '0' + s;
  return s;
};

export function formatDate(value: Date): string {
  return `${addZeros(value.getFullYear(), 4)}-${addZeros(value.getMonth() + 1, 2)}-${addZeros(
    value.getDate(),
    2,
  )}T${addZeros(value.getHours(), 2)}:${addZeros(value.getMinutes(), 2)}:${addZeros(value.getSeconds(), 2)}`;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const dateToString = (date: Date): string =>
  `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
export const timeToString = (date: Date): string =>
  `${addZeros(date.getHours(), 2)}:${addZeros(date.getMinutes(), 2)} ${
    date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
  }`;
