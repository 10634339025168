export const hexToRgb = (hex: string, alpha: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (
    'rgba(' +
    [
      parseInt(result ? result[1] : '', 16),
      parseInt(result ? result[2] : '', 16),
      parseInt(result ? result[3] : '', 16),
      alpha,
    ].join(', ') +
    ')'
  );
};

export const adjustHexColor = (color: string, amount: number): string => {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2),
      )
  );
};

export interface themeColorVariationsContainerItem {
  [key: string]: any;
}

export interface themeColorVariationsContainer {
  [key: string]: themeColorVariationsContainerItem;
}

export const themeColorVariations: themeColorVariationsContainer = {
  '#FA7878': {
    primary: '#FD5D5D',
    hover: '#FD2B2B',
    secondary: '#FEA6A6',
    thirtiary: '#FFEFEF',
    element: '#FF7E7E',
    background: '#FFDFDF',
  },
  '#F32222': {
    primary: '#D91B1B',
    hover: '#D91B1B',
    secondary: '#EA8282',
    thirtiary: '#FBE8E8',
    element: '#F03838',
    background: '#F7D1D1',
  },
  '#FFAB6F': {
    primary: '#FC8129',
    hover: '#F46F10',
    secondary: '#FDBA89',
    thirtiary: '#FFF2EA',
    element: '#FFA362',
    background: '#FEE6D4',
  },
  '#FF7B1B': {
    primary: '#D7600A',
    hover: '#C45606',
    secondary: '#E9A879',
    thirtiary: '#FBEFE7',
    element: '#F6761A',
    background: '#F7DFCE',
  },
  '#F3D772': {
    primary: '#F5BF00',
    hover: '#E4B200',
    secondary: '#F9DC73',
    thirtiary: '#FEF9E6',
    element: '#FEDF70',
    background: '#FDF2CC',
  },
  '#F0BC00': {
    primary: '#CCA000',
    hover: '#BF9600',
    secondary: '#E3CB73',
    thirtiary: '#FAF5E6',
    element: '#F6C723',
    background: '#F5ECCC',
  },

  '#D6F070': {
    primary: '#D3E505',
    hover: '#BBCC00',
    secondary: '#D1E973',
    thirtiary: '#F7FBE6',
    element: '#D0EA68',
    background: '#EEF7CC',
  },
  '#B1DD01': {
    primary: '#86A800',
    hover: '#7B9900',
    secondary: '#BDCF73',
    thirtiary: '#F3F6E6',
    element: '#A0C706',
    background: '#E7EECC',
  },
  '#B0F070': {
    primary: '#7CE612',
    hover: '#71D70A',
    secondary: '#A5EF5A',
    thirtiary: '#F2FDE7',
    element: '#A5EA60',
    background: '#E5FAD0',
  },
  '#7EE01B': {
    primary: '#5FB10C',
    hover: '#54A008',
    secondary: '#A7D47A',
    thirtiary: '#EFF7E7',
    element: '#74D513',
    background: '#DFEFCE',
  },
  '#8BFC89': {
    primary: '#08E604',
    hover: '#03C600',
    secondary: '#77F175',
    thirtiary: '#E6FCE6',
    element: '#59F357',
    background: '#CEFACD',
  },
  '#3EE63B': {
    primary: '#11B60E',
    hover: '#09A107',
    secondary: '#7CD77A',
    thirtiary: '#E7F8E7',
    element: '#2EE82B',
    background: '#CFF0CF',
  },
  '#77FCBC': {
    primary: '#00E677',
    hover: '#01CD6B',
    secondary: '#5FEEA9',
    thirtiary: '#E6FCF1',
    element: '#4CF7A5',
    background: '#CCFAE4',
  },
  '#22EA8A': {
    primary: '#00C868',
    hover: '#00B35D',
    secondary: '#73E1AC',
    thirtiary: '#E6F9F0',
    element: '#1CE886',
    background: '#CCF4E1',
  },

  '#6AFBFB': {
    primary: '#06D2D2',
    hover: '#01BEBE',
    secondary: '#6EE6E6',
    thirtiary: '#E6FBFB',
    element: '#62ECEC',
    background: '#CDF6F6',
  },
  '#0EE5E5': {
    primary: '#00A8A8',
    hover: '#009393',
    secondary: '#73CFCF',
    thirtiary: '#E6F6F6',
    element: '#00C8C8',
    background: '#CCEEEE',
  },
  '#66DAFF': {
    primary: '#33CEFF',
    hover: '#00B9F5',
    secondary: '#71DDFF',
    thirtiary: '#E6F9FF',
    element: '#71DDFF',
    background: '#D6F5FF',
  },
  '#05B3EB': {
    primary: '#008CB9',
    hover: '#00769B',
    secondary: '#73C0D9',
    thirtiary: '#E6F4F8',
    element: '#00ACE4',
    background: '#CCE8F1',
  },
  '#669AFF': {
    primary: '#4685FF',
    hover: '#1C69FF',
    secondary: '#99BCFF',
    thirtiary: '#EDF3FF',
    element: '#6A9DFF',
    background: '#DAE7FF',
  },
  '#5466FD': {
    primary: '#5466FD',
    hover: '#2D48D6',
    secondary: '#BDCCFF',
    thirtiary: '#EEF6FF',
    element: '#6587FF',
    background: '#E5EBFF',
  },

  '#9378FF': {
    primary: '#9479FF',
    hover: '#7C5CFF',
    secondary: '#C6B7FF',
    thirtiary: '#F0EEFF',
    element: '#AD99FF',
    background: '#EAE4FF',
  },
  '#6943FF': {
    primary: '#5024FF',
    hover: '#2C00DD',
    secondary: '#9F87FF',
    thirtiary: '#EEE9FF',
    element: '#6D48FF',
    background: '#DCD3FF',
  },
  '#BA75FF': {
    primary: '#B162FF',
    hover: '#9933FF',
    secondary: '#D4A9FF',
    thirtiary: '#F7EFFF',
    element: '#C284FF',
    background: '#EFE0FF',
  },
  '#932CFB': {
    primary: '#8E1EFF',
    hover: '#7600EC',
    secondary: '#C183FF',
    thirtiary: '#F4E8FF',
    element: '#A448FF',
    background: '#E8D2FF',
  },
  '#EC75FF': {
    primary: '#E753FF',
    hover: '#DE11FF',
    secondary: '#F2A0FF',
    thirtiary: '#FDEEFF',
    element: '#EC79FF',
    background: '#FADDFF',
  },
  '#D829F4': {
    primary: '#D829F4',
    hover: '#C403E3',
    secondary: '#E989F9',
    thirtiary: '#FBEAFE',
    element: '#E85EFE',
    background: '#F7D4FD',
  },
  '#FF5FA2': {
    primary: '#FF5FA2',
    hover: '#FF2681',
    secondary: '#FFA7CC',
    thirtiary: '#FFEFF6',
    element: '#FF80B5',
    background: '#FFDFEC',
  },
  '#F51573': {
    primary: '#F51573',
    hover: '#DB045E',
    secondary: '#FA7EB2',
    thirtiary: '#FEE8F1',
    element: '#FD4693',
    background: '#FDD0E3',
  },
};
