import React from 'react';
import classNames from 'classnames';

export interface ListTitleProps {
  controls?: JSX.Element;
}

export interface ListProps {
  background: boolean;
}

export const ListSpacer: JSX.Element = <div className="list-spacer" />;

export const ListTitle: React.FC<ListTitleProps> = ({ children, controls }) => {
  return (
    <div className="list-title--wrapper">
      <div className="list-title">{children}</div>
      {controls !== undefined && <div className="list-title--controls">{controls}</div>}
    </div>
  );
};

export const List: React.FC<ListProps> = ({ background, children }) => {
  return <div className={classNames('list', { 'list--background': background })}>{children}</div>;
};
