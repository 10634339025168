import { all } from 'redux-saga/effects';
import { ethersSagas } from './ethers/sagas';
import { authSagaWatcher } from './auth/sagas';
import { adminsSagaWatcher } from './admins/sagas';
import { transactionsSagaWatcher } from './transactions/sagas';
import { projectSagaWatcher } from './project/sagas';

export function* rootSaga(): Generator {
  yield all([ethersSagas(), authSagaWatcher(), adminsSagaWatcher(), transactionsSagaWatcher(), projectSagaWatcher()]);
}
