import React, { useState, useRef } from 'react';

import { Icons } from '../../assets/icons';

export const Popover: React.FC<{ content: string | JSX.Element; title?: string }> = (props) => {
  const [isShown, setIsShown] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const anchorRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const show = (): void => {
    setIsShown(true);

    if (anchorRef.current && popoverRef.current) {
      const anchorRect = anchorRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();

      setPosition({
        x: (anchorRect.left + anchorRect.right) / 2 - popoverRect.width / 2,
        y: anchorRect.top - popoverRect.height - 9,
      });
    }
  };

  const hide = (): void => {
    setIsShown(false);
  };

  return (
    <React.Fragment>
      <div onPointerEnter={show} onPointerLeave={hide}>
        <div ref={anchorRef}>{props.children}</div>

        <div
          ref={popoverRef}
          className="popover"
          style={{
            visibility: isShown ? 'visible' : 'hidden',
            left: position.x,
            top: position.y,
          }}
        >
          {props.title && <h4>{props.title}</h4>}
          {props.content}

          <div className="popover-arrow"></div>
        </div>
      </div>
    </React.Fragment>
  );
};
