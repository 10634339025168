import { BigNumberish, utils } from 'ethers';

export function formatTokens(value: BigNumberish, decimals = 18): string {
  return formatNumber(parseFloat(utils.formatUnits(value, decimals)));
}

function fixed(n: number, decimals: number, maxDecimals: number): string {
  if (n % 1 === 0) return n.toString();
  const s = n.toFixed(Math.min(decimals, maxDecimals));
  // what kind of check? it rounds 0.10 to 0
  //if (s.endsWith('0')) return Math.round(n).toString();
  return s;
}

export function formatNumber(n?: number, maxDecimals = 3): string {
  if (!n) return '0';

  if (n < 0.001) return '< 0.001';

  if (n < 0.01) return `${fixed(n, 3, maxDecimals)}`;
  if (n < 10) return `${fixed(n, 2, maxDecimals)}`;
  if (n < 1000) return `${fixed(n, 1, maxDecimals)}`;
  if (n < 1000000) return `${fixed(n / 1000, 1, maxDecimals)}K`;
  if (n < 1000000000) return `${fixed(n / 1000000, 1, maxDecimals)}M`;
  if (n < 1000000000000) return `${fixed(n / 1000000000, 1, maxDecimals)}B`;
  if (n < 1000000000000000) return `${fixed(n / 1000000000000, 1, maxDecimals)}T`;

  return '∞';
}

export const upToDecimals = (value: number, decimals: number): string => {
  return (Math.round(value * 100) / 100).toFixed(decimals);
};

export const formatTokenInput = (value: string): string => {
  if (value?.match(/^(0[1-9]+)/) || value?.match(/^0{2,}[1-9]+/)) {
    // to remove any leading zero after a number
    return value.replace(/^[0.]+/, '');
  } else if (value?.match(/^0{2,}[\.,]/)) {
    // to remove leading zeroes when has decimal point
    return value.replace(/^0+/, '0');
  } else if (value?.match(/^0{2}/)) {
    // to remove zeroes in the beginning
    return value.replace(/^[0.]+/, '0');
  }
  // if any check is met, returns the value as is
  return value;
};
