import React from 'react';
import { InfiniteStakingPoolList } from '../../../components/InfiniteStakingPoolList';
import { InfiniteStakingState } from '../../../components/InfiniteStakingPool';

export const InfiniteStakingScheduled: React.FC = () => {
  return (
    <InfiniteStakingPoolList
      title="Scheduled pools"
      subTitle="List of scheduled pools (new pools can take a few minutes to show up here)"
      filter={(pool: any): boolean => pool.state === InfiniteStakingState.SCHEDULED}
    />
  );
};
