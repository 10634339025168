import React, { useEffect, useState } from 'react';
import { Icons } from '../../assets/icons';
import { BigNumber, providers, utils } from 'ethers';
import { Button } from '../Button';
import { createDuration, Duration, durationToUnit, DurationUnits } from '../../sdk/helpers/duration';
import { PoolExpiration } from '../PoolExpiration';
import { PoolExtend } from '../PoolExtend';
import { PoolAsset } from '../PoolAsset';
import { PoolCampaign } from '../PoolCampaign';
import { PoolLockScheme } from '../PoolLockScheme';
import { formatNumber, formatTokens } from '../../utils/formatNumber';
import { PoolCampaignMessage } from '../PoolCampaignMessage';
import { RewardsPoolBase } from '../../sdk/staking-v2/RewardsPoolBase';
import { LiquidityMiningCampaign } from '../../sdk/staking-v2/LiquidityMiningCampaign';
import { useHistory } from 'react-router-dom';
import { handleError } from '../../utils/handleError';
import { useDispatch, useSelector } from 'react-redux';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { getNetworkName } from '../../sdk/helpers/network';
import { refundCredit, refundCreditExtension } from '../../sdk/payment';
import {
  PoolVersions,
  getProtocolOptions,
  getNetworkAndProtocol,
  getExplorerAddressByChainId,
  LMReward,
  LockingPeriod,
  PoolVersion,
} from '../../utils/helpers';
import { LiquidityMiningCampaignFactory } from '../../sdk/staking-v1/LiquidityMiningCampaignFactory';
import { getProjectConfig, getProjectConfigAll, getProjectName } from '../../ducks/project/selectors';
import { TokenERC20 } from '../../sdk/staking-v1/TokenERC20';
import { getTierCampaign } from '../../utils/getTierCampaign';
import { getTierValue, tierTenant } from '../CreatePool/getTierOptions';
import { useAccount, useNetwork } from 'wagmi';

export interface LiquidityMiningPool {
  address: string;
  lpAddress: string;
  assets: { symbol: string; balance: BigNumber; excess: BigNumber }[];
  rewards: { symbol: string; excess: BigNumber; address: string; decimals: number }[];
  protocol: string;
  lpTokenAmount: BigNumber;
  start: Date;
  end: Date;
  done: boolean;
  lockingPeriods?: LockingPeriod[];
  savedLockingPeriods?: LockingPeriod[];
  campaignRewards: LMReward[];
  version: PoolVersion;
  name: string;
  campaignMessage: string;
  totalLimit: BigNumber;
  walletLimit: BigNumber;
  extensionDuration?: Duration;
  extensionRewards?: LMReward[];
}

export const LiquidityMiningPool: React.FC<{
  pool: LiquidityMiningPool;
}> = ({ pool }) => {
  const { address: wallet } = useAccount();
  const { chain } = useNetwork();

  const history = useHistory();
  const dispatch = useDispatch();

  const web3 = useSelector(getEthersWeb3Provider);
  const configAll = useSelector(getProjectConfigAll);
  const config = useSelector(getProjectConfig);

  const [extendPopup, setExtendPopup] = useState(false);
  const [lockPopup, setLockPopup] = useState(false);
  const [chainId, setChainId] = useState<string>('');
  const [network, setNetwork] = useState('');
  const [tierCampaign, setTierCampaign] = useState<number>(5);
  const [protocolDetails, setProtocolDetails] = useState<any>('');
  const projectName = useSelector(getProjectName);
  const isTierCampaign = projectName === tierTenant ? true : false;

  useEffect(() => {
    if (chain?.id) {
      const [network, protocol] = getNetworkAndProtocol(String(chain.id));
      setProtocolDetails(getProtocolOptions(network));
      setNetwork(network);
      setChainId(String(chain.id));
    }
    return (): void => {
      setNetwork('');
      setChainId('');
    };
  }, [chain?.id]);

  useEffect(() => {
    getNetworkName().then((network) => {
      setNetwork(network);
    });

    if (isTierCampaign) getTierCampaign(pool.address, web3.provider).then((tier) => setTierCampaign(tier));
  }, []);

  async function cancelStart(): Promise<void> {
    if (pool.version === PoolVersions.v2) {
      const rewardsPool = new RewardsPoolBase();
      await rewardsPool.load(pool.address);

      await handleError(
        rewardsPool.cancel(),
        dispatch,
        'Cancel start',
        'The action is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      );
    } else {
      await handleError(
        refundCredit(pool.address),
        dispatch,
        'Cancel start',
        'The action is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      );
    }
  }

  async function cancelExtension(): Promise<void> {
    if (pool.version === PoolVersions.v2) {
      const rewardsPool = new LiquidityMiningCampaign();
      await rewardsPool.load(pool.address);

      await handleError(
        rewardsPool.cancelExtension(),
        dispatch,
        'Cancel extension',
        'The action is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      );
    } else {
      await handleError(
        refundCreditExtension(pool.address),
        dispatch,
        'Cancel extension',
        'The action is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      );
    }
  }

  async function withdrawExcessRewards(): Promise<void> {
    const rewardsPool = new LiquidityMiningCampaign();
    await rewardsPool.load(pool.address);

    await handleError(
      rewardsPool.withdrawExcessRewards(wallet),
      dispatch,
      'Withdraw of excess rewards',
      'The action is now awaiting multisig confirmation',
      () =>
        setTimeout(() => {
          history.push('/multisig/transactions');
        }, 1000),
    );
  }

  async function withdrawLeftovers(): Promise<void> {
    if (!config.factory) return;

    const factory = new LiquidityMiningCampaignFactory();
    await factory.load(config.factory[network]);

    for (const reward of pool.campaignRewards) {
      await handleError(
        factory.withdrawRewardsLeftovers(reward.address),
        dispatch,
        'Withdraw of leftovers',
        'The action is now awaiting multisig confirmation',
      );
    }
  }

  async function transferTokens(): Promise<void> {
    if (!wallet) return;
    if (!config.factory) return;

    for (const reward of pool.campaignRewards) {
      const tokenReward = new TokenERC20();
      await tokenReward.load(reward.address);
      const multisig = (configAll.multisig as any)[network];

      if (!multisig) return;

      await handleError(
        tokenReward.transferTokens(wallet, multisig),
        dispatch,
        'Transfer tokens',
        'The action is now awaiting multisig confirmation',
      );
    }
  }

  async function schedule(): Promise<void> {
    const state = {
      step: 1,
      version: pool.version,
      name: pool.name,
      campaignMessage: pool.campaignMessage,
      protocol: pool.protocol,
      stakingAddress: pool.lpAddress,
      userStakingLimit: { amount: pool.walletLimit.div(BigNumber.from(10).pow(18)), decimals: 18 },
      stakingLimit: { amount: pool.totalLimit.div(BigNumber.from(10).pow(18)), decimals: 18 },
      rewards: pool.campaignRewards.map((reward) => ({
        readOnly: true,
        info: config.tokens?.filter(({ address }) => address === reward.address)[0] || {
          network: '',
          name: reward.symbol,
          symbol: reward.symbol,
          address: reward.address,
          decimals: reward.decimals,
          coinGeckoID: '',
          projectToken: true,
        },
        reward: { amount: reward.totalRewards, decimals: reward.decimals },
      })),
      throttleRoundDuration: createDuration(1, DurationUnits.seconds),
      throttleRoundCap: { amount: BigNumber.from(1), decimals: 18 },
      poolAddress: pool.address,
    };

    history.push(`/pools/new`, state);
  }

  return (
    <div className="liquidity-mining-pool">
      <div className="pool-header flex flex-center">
        <div className="pool-header-content flex">
          <div className="pool-header-assets flex">
            {pool.assets.map((asset: any, assetIndex: number) => (
              <React.Fragment key={assetIndex}>
                {assetIndex > 0 && <img className="pool-asset-exchange-separator" src={Icons.arrow_separator} />}

                <div className="pool-asset-exchange flex">
                  <img
                    className="pool-asset-coin-icon"
                    src={
                      `${process.env.REACT_APP_ASSETS}/${asset.address.toLocaleLowerCase()}`
                        ? `${process.env.REACT_APP_ASSETS}/${asset.address.toLocaleLowerCase()}`
                        : Icons.unknown_coin
                    }
                  />
                  {asset.symbol.toUpperCase()}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="pool-protocol flex">
            <img height={30} src={Icons[pool.protocol]} />
            {protocolDetails
              ? protocolDetails.filter((item: any) => item.value === pool.protocol)[0].name
              : pool.protocol}
          </div>
        </div>
      </div>

      <div>
        <div className="pool-content">
          <div className="pool-assets">
            {pool.version !== '1.0' && <div className="pool-name">{pool.name}</div>}
            {pool.version !== '1.0' && <PoolCampaignMessage pool={pool} />}
            {pool.start.getTime() !== 0 ? (
              <div className="pool-assets-info">
                <div className="pool-assets-staking">
                  <div className="pool-assets-staking-text flex pool-content-header">Staking balance</div>
                  {pool.assets.map((asset: any, assetIndex: number) => (
                    <PoolAsset key={assetIndex} asset={asset} />
                  ))}

                  {isTierCampaign && (
                    <>
                      <div className="pool-assets-staking" style={{ marginTop: 28 }}>
                        <div className="pool-assets-staking-text flex pool-content-header">Tier Campaign</div>
                        <div className="text-bold">{(getTierValue(tierCampaign) as string).toUpperCase()}</div>
                      </div>
                    </>
                  )}
                </div>

                <div className="spacer"></div>

                <div className="pool-assets-staking">
                  <div>
                    {pool.campaignRewards.map((reward: LMReward, rewardIndex: number) => {
                      return (
                        <React.Fragment key={rewardIndex}>
                          <div className="flex pool-content-header">Total Reward</div>

                          <div className="pool-asset-amount">
                            <div className="pool-asset-amount-number flex">
                              <div className="pool-asset-amount-number">
                                {formatTokens(reward.totalRewards, reward.decimals)}
                              </div>
                              <img
                                className="pool-asset-coin-icon"
                                src={
                                  `${process.env.REACT_APP_ASSETS}/${reward.address.toLocaleLowerCase()}`
                                    ? `${process.env.REACT_APP_ASSETS}/${reward.address.toLocaleLowerCase()}`
                                    : 'unknown_coin'
                                }
                              />
                              <div className="pool-asset-amount-symbol">{reward.symbol.toUpperCase()}</div>
                            </div>

                            <div className="flex pool-content-header" style={{ marginTop: 24 }}>
                              Weekly Reward
                            </div>

                            <div style={{ marginTop: 8 }} className="pool-asset-amount-number flex">
                              <div className="pool-asset-amount-number">
                                {formatNumber(Number(utils.formatUnits(reward.weeklyRewards, reward.decimals)), 2)}
                              </div>
                              <img
                                className="pool-asset-coin-icon"
                                src={
                                  `${process.env.REACT_APP_ASSETS}/${reward.address.toLocaleLowerCase()}`
                                    ? `${process.env.REACT_APP_ASSETS}/${reward.address.toLocaleLowerCase()}`
                                    : 'unknown_coin'
                                }
                              />
                              <div className="pool-asset-amount-symbol">{reward.symbol.toUpperCase()}</div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>

                {[PoolVersions.v2, PoolVersions.v3, PoolVersions.v4].includes(pool.version) &&
                  pool.rewards.some((reward) => Number(reward.excess) / 10 ** reward.decimals >= 1) && (
                    <React.Fragment>
                      <div className="spacer"></div>

                      <div className="pool-assets-treasuries">
                        <div className="pool-assets-treasuries-text flex pool-content-header">Excess rewards</div>
                        {pool.rewards.map((asset: any, assetIndex: number) => (
                          <PoolAsset
                            key={assetIndex}
                            asset={{
                              address: asset.address,
                              balance: asset.excess,
                              symbol: asset.symbol,
                              decimals: asset.decimals,
                            }}
                          />
                        ))}
                        <Button
                          color="primary-empty"
                          size="small"
                          onClick={(): void => {
                            withdrawExcessRewards();
                          }}
                          label="Withdraw excess rewards"
                          icon="left_arrow"
                          iconposition="left"
                        />
                      </div>
                    </React.Fragment>
                  )}

                {pool.start.getTime() !== 0 && (
                  <>
                    <div className="spacer"></div>

                    <div>
                      <PoolExpiration end={pool.end} start={pool.start} />

                      {pool.extensionDuration && (
                        <div className="pool-extension">
                          <div className="pool-content-header flex pool-extension-text">Scheduled extension</div>
                          <div className="pool-extension-duration flex">
                            {formatNumber(durationToUnit(pool.extensionDuration, DurationUnits.days))} days
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : null}

            {pool.version === PoolVersions.v1 && !pool.done && (
              <div className="pool-assets-buttons flex">
                <Button
                  color="primary-empty"
                  size="medium"
                  onClick={(): void => {
                    setLockPopup(true);
                  }}
                  label="Locking"
                  icon="calendar"
                  iconposition="left"
                />
              </div>
            )}

            <div className="pool-version">V{pool.version == '3.0' ? '2.0' : pool.version}</div>
            <div className="pool-address">
              {pool.address}{' '}
              <a
                href={`${getExplorerAddressByChainId(chainId)}${pool.address}`}
                className="hyperlink-pool-address"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Icons.open_link_light} />
              </a>
            </div>
          </div>

          {pool.start.getTime() !== 0 ? (
            <div className="pool-lp flex">
              <div className="pool-lp-assets">
                <div className="pool-lp-assets-text flex pool-content-header">Provided to</div>

                <div className="pool-lp-assets-list flex">
                  {pool.assets.map((asset: any, assetIndex: number) => (
                    <React.Fragment key={assetIndex}>
                      {assetIndex > 0 && <img className="pool-asset-exchange-separator" src={Icons.arrow_separator} />}
                      <div className="pool-asset-exchange flex">
                        <img
                          className="pool-asset-coin-icon"
                          src={
                            `${process.env.REACT_APP_ASSETS}/${asset.address.toLocaleLowerCase()}`
                              ? `${process.env.REACT_APP_ASSETS}/${asset.address.toLocaleLowerCase()}`
                              : 'unknown_coin'
                          }
                        />
                        {asset.symbol.toUpperCase()}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div className="pool-lp-separator spacer"></div>

              <div className="pool-lp-tokens">
                <div className="pool-lp-tokens-text flex pool-content-header">LPT</div>

                <PoolAsset
                  asset={{
                    balance: pool.lpTokenAmount,
                    symbol: 'LPT',
                    image: Icons.lp_token,
                    address: pool.lpAddress,
                  }}
                />
              </div>
            </div>
          ) : null}

          <PoolCampaign start={pool.start} end={pool.end}>
            {[PoolVersions.v2, PoolVersions.v3, PoolVersions.v4].includes(pool.version) && pool.start > new Date() && (
              <Button
                color="primary-empty"
                size="medium"
                onClick={(): void => {
                  cancelStart();
                }}
                label="Cancel start"
                icon="close"
                iconposition="left"
              />
            )}

            {pool.start.getTime() !== 0 && pool.start < new Date() && !pool.extensionDuration && (
              <Button
                color="primary-empty"
                size="medium"
                onClick={(): void => {
                  setExtendPopup(true);
                }}
                label="Extend campaign"
                icon="calendar"
                iconposition="left"
              />
            )}

            {pool.version === PoolVersions.v1 && pool.end <= new Date() && (
              <Button
                color="primary-empty"
                size="medium"
                onClick={(): void => {
                  withdrawLeftovers();
                }}
                label="Withdraw Leftovers"
                icon="calendar"
                iconposition="left"
              />
            )}

            {pool.version === PoolVersions.v1 && pool.end <= new Date() && (
              <Button
                color="primary-empty"
                size="medium"
                onClick={(): void => {
                  transferTokens();
                }}
                label="Transfer Tokens"
                icon="calendar"
                iconposition="left"
              />
            )}

            {[PoolVersions.v2, PoolVersions.v3, PoolVersions.v4].includes(pool.version) && pool.start.getTime() === 0 && (
              <>
                <Button
                  color="primary-empty"
                  size="medium"
                  onClick={(): void => {
                    withdrawExcessRewards();
                  }}
                  label="Withdraw Excess Rewards"
                  icon="left_arrow"
                  iconposition="left"
                />
                <div className="spacer" />
                <Button
                  color="primary-empty"
                  size="medium"
                  onClick={(): void => {
                    schedule();
                  }}
                  label="Schedule start"
                  icon="calendar"
                  iconposition="left"
                />
              </>
            )}

            {[PoolVersions.v2, PoolVersions.v3, PoolVersions.v4].includes(pool.version) &&
              pool.start < new Date() &&
              pool.extensionDuration && (
                <Button
                  color="primary-empty"
                  size="medium"
                  onClick={(): void => {
                    cancelExtension();
                  }}
                  label="Cancel extension"
                  icon="close"
                  iconposition="left"
                />
              )}
          </PoolCampaign>
        </div>

        {extendPopup && <PoolExtend pool={pool} onClose={(): void => setExtendPopup(false)} />}
        {lockPopup && <PoolLockScheme pool={pool} onClose={(): void => setLockPopup(false)} />}
      </div>
    </div>
  );
};
