import React from 'react';
import { dateToString, timeToString } from '../../utils/formatDate';

export const PoolCampaign: React.FC<{ start: Date; end: Date }> = ({ start, end, children }) => {
  return (
    <div className="pool-campaign flex">
      {start.getTime() !== 0 ? (
        <>
          <div className="pool-campaign-start">
            <div className="pool-campaign-start-text pool-content-header">START</div>
            <div className="pool-campaign-date">{dateToString(start)}</div>
            <div className="pool-campaign-time" data-cy="campaign-start-time">
              {timeToString(start)}
            </div>
          </div>
          <div className="pool-campaign-separator spacer"></div>
          <div className="pool-campaign-end">
            <div className="pool-campaign-end-text pool-content-header">END</div>
            <div className="pool-campaign-date">{dateToString(end)}</div>
            <div className="pool-campaign-time">{timeToString(end)}</div>
          </div>
        </>
      ) : (
        <div className="pool-content-header spacer">NO START SCHEDULED</div>
      )}
      {children}
    </div>
  );
};
