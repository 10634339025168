import React, { useState } from 'react';
import { TransactionsTable } from '../../components/TransactionsTable';
import { TransactionTableSort, TransactionTableSortDirection } from '../../components/TransactionsTable/TableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionsRows } from '../../ducks/transactions/selectors';
import { confirmTransaction } from '../../ducks/transactions/actions';

export const Transactions: React.FC = () => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState<TransactionTableSort>('id');
  const [sortDirection, setSortDirection] = useState<TransactionTableSortDirection>('desc');
  const handleSortClick = (id: TransactionTableSort): void => {
    if (sort === id) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSort(id);
      setSortDirection('desc');
    }
  };

  // TODO: add selector to get rows data
  const rows = useSelector(getTransactionsRows);

  const handleApproveClick = (id: string): void => {
    dispatch(confirmTransaction(Number(id)));
  };

  return (
    <>
      <div className="transactions-title">
        <h3>Transactions</h3>
      </div>
      <TransactionsTable
        rows={rows}
        onApproveClick={handleApproveClick}
        sort={sort}
        direction={sortDirection}
        onSortClick={handleSortClick}
      />
    </>
  );
};
