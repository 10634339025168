import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from './FormInput';
import { StakingTitle } from '../../../components/StakingTitle';
import { hexToRgb, themeColorVariations } from '../../../utils/colorManipulations';

export const Colors: React.FC = () => {
  const history = useHistory();
  const state: any = history.location.state;

  useEffect(() => {
    if (!state) history.push('/configuration/brand');
  }, [state]);

  const onSubmit = (data: any): void => {
    const newData = { ...state, colors: data };
    // TODO: move this logic to sagas
    localStorage.setItem('brand_color', data);
    localStorage.setItem('brand_primary', themeColorVariations[data]['primary']);
    localStorage.setItem('brand_hover', themeColorVariations[data]['hover']);
    localStorage.setItem('brand_secondary', themeColorVariations[data]['secondary']);
    localStorage.setItem('brand_thirtiary', themeColorVariations[data]['thirtiary']);
    localStorage.setItem('brand_element', themeColorVariations[data]['element']);
    localStorage.setItem('brand_background', themeColorVariations[data]['background']);
    localStorage.setItem('brand_color_shadow', hexToRgb(data, 0.57));
    history.push('/configuration/tokens', newData);
  };
  const goBack = (): void => {
    history.push('/configuration/theme', { ...state });
  };

  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Colors"
          message="Choose your Brand Primary color."
          steps={8}
          currentStep={3}
        />
        <Form onSubmit={onSubmit} goBack={goBack} savedField={state?.colors} />
      </div>
    </>
  );
};
