import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from './FormInput';
import { StakingTitle } from '../../../components/StakingTitle';

export const Theme: React.FC = () => {
  const history = useHistory();
  const state: any = history.location.state;

  useEffect(() => {
    if (!state) history.push('/configuration/brand');
  }, [state]);

  const onSubmit = (data: any): void => {
    const newData = { ...state, theme: data };
    // TODO: move this logic to sagas
    localStorage.setItem('theme', data);
    history.push('/configuration/colors', newData);
  };
  const goBack = (): void => {
    history.push('/configuration/brand', { ...state });
  };
  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Theme"
          message="Choose your platform default theme. Please note that users may change a theme according to their preference."
          steps={9}
          currentStep={2}
        />
        <Form onSubmit={onSubmit} goBack={goBack} savedField={state?.theme} />
      </div>
    </>
  );
};
