import React from 'react';
import { Icons } from '../../../assets/icons/index';

export interface MetricsDataTableItemProps {
  userWallet: string;
  protocol: string;
  pool: any;
  startsDate: string;
  startsTime: string;
  endsDate: string;
  endsTime: string;
  lptStakedCurrency: string;
  lptStakedAmount: string;
  lptStakedOnCurrency: string;
  lptStakedOnAmount: string;
}

/**
 * Primary UI component for user interaction
 */
export const MetricsDataTableItem: React.FC<MetricsDataTableItemProps> = ({
  userWallet,
  protocol,
  pool,
  startsDate,
  startsTime,
  endsDate,
  endsTime,
  lptStakedCurrency,
  lptStakedAmount,
  lptStakedOnCurrency,
  lptStakedOnAmount,
}) => {
  return (
    <div className={'metrics-data-table-item'}>
      <span>{userWallet}</span>
      <span>{protocol}</span>
      <span className="wide">{pool}</span>
      <span>
        <strong>{startsDate}</strong> {startsTime}
        <br />
        <strong>{endsDate}</strong> {endsTime}
      </span>
      <span className="text-right">
        {lptStakedCurrency} <strong>{lptStakedAmount}</strong>
        <br />
        {lptStakedOnCurrency} <strong>{lptStakedOnAmount}</strong>
        <img className="actionCaret" src={Icons[`caret_right`]} alt={''} />
      </span>
    </div>
  );
};
