import { projectSlice } from './slice';
import { createAction } from '@reduxjs/toolkit';
import { BackendAdmin } from '../admins/slice';

export const { setProject, setMultisig, setConfig, setConfigLM } = projectSlice.actions;

export const processCreateMultisig = createAction<BackendAdmin[]>('project/process-create-multisig');

export const confirmCreateMultisig = createAction('admins/confirm-create-multisig');

export const initializedProject = createAction('admins/initialized_project');

export const fetchProject = createAction('admins/update_project', (projectId?: string, network?: string) => ({
  payload: { projectId, network },
}));
