import React, { useState, useEffect } from 'react';
import { ColorSelector } from '../../../components/ColorSelector';
import { Button } from '../../../components/Button';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useSelector } from 'react-redux';
import { hexToRgb, themeColorVariations } from '../../../utils/colorManipulations';

export interface Props {
  onSubmit: (data: any) => void;
  goBack: () => void;
  savedField: string;
}

export const Form: React.FC<Props> = ({ onSubmit, goBack, savedField }): JSX.Element => {
  const config = useSelector(getProjectConfigAll);
  // TODO: get theme state from redux
  const [value, setValue] = useState(localStorage.getItem('brand_color') || config.brandColor || '#5466FD');

  useEffect(() => {
    return (): void => {
      if (localStorage.getItem('brand_color')) {
        document.body.style.setProperty('--brand-primary', localStorage.getItem('brand_primary'));
        document.body.style.setProperty('--brand-hover', localStorage.getItem('brand_hover'));
        document.body.style.setProperty('--brand-secondary', localStorage.getItem('brand_secondary'));
        document.body.style.setProperty('--brand-thirtiary', localStorage.getItem('brand_thirtiary'));
        document.body.style.setProperty('--brand-element', localStorage.getItem('brand_element'));
        document.body.style.setProperty('--brand-background', localStorage.getItem('brand_background'));
        document.body.style.setProperty('--blue-shadow', '0px 7px 28px ' + localStorage.getItem('brand_color_shadow'));
        document.body.style.setProperty(
          '--blue-tile-shadow',
          '0px 7px 19px ' + localStorage.getItem('brand_color_shadow'),
        );
        document.body.style.setProperty(
          '--blue-tile-hover-shadow',
          '0px 7px 22px ' + localStorage.getItem('brand_color_shadow'),
        );
      }
    };
  }, []);

  const handleChange = (e: any): void => {
    setValue(e);
    document.body.style.setProperty('--brand-primary', themeColorVariations[e]['primary']);
    document.body.style.setProperty('--brand-hover', themeColorVariations[e]['hover']);
    document.body.style.setProperty('--brand-secondary', themeColorVariations[e]['secondary']);
    document.body.style.setProperty('--brand-thirtiary', themeColorVariations[e]['thirtiary']);
    document.body.style.setProperty('--brand-element', themeColorVariations[e]['element']);
    document.body.style.setProperty('--brand-background', themeColorVariations[e]['background']);
    document.body.style.setProperty('--blue-shadow', '0px 7px 28px ' + hexToRgb(e, 0.57));
    document.body.style.setProperty('--blue-tile-shadow', '0px 7px 19px ' + hexToRgb(e, 0.57));
    document.body.style.setProperty('--blue-tile-hover-shadow', '0px 7px 22px ' + hexToRgb(e, 0.57));
  };

  const handleOnSubmit = (): void => {
    onSubmit(value);
  };

  const BackFunc = (): void => {
    goBack();
  };

  return (
    <>
      <div className="text-field-container">
        <ColorSelector value={value} selectValue={handleChange} />
      </div>
      <div className="button-container">
        <Button
          color="tertiary"
          iconposition="left"
          icon="arrow_left"
          label="Go back"
          onClick={BackFunc}
          size="large"
        />
        <Button
          data-cy="configuration-color-next"
          color="primary"
          label="Next"
          icon="arrow_right"
          onClick={handleOnSubmit}
          size="large"
          iconposition="right"
        />
      </div>
    </>
  );
};
