import { AxiosResponse } from 'axios';
import { GetProject } from '../types/dto/Project.dto';
import { instance } from './private';

export const getProject = (id: string): Promise<AxiosResponse<GetProject>> =>
  instance.get('project', { params: { id } });

export const postProjectMultisig = (
  signature: string,
  wallet: string,
  multisig: { bsc?: string; eth?: string },
  timestamp: number,
  addressess: string[],
  network: string,
): Promise<AxiosResponse> =>
  instance.post('/project/multisig', {
    signature,
    wallet,
    multisig,
    timestamp,
    addressess,
    network,
  });
