import { BigNumber, BigNumberish } from 'ethers';
import React from 'react';
import { integerRegex } from '../../sdk/constants/regex';
import { TextField } from '../TextField';

export interface WeiAmount {
  amount: BigNumberish;
  decimals: number;
}

export interface WeiInputProps {
  value: WeiAmount;
  label: string;
  onChange: (value: WeiAmount) => void;
  onValidate?: (value: WeiAmount) => string;
  disabled?: boolean;
  dataCy?: string;
}

export const WeiInput: React.FC<WeiInputProps> = (props) => {
  return (
    <React.Fragment>
      <div className="wei-input flex">
        <TextField
          data-cy={props.dataCy}
          className="spacer"
          value={props.value.amount.toString()}
          label={props.label}
          type="number"
          min="0"
          step="1"
          icon="tokens"
          disabled={props.disabled}
          onChange={(value): void => {
            props.onChange({
              amount: integerRegex.test(value) ? BigNumber.from(value) : value,
              decimals: props.value.decimals ? props.value.decimals : 18,
            });
          }}
          onValidate={(value): string => {
            if (!integerRegex.test(value)) return 'Please enter a valid number';
            if (props.onValidate)
              return props.onValidate({
                amount: integerRegex.test(value) ? BigNumber.from(value) : value,
                decimals: props.value.decimals ? props.value.decimals : 18,
              });
            return '';
          }}
        />
      </div>
    </React.Fragment>
  );
};
