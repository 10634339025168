import { RootState } from '../store';
import { AuthState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { truncateAddress } from '../../utils/address';

export const getAuthState = (state: RootState): AuthState => state.auth.state;

export const getAuthRoles = (state: RootState): string[] => state.auth.roles;

export const getAuthAddress = (state: RootState): string => state.auth.publicKey;

export const getNetwork = (state: RootState): string => state.auth.network;

export const getAuthName = (state: RootState): string => state.auth.name;

export const getAuthEmail = (state: RootState): string => state.auth.email;

export const getExplorer = (state: RootState): string => state.auth.explorer;

export const getShortPublicKey = createSelector(getAuthAddress, (publicKey) =>
  publicKey.length === 0 ? 'unknown' : truncateAddress(publicKey),
);
