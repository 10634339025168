import React, { useEffect, useState, useRef } from 'react';
import { Icons } from '../../assets/icons';
import cls from 'classnames';

export interface DropdownOption {
  value: string | number;
  icon?: string;
  name?: string;
  rightComponent?: React.ReactNode;
}

export interface IDropdownProps {
  value: string | number;
  label?: string;
  onChange: (value: string | number) => void;
  options: DropdownOption[];
  ///
  icon?: string;
  height?: number;
  disabled?: boolean;
}

export const Dropdown: React.FC<IDropdownProps> = ({
  value,
  onChange,
  label,
  icon,
  options,
  height,
  disabled = false,
}) => {
  const dropdownRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const toggleVisibility = (): void => {
    if (!disabled) {
      if (!visible) {
        document.addEventListener('click', handleClickOutside, false);
      } else {
        document.removeEventListener('click', handleClickOutside, false);
      }

      setVisible(!visible);
    }
  };

  const handleClickOutside = (event: Event): void => {
    const currentRef = dropdownRef as React.RefObject<HTMLElement>;

    if (currentRef.current && !currentRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  const optionIndex = options.findIndex((option) => option.value === value);
  const [selected, setSelected] = useState(optionIndex);

  useEffect(() => {
    if (value !== '') {
      setSelected(options.findIndex((option) => option.value === value));
    }
  }, [value, options]);

  const handleOptionClick = (index: number) => (): void => {
    setSelected(index);
    onChange(options[index].value);
  };

  const displayText = selected !== -1 ? options[selected].name || options[selected].value : value || label;
  const displayIcon = options[selected] && options[selected].icon ? options[selected].icon : Icons[icon || ''] || icon;
  return (
    <div className="dropdown" ref={dropdownRef} onClick={toggleVisibility}>
      {label && <div className="dropdown-label header-smallest">{label}</div>}
      {displayIcon && <img className="dropdown-icon" src={displayIcon} />}
      <div className="header-light-medium">{displayText}</div>

      <div className={cls('dropdown-content', { visible })}>
        <div className="scroll-container scroll-style" style={{ height: height ? height - 35 : 'auto' }}>
          {options.map(({ icon, name, value: optionValue, rightComponent }, index) => (
            <div
              key={index}
              className={cls('dropdown-item', { selected: selected === index })}
              onClick={handleOptionClick(index)}
            >
              {icon && <img className="dropdown-icon" src={icon} />}
              {name || optionValue}
              {rightComponent && <div className="dropdown-rightComponent">{rightComponent}</div>}
            </div>
          ))}
        </div>
      </div>
      <div className="dropdown-rightComponent">
        <img src={Icons.expand} />
      </div>
    </div>
  );
};
