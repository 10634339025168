import { createAction } from '@reduxjs/toolkit';
import { WagmiConnectionResult } from '../ethers/web3/sagas';
import { authSlice } from './slice';

export const {
  setAuthorizeState,
  storeAuthorization,
  removeAuthorization,
  checkAuthorization,
  offAuthorization,
  addAuthorizationRole,
  setPublicKey,
  setName,
  setEmail,
  setNetwork,
  setExplorer,
} = authSlice.actions;

type RequireAuthorization = (username: string, password: string) => { payload: { email: string; password: string } };
export const requireAuthorization = createAction<RequireAuthorization>(
  'auth/require_authorization',
  (email: string, password: string) => ({
    payload: { email, password },
  }),
);

export const revokeAuthorization = createAction('auth/revoke_authorization');

export const connectWallet = createAction<WagmiConnectionResult['provider']>('auth/connect_wallet');
