import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProjectConfig } from '../../ducks/project/selectors';
import { getNetworkName } from '../../sdk/helpers/network';
import { InfiniteStakingInterface } from '../../types/dto/Project.dto';
import { InfiniteStakingPool } from '../InfiniteStakingPool';
import { loadV2 } from '../InfiniteStakingPool/loadInfiniteStakingV2';
import { StakingTitle } from '../StakingTitle';

export const InfiniteStakingPoolList: React.FC<{
  title: string;
  subTitle: string;
  filter: (pool: InfiniteStakingPool) => boolean;
}> = (props) => {
  const [pools, setPools] = useState<(InfiniteStakingPool | null)[]>([]);
  const [loading, setLoading] = useState(true);

  const config = useSelector(getProjectConfig);

  const load = async (): Promise<void> => {
    if (!config) return;

    setLoading(true);

    const currentNetwork = await getNetworkName();
    const campaigns =
      config.campaignsInfiniteStaking?.filter((item: InfiniteStakingInterface) => item.network === currentNetwork) ||
      [];

    async function loadCampaign(campaign: InfiniteStakingInterface): Promise<InfiniteStakingPool | null> {
      try {
        return await loadV2(campaign);
      } catch (e) {
        console.error(e);
      }

      return null;
    }

    const promises = campaigns.map(loadCampaign);

    Promise.all(promises).then((pools) => {
      setPools(pools);
      setLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, [config]);

  return (
    <React.Fragment>
      <StakingTitle title={props.title} hideInfo={true} message={props.subTitle} showRefresh={true} onRefresh={load} />
      <div className="staking">
        {!loading &&
          pools
            .filter((pool) => pool && props.filter(pool))
            .map(
              (pool, poolIndex) =>
                pool && (
                  <div key={poolIndex}>
                    <InfiniteStakingPool pool={pool} />
                  </div>
                ),
            )}
        {loading && <div className="staking-loading">Loading...</div>}
      </div>
    </React.Fragment>
  );
};
