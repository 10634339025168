import { BigNumber } from 'ethers';
import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import artifacts from './NonCompoundingRewardsPoolFactory.json';
import { Contract } from 'ethers';
import { MULTISIG_DOES_NOT_EXIST } from '../constants/errors';
import { ContractLoader } from '../helpers/ContractLoader';
import { dateToBlock, Duration, durationToBlocks, getFutureBlockNumber } from '../helpers/duration';
import { getNetworkConfig } from '../helpers/network';
import { loadContract } from '../helpers/loadContract';
import { sendMultisig } from '../helpers/sendMultisig';
import { Treasury } from './Treasury';

export class NonCompoundingRewardsPoolFactory {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'NonCompoundingRewardsPoolFactoryV1',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async deploy(treasury: Treasury, externalRewardToken: string): Promise<void> {
    if (treasury.contract === null) {
      throw new Error('Treasury contract not defined');
    }

    this.contract = await this.loader.deploy([treasury.contract.address, externalRewardToken]);
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deployCampaign(
    stakingToken: string,
    rewardsTokens: Array<string>,
    rewards: Array<BigNumber>,
    stakeLimit: BigNumber,
    contractStakeLimit: BigNumber,
    start: Date,
    end: Date,
    throttleRoundDuration: Duration,
    throttleRoundCap: BigNumber,
  ): Promise<string> {
    const startBlock = await dateToBlock(start);
    const endBlock = await dateToBlock(end);

    const numBlocks = endBlock - startBlock;
    const rewardsPerBlock = rewards.map((reward) => reward.div(numBlocks).toString());

    if (this.contract === null) throw new Error('Trying to call deploy campaign on unloaded contract');

    const throttleRoundBlocks = await durationToBlocks(throttleRoundDuration);

    return await sendMultisig(this.contract, 'deploy', [
      stakingToken,
      startBlock,
      endBlock,
      rewardsTokens,
      rewardsPerBlock,
      stakeLimit,
      throttleRoundBlocks,
      throttleRoundCap,
      contractStakeLimit,
    ]);
  }

  async transferOwnerShipToMultisig(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const wallet = loadContract('wallet') as MultiSigWallet;

    if (wallet === undefined) {
      throw new Error(MULTISIG_DOES_NOT_EXIST);
    }

    const transferTx = await this.contract.transferOwnership(wallet.address);
    await transferTx.wait();

    return await sendMultisig(this.contract, 'acceptOwnership', []);
  }

  async enableReceivers(transferer: string, receivers: string[]): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'enableReceivers', [transferer, receivers]);
  }
}
