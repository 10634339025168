import React, { useState } from 'react';
import { AdminsReview } from './AdminsReview';
import { AdminsView } from './AdminsView';
import { AdminsEdit } from './AdminsEdit';
import { useDispatch, useSelector } from 'react-redux';
import { getAdmins, getTemporaryAdmins } from '../../../ducks/admins/selectors';
import { clearTempAdmins, confirmAdminsEdit } from '../../../ducks/admins/actions';

enum Screen {
  View,
  Edit,
  Review,
}

export const Admins: React.FC = () => {
  const [screen, setScreen] = useState<Screen>(Screen.View);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const signers = useSelector(getAdmins);
  const temporary = useSelector(getTemporaryAdmins);

  const handleEditClick = (): void => {
    setScreen(Screen.Edit);
  };

  const handleCancelClick = (): void => {
    setScreen(Screen.View);
    dispatch(clearTempAdmins());
  };

  const handleReviewClick = (): void => {
    setScreen(Screen.Review);
  };

  const handleBackClick = (): void => {
    setScreen(Screen.Edit);
  };

  const handleSubmitClick = (): void => {
    setScreen(Screen.View);
    setProcessing(true);
    dispatch(
      confirmAdminsEdit(() => {
        setProcessing(false);
      }),
    );
  };

  switch (screen) {
    case Screen.View:
      return <AdminsView signers={signers} onEditClick={handleEditClick} processing={processing} />;
    case Screen.Edit:
      return (
        <AdminsEdit
          signers={signers}
          newSigners={temporary}
          onCancelClick={handleCancelClick}
          onReviewClick={handleReviewClick}
        />
      );
    case Screen.Review:
      return (
        <AdminsReview
          signers={signers}
          newSigners={temporary}
          onBackClick={handleBackClick}
          onSubmitClick={handleSubmitClick}
        />
      );
  }
};
