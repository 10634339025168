import React from 'react';

import cls from 'classnames';

import './Panel.scss';

export interface StepSubscriptionProps {
  children: React.ReactNode;
  className?: string;
}

export const Panel: React.FC<StepSubscriptionProps> = ({ children, className }) => (
  <div className={cls('panel', className)}>{children}</div>
);
