import { instance } from './public';
import { User } from '../types/dto/User.dto';
import { getNetworkName } from '../sdk/helpers/network';

export const getUserByAddress = async (address: string): Promise<User | undefined> => {
  try {
    const response = await instance.get('/user', {
      params: {
        wallet: address,
      },
    });

    if (!response.data.error) {
      return response.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const addUser = async (
  user: string | undefined,
  email: string | any,
  newUserWallet: string | undefined,
  wallet: string | any,
  signature: string | any,
  timestamp: number,
): Promise<User | undefined> => {
  try {
    const response = await instance.post('/user/add-user', {
      signature: signature,
      wallet: wallet,
      newUserWallet: newUserWallet,
      username: user,
      email: email,
      timestamp,
      network: await getNetworkName(),
    });

    if (!response.data.error) {
      return response.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const removeUser = async (
  wallet: string | any,
  walletToRemove: string | undefined,
  signature: string | any,
  timestamp: number,
  transactionId: string,
): Promise<User | undefined> => {
  try {
    const response = await instance.post('/transaction/remove-user', {
      signature,
      wallet,
      walletToRemove,
      network: await getNetworkName(),
      timestamp,
      transactionId,
      type: 'removeUser',
    });

    if (!response.data.error) {
      return response.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
