import React, { useEffect, useState } from 'react';
import { BigNumber, constants } from 'ethers';
import { createDuration, Duration, DurationUnits } from '../../sdk/helpers/duration';
import { getNetworkId } from '../../sdk/helpers/network';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { RewardsPoolBase } from '../../sdk/staking-v2/RewardsPoolBase';
import { handleError } from '../../utils/handleError';
import { integerRegex } from '../../sdk/constants/regex';
import { Icons } from '../../assets/icons';
import { calculatePercentage } from '../../sdk/helpers/calculatePercentage';
import { Button } from '../Button';
import { PoolAsset } from '../PoolAsset';
import { InfinitePoolCampaign } from '../InfinitePoolCampaign';
import { PoolFundRewards } from '../PoolFundRewards';
import { PoolEpoch } from '../PoolEpoch';
import { LMReward, PoolVersion, getExplorerAddressByChainId } from '../../utils/helpers';
import { getProjectConfig } from '../../ducks/project/selectors';
import { fetchProject } from '../../ducks/project/action';

export interface InfiniteStakingPool {
  address: string;
  symbol: string;
  decimals: number;
  totalLimit: BigNumber;
  walletLimit: BigNumber;
  duration: Duration;
  campaignRewards: LMReward[];
  start: Date;
  end: Date;
  done: boolean;
  version: PoolVersion;
  name: string;
  epochDuration: Duration;
  campaignMessage: string;
  stakingTokenAddress: string;
  state: InfiniteStakingState;
  totalStaked?: BigNumber;
}

export enum InfiniteStakingState {
  NOT_STARTED,
  SCHEDULED,
  ACTIVE,
  EXPIRED,
}

export interface InfiniteCampaignStatusStateData extends InfiniteCampaignBaseStatusData {
  state: InfiniteStakingState;
}

export interface InfiniteCampaignBaseStatusData {
  startTimestamp: number;
  endTimestamp: number;
  epochDuration: number;
  distributableFunds: boolean;
  upcoming: boolean;
}

export const InfiniteStakingPool: React.FC<{ pool: InfiniteStakingPool }> = ({ pool }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const config = useSelector(getProjectConfig);

  const web3 = useSelector(getEthersWeb3Provider);

  const [refundPoolPopup, setRefundPoolPopup] = useState<boolean>(false);
  const [chainId, setChainId] = useState<string>('');

  useEffect(() => {
    getNetworkId().then((id) => {
      setChainId(id);
    });
    return (): void => {
      setChainId('');
    };
  }, []);

  //   TODO: enable withdraw excessrewards
  async function withdrawExcessRewards(): Promise<void> {
    const rewardsPool = new RewardsPoolBase();
    await rewardsPool.load(pool.address);
    const wallet = await web3?.signer.getAddress();

    await handleError(
      rewardsPool.withdrawExcessRewards(wallet),
      dispatch,
      'Withdraw of excess rewards',
      'The action is now awaiting multisig confirmation',
      () =>
        setTimeout(() => {
          history.push('/multisig/transactions');
        }, 1000),
    );
  }

  async function schedule(): Promise<void> {
    const stakingTokenProps = config.tokens?.filter(({ address }) => address === pool.stakingTokenAddress)[0];
    const decimals = stakingTokenProps?.decimals || 18;

    const state = {
      step: 1,
      version: pool.version,
      name: pool.name,
      campaignMessage: pool.campaignMessage,
      protocol: 'uniswap',
      userStakingLimit: { amount: pool.walletLimit.div(BigNumber.from(10).pow(decimals)), decimals },
      stakingLimit: { amount: pool.totalLimit.div(BigNumber.from(10).pow(decimals)), decimals },
      rewards: pool.campaignRewards.map((reward) => ({
        readOnly: true,
        info: config.tokens?.filter(({ address }) => address === reward.address)[0] || {
          network: '',
          name: reward.symbol,
          symbol: reward.symbol,
          address: reward.address,
          decimals: reward.decimals,
          coinGeckoID: '',
          projectToken: true,
        },
        reward: { amount: reward.totalRewards, decimals: reward.decimals },
      })),
      stakingAddress: pool.stakingTokenAddress,
      throttleRoundDuration: createDuration(1, DurationUnits.seconds),
      throttleRoundCap: { amount: BigNumber.from(1), decimals },
      poolAddress: pool.address,
    };

    history.push(`/istaking/new`, state);
  }

  const checkMaxStakingLimit = (limit: BigNumber): boolean => {
    const tenBN = BigNumber.from(10);
    const tenPow18BN = tenBN.pow(18);
    const maxAmount = constants.MaxUint256.div(tenPow18BN);

    return limit.div(tenPow18BN).eq(maxAmount);
  };

  const hasContractStakeLimit = !checkMaxStakingLimit(pool.totalLimit);

  return (
    <div className="staking-pool">
      <div className="pool-header flex flex-center">
        <div className="pool-header-content flex">
          <div className="pool-header-assets flex">
            <div className="pool-asset-exchange flex">
              <img src={`${process.env.REACT_APP_ASSETS}/${pool.stakingTokenAddress.toLocaleLowerCase()}`} />
              {pool.symbol.toUpperCase()}
            </div>
          </div>
        </div>
      </div>

      <div className="pool-content">
        <div className="pool-info">
          <div className="pool-info-stats flex">
            {pool.start.getTime() !== 0 && (
              <div>
                <div className="pool-content-header">Total rewards this epoch</div>
                {pool.campaignRewards.map((campaignReward, index) => (
                  <PoolAsset
                    asset={{
                      address: campaignReward.address,
                      balance: campaignReward.totalRewards,
                      decimals: campaignReward.decimals,
                      image: Icons[pool.symbol.toLowerCase()],
                      symbol: campaignReward.symbol,
                    }}
                    key={index}
                  />
                ))}

                <div className="spacer"></div>
              </div>
            )}

            {pool.campaignRewards.some((val) => val.hasExcessRewards) && (
              <React.Fragment>
                <div className="spacer"></div>
                <div className="pool-assets-treasuries">
                  <div className="pool-assets-treasuries-text flex pool-content-header">Excess rewards</div>
                  {pool.campaignRewards.map((reward) => (
                    <div key={reward.address}>
                      <PoolAsset
                        asset={{
                          address: reward.address,
                          balance: reward.excessRewards as BigNumber,
                          symbol: reward.symbol,
                          decimals: reward.decimals,
                        }}
                      >
                        <div className="pool-asset-amount-symbol">
                          <Button
                            color="primary-empty"
                            size="small"
                            onClick={async (): Promise<void> => {
                              await withdrawExcessRewards();
                              dispatch(fetchProject());
                            }}
                            label="Withdraw"
                          />
                        </div>
                      </PoolAsset>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}

            <div className="spacer"></div>

            <div className="pool-info-limit">
              <div className="pool-content-header">Total limit</div>
              <PoolAsset
                asset={{
                  balance: pool.totalLimit,
                  symbol: pool.symbol,
                  image: Icons[pool.symbol.toLowerCase()],
                  address: pool.stakingTokenAddress,
                  decimals: pool.decimals,
                }}
              />

              <div className="pool-content-header">Per wallet limit</div>
              <PoolAsset
                asset={{
                  balance: pool.walletLimit,
                  symbol: pool.symbol,
                  image: Icons[pool.symbol.toLowerCase()],
                  address: pool.stakingTokenAddress,
                  decimals: pool.decimals,
                }}
              />
            </div>

            {hasContractStakeLimit && (
              <div className="percentage-vertical-bar">
                <div
                  className={`${
                    calculatePercentage(
                      BigNumber.from(
                        pool.totalStaked && integerRegex.test(pool.totalStaked.toString()) ? pool.totalStaked : 0,
                      ),
                      BigNumber.from(pool.totalLimit),
                    ) !== 100
                      ? 'percentage-progress-max'
                      : ''
                  } percentage-progress`}
                  style={{
                    height: `${calculatePercentage(
                      BigNumber.from(
                        pool.totalStaked && integerRegex.test(pool.totalStaked.toString()) ? pool.totalStaked : 0,
                      ),
                      BigNumber.from(pool.totalLimit),
                    ).toString()}%`,
                  }}
                />
              </div>
            )}
          </div>

          {pool.start.getTime() !== 0 && (
            <>
              <div className="pool-info-divider"></div>
              <div className="pool-info-locking">
                <PoolEpoch end={pool.end} start={pool.start} upcoming={pool.state === InfiniteStakingState.SCHEDULED} />
                <div className="spacer"></div>
                <div className="pool-info-lockup total-staked">
                  <div className="pool-content-header">Total Staked</div>
                  <PoolAsset
                    asset={{
                      balance: pool && pool.totalStaked ? pool.totalStaked : BigNumber.from(0),
                      decimals: pool.decimals,
                      symbol: pool.symbol,
                      image: Icons[pool.symbol.toLowerCase()],
                      address: pool.stakingTokenAddress,
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <div className="pool-version">V{pool.version || '1.0'}</div>
          <div className="pool-address">
            {pool.address}
            <a
              href={`${getExplorerAddressByChainId(chainId)}${pool.address}`}
              className="hyperlink-pool-address"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Icons.open_link_light} />
            </a>
          </div>
        </div>

        <InfinitePoolCampaign start={pool.start} end={pool.end}>
          {pool.start.getTime() === 0 && (
            <Button
              color="primary-empty"
              size="medium"
              onClick={schedule}
              label="Schedule start"
              icon="calendar"
              iconposition="left"
            />
          )}

          {pool.start.getTime() !== 0 && pool.start < new Date() && (
            <>
              <Button
                color="primary-empty"
                size="medium"
                onClick={(): void => {
                  setRefundPoolPopup(true);
                }}
                label="Fund pool"
                icon="pool_blue"
                iconposition="left"
              />
            </>
          )}
        </InfinitePoolCampaign>
      </div>

      {refundPoolPopup && (
        <PoolFundRewards
          pool={{ ...pool, version: '4.0' }}
          onClose={(wasWhitelisted: boolean): void => {
            setRefundPoolPopup(false);
            if (wasWhitelisted) {
              history.push('/multisig/transactions');
            }
          }}
        />
      )}
    </div>
  );
};
