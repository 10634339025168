import React, { useEffect, useState } from 'react';
import { StakingTitle } from '../../components/StakingTitle';
import { StakingPool } from '../../components/StakingPool';
import { useSelector } from 'react-redux';
import { getProjectConfig } from '../../ducks/project/selectors';
import { getNetworkName } from '../../sdk/helpers/network';
import { StakingInterface } from '../../types/dto/Project.dto';
import { loadV1 } from '../StakingPool/loadStakingV1';
import { loadV2 } from '../StakingPool/loadStakingV2';
import { PoolVersion, PoolVersions } from '../../utils/helpers';

export const StakingPoolList: React.FC<{
  title: string;
  subTitle: string;
  filter: (pool: StakingPool) => boolean;
}> = (props) => {
  const [pools, setPools] = useState<(StakingPool | null)[]>([]);
  const [loading, setLoading] = useState(true);

  const config = useSelector(getProjectConfig);

  const load = async (): Promise<void> => {
    if (!config) return;

    setLoading(true);

    const currentNetwork = await getNetworkName();
    const campaigns =
      config.campaignsStaking?.filter((item: StakingInterface) => item.network === currentNetwork) || [];

    async function loadCampaign(campaign: StakingInterface): Promise<StakingPool | null> {
      try {
        if (!campaign.version || campaign.version === PoolVersions.v1) {
          return await loadV1(campaign);
        }

        if ([PoolVersions.v2, PoolVersions.v3, PoolVersions.v4].includes(campaign.version as PoolVersion)) {
          return await loadV2(campaign);
        }
      } catch (e) {
        console.error(e);
      }

      return null;
    }

    const promises = campaigns.map(loadCampaign);

    Promise.all(promises).then((pools) => {
      setPools(pools);
      setLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, [config]);

  return (
    <React.Fragment>
      <StakingTitle title={props.title} hideInfo={true} message={props.subTitle} showRefresh={true} onRefresh={load} />
      <div className="staking">
        {!loading &&
          pools
            .filter((pool) => pool && props.filter(pool))
            .map(
              (pool, poolIndex) =>
                pool && (
                  <div key={poolIndex}>
                    <StakingPool pool={pool} />
                  </div>
                ),
            )}
        {loading && <div className="staking-loading">Loading...</div>}
      </div>
    </React.Fragment>
  );
};
