import React from 'react';
import { Icons } from '../../../assets/icons';
import { Separator } from '../../../components/Separator';
import { BackendAdmin } from '../../../ducks/admins/slice';
import { Button } from '../../../components/Button';
import { AccountCard } from '../../../components/AccountCard';
import { useSelector } from 'react-redux';
import { getExplorer } from '../../../ducks/auth/selectors';

interface IProps {
  approves: number;
  signers: BackendAdmin[];
  onCancelClick: () => void;
  onSubmitClick: () => void;
  onBack: () => void;
}

export const TransactionsReview: React.FC<IProps> = ({ approves, signers, onSubmitClick, onCancelClick, onBack }) => {
  // TODO: Use selector to read
  const amountToBeSent = '145';
  const tokenBeingSent = {
    address: '0x123',
    name: 'ETH',
  };

  return (
    <>
      <div className="transaction-title">
        <div className="transaction-title-back">
          <img src={Icons.arrow_left_black} onClick={onBack} />
          <h3>Sending funds</h3>
        </div>
        <img className="transaction-section-info" src={Icons.info} />
      </div>
      <div className="paragraph-large">
        You are about to send Ethereum to external wallet. It requires to confirm a transaction with your wallet. It
        will cost approximately {'<'} 0.001 ETH. The exact amount will be determined by your wallet.
      </div>

      <div className="transaction-address-container">
        <div className="transaction-section-header">
          <h5>RECIPIENT</h5>
        </div>

        <div className="transaction-section">
          <AccountCard
            name="John Doe"
            address={'0x1234'}
            etherscanLink={`${useSelector(getExplorer)}`}
            rightComponent={
              <h6>
                {amountToBeSent} {tokenBeingSent.name}
              </h6>
            }
          />
        </div>
      </div>

      <div className="padded-container">
        <div className="transaction-section-header">
          <h5>TRANSACTION</h5>
        </div>
        <div className="transaction-section">
          <Separator
            leftComponent={
              <div>
                {approves} of {signers.length} administrators need to approve
              </div>
            }
            rightComponent={
              <div className="small-avatar-list">
                {signers.map(({ address }) => (
                  <div key={address} className="admins-small-avatar-container">
                    {/*{signer && <img className="avatar-image" src={signer} />}*/}
                  </div>
                ))}
              </div>
            }
          />
          <Separator
            leftComponent={<div>Amount to be sent</div>}
            rightComponent={
              <div className="small-avatar-list">
                {amountToBeSent} {tokenBeingSent.name}
              </div>
            }
          />
        </div>
      </div>

      <div className="transaction-button-container">
        <Button
          label="Cancel"
          size="large"
          icon="declined_black"
          iconposition="left"
          color="tertiary"
          onClick={onCancelClick}
        />
        <Button label="Submit" size="large" icon="arrow_right" iconposition="right" onClick={onSubmitClick} shadow />
      </div>
    </>
  );
};
