import { BigNumber } from 'ethers';
import { getCreditsCampaignsByDay, getCreditsCampaignsByType } from '../payment';

export const getPaymentCredits = async (userToken: string, days: number): Promise<BigNumber> => {
  const credits = await getCreditsCampaignsByDay(userToken, days);

  return credits;
};

export const checkIfDeployCampaignCreditsAvailable = async (userToken: string): Promise<boolean> => {
  let credits = 0;

  credits = await getCreditsCampaignsByType(userToken, 0);
  if (Number(credits)) return true;

  credits = await getCreditsCampaignsByType(userToken, 1);
  if (Number(credits)) return true;

  credits = await getCreditsCampaignsByType(userToken, 2);
  if (Number(credits)) return true;

  return false;
};

export const checkIfExtendCampaignCreditsAvailable = async (userToken: string): Promise<boolean> => {
  let credits = 0;

  credits = await getCreditsCampaignsByType(userToken, 3);
  if (credits) return true;

  return false;
};
