import * as yup from 'yup';
import { StringSchema } from 'yup';
import { ethers } from 'ethers';

yup.addMethod<StringSchema>(yup.string, 'isEthAddress', function () {
  return this.test({
    name: 'address',
    message: 'Invalid Ethereum address',
    test: (address = '') => {
      return ethers.utils.isAddress(address);
    },
  });
});
