import React, { useState } from 'react';

interface ClipboardTooltipProps {
  className?: string;
  children: string | JSX.Element | JSX.Element[];
  content: string;
  durationInMs?: number;
}

export const ClipboardTooltip: React.FC<ClipboardTooltipProps> = ({
  children,
  content,
  className,
  durationInMs = 2000,
}) => {
  const [active, setActive] = useState<boolean>(false);

  const handleOnClick = (): void => {
    setActive(true);
    navigator.clipboard.writeText(content);
    setTimeout(() => {
      setActive(false);
    }, durationInMs);
  };

  return (
    <span
      data-tooltip={'Copied!'}
      className={`tooltip clipboard ${active ? 'active' : ''} ${className ? className : ''}`}
      onClick={handleOnClick}
    >
      {children}
    </span>
  );
};
