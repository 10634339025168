import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BackendAdmin {
  name: string;
  email: string;
  address: string;
}

interface AdminsState {
  addresses: string[];
  meta: BackendAdmin[];
  // temporary list used while editing admins
  temp: BackendAdmin[];
}

const initialState: AdminsState = { addresses: [], meta: [], temp: [] };

export const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setAdminAddresses: (state, action: PayloadAction<string[]>): void => {
      state.addresses = action.payload;
    },
    addAdminAddress: (state, action: PayloadAction<string>): void => {
      state.addresses.push(action.payload);
    },
    deleteAdminAddress: (state, action: PayloadAction<string>): void => {
      const index = state.addresses.findIndex((admin) => admin === action.payload);
      if (index !== -1) state.addresses.splice(index, 1);
    },
    setAdminsMeta: (state, action: PayloadAction<BackendAdmin[]>): void => {
      state.meta = action.payload;
    },
    addAdminMeta: (state, action: PayloadAction<BackendAdmin>): void => {
      state.meta.push(action.payload);
    },
    deleteAdminMeta: (state, action: PayloadAction<string>): void => {
      const index = state.meta.findIndex(({ address }) => address === action.payload);
      if (index !== -1) state.meta.splice(index, 1);
    },
    setTempAdmins: (state, action: PayloadAction<BackendAdmin[]>): void => {
      state.temp = action.payload;
    },
    clearTempAdmins: (state): void => {
      state.temp = [];
    },
  },
});
