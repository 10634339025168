import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { AdminRow } from '../../pages/Manage/Admins/AdminsEdit';
import { getAuthAddress, getAuthName, getAuthEmail } from '../../ducks/auth/selectors';
import * as yup from 'yup';
import { BackendAdmin } from '../../ducks/admins/slice';
import { Icons } from '../../assets/icons';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { getTemporaryAdmins } from '../../ducks/admins/selectors';
import { processCreateMultisig } from '../../ducks/project/action';
import axios, { AxiosResponse } from 'axios';
import { getProjectId } from '../../ducks/project/selectors';
interface IProps {
  onReviewClick: () => void;
}

const multisigSchema = yup.object().shape({
  signers: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().required('Email is required').email(),
        address: yup.string().required('Address is required').isEthAddress(),
      }),
    )
    .min(1),
});

export const MultisigInput: React.FC<IProps> = ({ onReviewClick }) => {
  const dispatch = useDispatch();
  const address = useSelector(getAuthAddress);
  const name = useSelector(getAuthName);
  const email = useSelector(getAuthEmail);
  const newSigners = useSelector(getTemporaryAdmins);
  const projectId = useSelector(getProjectId);
  const formik = useFormik<{ signers: AdminRow[] }>({
    initialValues: {
      signers: [],
    },
    validationSchema: multisigSchema,
    onSubmit: (values) => {
      dispatch(processCreateMultisig(values.signers));
      onReviewClick();
    },
  });

  useEffect(() => {
    async function getUsers(): Promise<AxiosResponse | undefined> {
      let users: any;

      try {
        users = await axios.get(process.env.REACT_APP_API + '/user/users', {
          params: {
            projectId: projectId,
          },
        });
        formik.setFieldValue(
          'signers',
          users?.data.usersArray.map((user: { username: string; email: string; wallet: string }) => ({
            name: user.username,
            email: user.email,
            address: user.wallet,
          })),
        );
        formik.setTouched({
          signers: users?.data.usersArray.map((user: { username: string; email: string; wallet: string }) => ({
            name: true,
            email: true,
            address: true,
          })),
        });

        console.log(users.data.usersArray);
      } catch (e) {
        console.log(e);
      }
      return users;
    }

    getUsers();

    if (!newSigners) {
      formik.setFieldValue('signers', [{ name, email, address }]);
      formik.setTouched({ signers: [{ name: true, email: true, address: true }] });
    }
  }, []);

  useEffect(() => {
    // Create more natural feeling of coming back here from "Review View"
    if (newSigners) {
      formik.setFieldValue('signers', newSigners);
      formik.setTouched({ signers: newSigners.map(() => ({ name: true, email: true, address: true })) });
    }
  }, [newSigners]);

  const fieldStatus = (index: number, field: keyof BackendAdmin): ReactNode => {
    if (formik.touched.signers && formik.touched.signers[index] && formik.touched.signers[index][field])
      return formik.errors.signers?.[index] && (formik.errors.signers[index] as FormikErrors<BackendAdmin>)[field] ? (
        <img className="admin-input-badge admin-input-bad" src={Icons.declined} />
      ) : (
        <img className="admin-input-badge admin-input-good" src={Icons.approved_white} />
      );
    return null;
  };

  return (
    <>
      <div className="multisig-header">
        <h3>Multisig Setup</h3>
      </div>
      <div>
        Multi-signature (multisig) refers to requiring multiple keys to authorize a transaction, rather than a single
        signature from one key. It allows for secure transactions on the network without given the execution power to a
        single administrator. At least 51% of the administrators added to the multisig need to accept a transaction
        before it is executed.
      </div>
      <div className="add-container scroll-container scroll-style">
        <FormikProvider value={formik}>
          <FieldArray name="signers">
            {({ remove, push }): any => (
              <div className="admin-input-container">
                {formik.values.signers.map(({ name, email, address }, index) => (
                  <div key={index} className="admin-input-row">
                    <TextField
                      name={`signers.${index}.name`}
                      type="text"
                      value={name}
                      icon="admins"
                      label="Admin name"
                      onChange={(_value, e): void => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      rightComponent={fieldStatus(index, 'name')}
                    />
                    <TextField
                      name={`signers.${index}.email`}
                      type="text"
                      value={email}
                      icon="admins"
                      label="Admin email"
                      onChange={(_value, e): void => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      rightComponent={fieldStatus(index, 'email')}
                    />
                    <TextField
                      name={`signers.${index}.address`}
                      type="text"
                      value={address}
                      icon="wallet_black"
                      label="Wallet address"
                      onChange={(_value, e): void => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                      rightComponent={fieldStatus(index, 'address')}
                    />
                    {index !== 0 ? (
                      <img src={Icons.bin} className="delete-icon" onClick={(): void => remove(index)} />
                    ) : (
                      <img src={Icons.bin} className="delete-icon--disabled" />
                    )}
                  </div>
                ))}

                <div className="admin-input-add">
                  <Button
                    label="Add admin"
                    color="primary-empty"
                    size="medium"
                    icon="plus"
                    iconposition="left"
                    onClick={(): void => push({ name: '', address: '' })}
                  />
                </div>
              </div>
            )}
          </FieldArray>
        </FormikProvider>
      </div>
      <div className="multisig-button-container">
        <Button
          label="Review"
          size="large"
          icon="arrow_right"
          iconposition="right"
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
          shadow
        />
      </div>
    </>
  );
};
