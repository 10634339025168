import React from 'react';
import { PrivateRoute } from '../../containers/routes/PrivateRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ActivePools } from './Active';
import { ExpiredPools } from './Expired';
import { CreateLMPool } from './CreatePool';
import { ScheduledPools } from './Scheduled';
import { AwaitingPools } from './Awaiting';

interface IProps {
  baseUrl: string;
}

export const LiquidityMining: React.FC<IProps> = ({ baseUrl }) => (
  <PrivateRoute path={baseUrl} redirectTo="/register">
    <Switch>
      <Route path={baseUrl + '/new'}>
        <CreateLMPool />
      </Route>
      <Route path={baseUrl + '/awaiting'}>
        <AwaitingPools />
      </Route>
      <Route path={baseUrl + '/scheduled'}>
        <ScheduledPools />
      </Route>
      <Route path={baseUrl + '/active'}>
        <ActivePools />
      </Route>
      <Route path={baseUrl + '/expired'}>
        <ExpiredPools />
      </Route>
      <Redirect path={baseUrl} to={baseUrl + '/active'} />
    </Switch>
  </PrivateRoute>
);
