import React, { useEffect, useState } from 'react';
import { MultisigInput } from './MultisigInput';
import { MultisigRewiev } from './MultisigRewiev';
import { useSelector } from 'react-redux';
import { getMultisig } from '../../ducks/project/selectors';
import { RestrictedView } from '../views/RestrictedView';
import { getNetworkId } from '../../sdk/helpers/network';
import { store } from '../../ducks/store';

enum Screen {
  Add,
  Review,
}

export const MultisigCreation: React.FC = () => {
  const [screen, setScreen] = useState<Screen>(Screen.Add);

  const handleReviewClick = (): void => {
    setScreen(Screen.Review);
  };

  const handleBackClick = (): void => {
    setScreen(Screen.Add);
  };

  const ethMultisig = useSelector(getMultisig);

  if (ethMultisig) return null;
  return (
    <RestrictedView roles={['web3']}>
      <div className="multisig">
        <div className="multisig-overlay" />
        <div className="multisig-container">
          <div className="multisig-container-content">
            {screen === Screen.Add ? (
              <MultisigInput onReviewClick={handleReviewClick} />
            ) : (
              <MultisigRewiev onBackClick={handleBackClick} />
            )}
          </div>
        </div>
      </div>
    </RestrictedView>
  );
};
