import React from 'react';
import { NotificationHeader } from './NotificationHeader';
import { Icons } from '../../assets/icons';
import { TabView } from '../TabView';
import { Button } from '../Button';
import { NotificationInfoList } from './NotificationInfoList';
import { IAdmins, NotificationTransaction } from './NotificationTransaction';

interface INotificationProps {
  currentPage: number;
  notifications: number;
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
  onConfirmClick: () => void;
  onDeclineClick: () => void;
  onChipClick: () => void;
  transactionInfo: {
    title: string;
    value: string | number;
  }[];
  sendingTo: {
    name?: string;
    avatar?: string;
    address: string;
  };
  transaction: NotificationTransaction;
  confirmed: IAdmins[];
  awaiting: IAdmins[];
  processing: boolean;
}

export const Notification: React.FC<INotificationProps> = ({
  currentPage,
  notifications,
  onLeftArrowClick,
  onRightArrowClick,
  transactionInfo,
  onConfirmClick,
  onDeclineClick,
  onChipClick,
  processing,
  ...transaction
}) => (
  <div className="notification-container">
    <NotificationHeader
      current={currentPage}
      max={notifications}
      onLeftArrowClick={onLeftArrowClick}
      onRightArrowClick={onRightArrowClick}
      onChipClick={onChipClick}
    />
    <div className="notification-content">
      <h5>Pending confirmation</h5>
      <span className="paragraph-large info-text">
        There is a transaction pending your confirmation. Click Confirm button to approve transaction or Dismiss button
        to decline.
      </span>
      <TabView
        tabs={[
          { name: 'Transaction', component: <NotificationTransaction {...transaction} /> },
          { name: 'Info', component: <NotificationInfoList list={transactionInfo} /> },
        ]}
      />
      <div className="buttons-container">
        <Button
          label="Dismiss"
          size="medium"
          color="negative"
          icon="times"
          iconposition="left"
          disabled={processing}
          onClick={onDeclineClick}
        />
        {processing ? (
          <Button
            label="Processing"
            color="process"
            size="medium"
            icon="spinner"
            iconposition="left"
            onClick={onConfirmClick}
            spin
          />
        ) : (
          <Button
            label="Confirm"
            size="medium"
            icon="check"
            iconposition="left"
            onClick={onConfirmClick}
            data-cy="notification-container-confirm-button"
          />
        )}
      </div>
    </div>
  </div>
);
