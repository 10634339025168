import React, { useState } from 'react';
import { Icons } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { ToggleSwitch } from '../../../components/ToggleSwitch';
import { Dropdown } from '../../../components/Dropdown';
import { TextField } from '../../../components/TextField';

interface IProps {
  approves: number;
  onCancelClick: () => void;
  onReviewClick: () => void;
  onBack: () => void;
}

interface Token {
  address: string;
  name: string;
}

export const TransactionsSend: React.FC<IProps> = ({ approves, onCancelClick, onReviewClick, onBack }) => {
  const [isMining, setIsMining] = useState(false);
  const [amountToSend, setAmountToSend] = useState('0.00');

  const onSwitchToggle = (checked: boolean): void => {
    setIsMining(checked);
  };

  const onPoolSelect = (value: string | number): void => {
    return;
  };

  const onContractSelect = (value: string | number): void => {
    return;
  };

  const onAmountChange = (value: string): void => {
    setAmountToSend(value);
  };

  const onMaxClick = (): void => {
    // TODO: Read balance for the token
    setAmountToSend('10000');
  };

  const poolOptions = [{ value: 'Pool 1' }, { value: 'Pool 2' }, { value: 'Pool 3' }];
  const contractOptions = [{ value: '0x123' }, { value: '0x145' }, { value: '0x585' }];

  return (
    <>
      <div className="transaction-title">
        <div className="transaction-title-back">
          <img src={Icons.arrow_left_black} onClick={onBack} />
          <h3>Sending funds</h3>
        </div>
        <img className="transaction-section-info" src={Icons.info} />
      </div>
      <div>Sending requires {approves} Administrators to approve a transaction.</div>
      <div className="transaction-address-container">
        <div className="transaction-section-header">
          <h5>RECIPIENT</h5>
          <div>
            <span className="header-light-regular">Campaigns: </span>
            <span className="header-regular">Staking </span>
            <ToggleSwitch onChange={onSwitchToggle} checked={isMining} />
            <span className="header-regular"> Liquidity mining</span>
          </div>
        </div>

        <div className="send-dropdowns">
          <Dropdown value={'Pool name'} label="POOL" onChange={onPoolSelect} options={poolOptions} />
          <Dropdown value={'Contract name'} label="CONTRACT" onChange={onContractSelect} options={contractOptions} />
        </div>
      </div>

      <div className="padded-container">
        <div className="transaction-section-header">
          <h5>AMOUNT</h5>
        </div>
        <div className="transaction-section">
          <TextField
            label="TOKEN"
            type="text"
            onChange={onAmountChange}
            value={amountToSend}
            rightComponent={<Button color="primary" size="small" label="MAX" onClick={onMaxClick} />}
          />
        </div>
      </div>

      <div className="transaction-button-container">
        <Button
          label="Cancel"
          size="large"
          icon="declined_black"
          iconposition="left"
          color="tertiary"
          onClick={onCancelClick}
        />
        <Button label="Review" size="large" icon="arrow_right" iconposition="right" onClick={onReviewClick} shadow />
      </div>
    </>
  );
};
