import { BigNumber } from 'ethers';
import { blockToDate, blocksToDuration, createDuration, DurationUnits } from '../../sdk/helpers/duration';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { getNetworkConfig } from '../../sdk/helpers/network';
import { StakingInterface } from '../../types/dto/Project.dto';
import { PoolVersions } from '../../utils/helpers';
import { RewardsPoolBase as RewardsPoolBaseV1 } from '../../sdk/staking-v1/RewardsPoolBase';
import { StakingPool } from '.';

export async function loadV1(pool: StakingInterface): Promise<StakingPool> {
  const poolContract = new RewardsPoolBaseV1();
  await poolContract.load(pool.campaignAddress);

  const networkConfig = await getNetworkConfig();

  const promises: Promise<any>[] = [
    poolContract.getStartBlock(),
    poolContract.getEndBlock(),
    poolContract.getRewardPerBlock(0),
    poolContract.getStakingToken(),
    poolContract.getContractStakeLimit(),
    poolContract.getStakeLimit(),
    poolContract.getTotalStaked(),
  ];
  const results = await Promise.all(promises);

  const startBlock = parseInt(results[0]);
  const endBlock = parseInt(results[1]);
  const rewardPerBlock: BigNumber = results[2];
  const tokenContract = loadERC20(results[3]);

  const startDate = await blockToDate(startBlock);
  const endDate = await blockToDate(endBlock);
  const duration = await blocksToDuration(endBlock - startBlock);

  const totalReward = rewardPerBlock.mul(endBlock - startBlock);
  const secondsPerWeek = 604800;
  const blocksPerWeek = Math.floor(secondsPerWeek / networkConfig.BLOCK_TIME);
  const weeklyReward = rewardPerBlock.mul(blocksPerWeek);
  const campaignMessage = pool.campaignMessage || '';

  const symbol = await tokenContract.getSymbol();
  const decimals = await tokenContract.getDecimals();

  return {
    address: pool.campaignAddress,
    symbol: symbol,
    decimals,
    rewardAddress: results[3],
    rewardDecimals: decimals,
    rewardSymbol: symbol,
    rewardExcess: BigNumber.from(0),
    totalReward: totalReward,
    weeklyReward: weeklyReward,
    totalLimit: results[4],
    walletLimit: results[5],
    duration: duration,
    start: startDate,
    end: endDate,
    done: Date.now() > endDate.getTime(),
    version: PoolVersions.v1,
    name: '',
    campaignMessage: campaignMessage,
    throttleRoundDuration: createDuration(0, DurationUnits.seconds),
    throttleRoundCap: BigNumber.from(0),
    stakingTokenAddress: results[3],
    totalStaked: results[6],
  };
}
