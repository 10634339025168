import React from 'react';

export interface SidebarMenuProps {
  baseLocation: string;
  disabled?: boolean;
}

export const SidebarMenu: React.FC<SidebarMenuProps> = ({ baseLocation, disabled, children }) => {
  const childrenWithBaseLocation = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { baseLocation });
    }

    return child;
  });

  return (
    <div className="sidebar-menu">
      {disabled && <div className="sidebar-menu-disable-overlay" />}
      <h5>Menu</h5>
      {childrenWithBaseLocation}
    </div>
  );
};
