import artifacts from './NonCompoundingRewardsPoolInfinite.json';
import { BigNumber } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { AbstractRewardsPoolBase } from './AbstractRewardsPoolBase';
import { sendMultisig } from '../helpers/sendMultisig';
import { InfiniteCampaignBaseStatusData, InfiniteStakingState } from '../../components/InfiniteStakingPool';
import { parseUnits } from 'ethers/lib/utils';
import { TokenERC20 } from '../staking-v1/TokenERC20';

export class NonCompoundingRewardsPoolInfinite extends AbstractRewardsPoolBase {
  constructor() {
    super(
      new ContractLoader({
        name: 'NonCompoundingRewardsPoolInfinite',
        bytecode: artifacts.bytecode,
        abi: artifacts.abi,
      }),
      null,
    );
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deploy(
    stakingToken: string,
    rewardsTokens: Array<string>,
    stakeLimit: BigNumber,
    contractStakeLimit: BigNumber,
    name: string,
    locked: boolean,
  ): Promise<void> {
    this.contract = await this.loader.deploy([
      stakingToken,
      rewardsTokens,
      stakeLimit,
      contractStakeLimit,
      name,
      locked,
    ]);
  }

  async getEpochDuration(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.epochDuration();
  }

  async start(epochDuration: number, timestamp: number): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'start(uint256,uint256)', [epochDuration, timestamp]);
  }

  async hasStakingStarted(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.hasStakingStarted();
  }

  /**
   * Get campaign data
   * @public
   * @param {string} contractAddress - Address of the campaign contract
   * @return {InfiniteCampaignBaseStatusData} CampaingStatusData object
   */
  public async getCampaignStatus(): Promise<InfiniteCampaignBaseStatusData> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const campaignEndTimestamp: BigNumber = await this.contract.endTimestamp();
    const hasCampaignStarted: boolean = await this.contract.hasStakingStarted();
    const campaignStartTimestamp: BigNumber = await this.contract.startTimestamp();
    const epochDuration = campaignEndTimestamp.sub(campaignStartTimestamp);
    const now = Math.floor(Date.now() / 1000);

    const tokensCount = Number(await this.contract.getRewardTokensCount());

    let distributableFunds = false;
    if (hasCampaignStarted) {
      for (let i = 0; i < tokensCount; i++) {
        const availableBalance = await this.contract.getAvailableBalance([i]);

        const tokenAddress = (await this.contract.rewardsTokens([i])).toLowerCase();

        const token = new TokenERC20();
        await token.load(tokenAddress);

        const tokenDecimals = await token.getDecimals();

        if (!token) {
          console.error('token not found, fallback to 18 decimals', tokenAddress);

          distributableFunds = distributableFunds || availableBalance.gt(parseUnits('1', 18).div(epochDuration));

          continue;
        }

        distributableFunds =
          distributableFunds || availableBalance.gt(parseUnits('1', tokenDecimals).div(epochDuration));
      }
    }

    return {
      distributableFunds,
      endTimestamp: campaignEndTimestamp.toNumber(),
      epochDuration: campaignEndTimestamp.sub(campaignStartTimestamp).toNumber(),
      startTimestamp: campaignStartTimestamp.toNumber(),
      upcoming: campaignStartTimestamp.gt(now),
    };
  }

  async getState(): Promise<InfiniteStakingState> {
    const baseStatus = await this.getCampaignStatus();

    return this._getState(baseStatus);
  }

  protected _getState(statusData: InfiniteCampaignBaseStatusData): InfiniteStakingState {
    const { distributableFunds, endTimestamp, epochDuration, startTimestamp } = statusData;

    const now = Date.now() / 1000;

    // Unscheduled
    if (startTimestamp === 0) return InfiniteStakingState.NOT_STARTED;

    // Upcoming campaigns
    if (startTimestamp > now) return InfiniteStakingState.SCHEDULED;

    // Active campaigns
    if (now > startTimestamp) {
      if (now < endTimestamp) return InfiniteStakingState.ACTIVE;

      if (now < endTimestamp + epochDuration && distributableFunds) return InfiniteStakingState.ACTIVE;
    }

    return InfiniteStakingState.EXPIRED;
  }
}
