import { ContractInterface } from 'ethers';
import deployment from '../../utils/deployments.json';

const getDeploymentData = (chainId: number): any => {
  if (!(deployment as any)[chainId]) throw new Error(`Chain ID ${chainId} not supported`);
  const key = Object.keys((deployment as any)[chainId])[0];
  return (deployment as any)[chainId][key];
};

export const getMultisigFactoryContract = (
  chainId: number,
): {
  abi: ContractInterface;
  address: string;
} => {
  const { contracts } = getDeploymentData(chainId);
  return {
    abi: contracts.MultiSigWalletFactory.abi,
    address: contracts.MultiSigWalletFactory.address,
  };
};
