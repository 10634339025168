import React, { ReactNode } from 'react';
import { ReactComponent as OpenLinkIcon } from '../../assets/icons/open-link-light.svg';

export interface IAccountCardProps {
  avatar?: string;
  name: string;
  address: string;
  rightComponent: ReactNode;
  etherscanLink?: string;
}

export const AccountCard: React.FC<IAccountCardProps> = ({ avatar, name, address, rightComponent, etherscanLink }) => (
  <div className="account-card">
    <div className="account-card-avatar">{avatar && <img src={avatar} />}</div>
    <div className="account-card-text">
      <div className="header-large">{name}</div>
      <div className="address-row">
        <div className="header-light-big">{address}</div>
        {etherscanLink ? (
          <a href={etherscanLink} rel="noopener noreferrer" target="_blank">
            <OpenLinkIcon />
          </a>
        ) : null}
      </div>
    </div>
    {rightComponent && <div className="account-card-rightComponent">{rightComponent}</div>}
  </div>
);
