import React from 'react';

export const MetricsDataTable: React.FC<any> = ({ children }) => {
  const childrenItems = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }

    return child;
  });

  return (
    <div className="metrics-data-table">
      <div className="metrics-data-table-header">
        <span>User Wallet</span>
        <span>Protocol</span>
        <span className="wide">Pool</span>
        <span>
          Time of Entry
          <br />
          Time of Exit
        </span>
        <span className="text-right">
          LPT Staked
          <br />
          LPT Staked On
        </span>
      </div>
      <div className="metrics-data-table-body">{childrenItems}</div>
    </div>
  );
};
