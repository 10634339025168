import { ContractInterface, ContractFactory, BytesLike, Contract } from 'ethers';
import { storeContract } from '../../ducks/ethers/contracts/actions';
import { store } from '../../ducks/store';
import { useWeb3 } from './useWeb3';

export const deployContract = async (
  contractName: string,
  abi: ContractInterface,
  bytecode: BytesLike,
  args: Array<any>,
): Promise<Contract> => {
  const web3 = useWeb3();
  const signer = web3.signer;

  const factory = new ContractFactory(abi, bytecode, signer);
  const contract = await factory.deploy(...args);

  store.dispatch(storeContract(contractName, contract));

  return contract;
};
