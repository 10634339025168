import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSnackbars } from '../../ducks/snackbar/selectors';
import { Snackbar } from '../../components/Snackbar';

export const Snackbars: FC = () => {
  const snackbars = useSelector(getSnackbars);

  return (
    <React.Fragment>
      {snackbars.map((props, index) => (
        <Snackbar key={index} {...props} />
      ))}
    </React.Fragment>
  );
};
