import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import { BigNumber, Contract } from 'ethers';
import { MULTISIG_DOES_NOT_EXIST } from '../constants/errors';
import { ContractLoader } from '../helpers/ContractLoader';
import { loadContract } from '../helpers/loadContract';
import { sendMultisig } from '../helpers/sendMultisig';

export abstract class AbstractRewardsPoolBase {
  constructor(public loader: ContractLoader, public contract: Contract | null) {}

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async getStakingToken(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.stakingToken();
  }

  async getRewardPerSecond(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.rewardPerSecond(index);
  }

  async getRewardTokens(): Promise<string[]> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const count = await this.contract.getRewardTokensCount();
    const tokens = [];

    for (let i = 0; i < count; i++) {
      tokens.push(await this.contract.rewardsTokens(i));
    }

    return tokens;
  }

  async getStakeLimit(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.stakeLimit();
  }

  async getContractStakeLimit(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.contractStakeLimit();
  }

  async getAvailableBalance(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.getAvailableBalance(index);
  }

  async getStartTimestamp(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.startTimestamp();
  }

  async getEndTimestamp(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.endTimestamp();
  }

  async getTotalStaked(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.totalStaked();
  }

  async transferOwnerShipToMultisig(): Promise<void> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const wallet = loadContract('wallet') as MultiSigWallet;

    if (wallet === undefined) {
      throw new Error(MULTISIG_DOES_NOT_EXIST);
    }

    const transferTx = await this.contract.transferOwnership(wallet.address);
    await transferTx.wait();
  }

  async acceptOwnerShip(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'acceptOwnership', []);
  }

  async isMultisigOwner(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const wallet = loadContract('wallet') as MultiSigWallet;

    if (wallet === undefined) {
      throw new Error(MULTISIG_DOES_NOT_EXIST);
    }

    const owner = await this.contract.owner();
    return owner === wallet.address;
  }

  async getName(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.name();
  }

  async hasStarted(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return this.contract.hasStakingStarted();
  }
}
