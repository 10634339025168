import React from 'react';
import classNames from 'classnames';

export interface StepperProps {
  steps: number;
  currentStep: number;
}

export const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  const stepElements = [...Array(steps)].map((val, id) => {
    return (
      <div className="step-container" key={id}>
        <div className={`step ${currentStep - 1 === id ? 'active' : ''}`}></div>
      </div>
    );
  });
  return (
    <div className="stepper-container">
      <div className="header-small title">Steps:</div>
      <div className="stepper">{stepElements}</div>
    </div>
  );
};
