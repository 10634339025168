import React from 'react';
import { PrivateRoute } from '../../containers/routes/PrivateRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CreateStakingPool } from './NewCampaign';
import { StakingActive } from './Active';
import { StakingExpired } from './Expired';
import { StakingScheduled } from './Scheduled';
import { StakingAwaiting } from './Awaiting';

interface IProps {
  baseUrl: string;
}

export const Staking: React.FC<IProps> = ({ baseUrl }) => {
  return (
    <PrivateRoute path={baseUrl} redirectTo="/register">
      <Switch>
        <Route path={baseUrl + '/new'}>
          <CreateStakingPool />
        </Route>
        <Route path={baseUrl + '/awaiting'}>
          <StakingAwaiting />
        </Route>
        <Route path={baseUrl + '/scheduled'}>
          <StakingScheduled />
        </Route>
        <Route path={baseUrl + '/active'}>
          <StakingActive />
        </Route>
        <Route path={baseUrl + '/expired'}>
          <StakingExpired />
        </Route>
        <Redirect path={baseUrl} to={baseUrl + '/active'} />
      </Switch>
    </PrivateRoute>
  );
};
