import artifacts from './LiquidityMiningCampaignPaymentTier.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../../helpers/ContractLoader';

export class LiquidityMiningCampaignPaymentTier {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'LiquidityMiningCampaignPaymentTier',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deploy(
    stakingToken: string,
    rewardsTokens: Array<string>,
    stakeLimit: BigNumber,
    contractStakeLimit: BigNumber,
    name: string,
    paymentContract: string,
    stakingContract: string,
    tierCampaign: number,
  ): Promise<void> {
    this.contract = await this.loader.deploy([
      stakingToken,
      rewardsTokens,
      stakeLimit,
      contractStakeLimit,
      name,
      paymentContract,
      stakingContract,
      tierCampaign,
    ]);
  }
}
