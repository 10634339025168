import artifacts from './NonCompoundingRewardsPool.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { durationToUnit, DurationUnits, Duration, createDuration } from '../helpers/duration';
import { sendMultisig } from '../helpers/sendMultisig';

export class NonCompoundingRewardsPool {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'NonCompoundingRewardsPoolV2',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deploy(
    stakingToken: string,
    rewardsTokens: Array<string>,
    stakeLimit: BigNumber,
    throttleRoundDuration: Duration,
    throttleRoundCap: BigNumber,
    contractStakeLimit: BigNumber,
    name: string,
  ): Promise<void> {
    const throttleRoundSeconds = durationToUnit(throttleRoundDuration, DurationUnits.seconds);

    this.contract = await this.loader.deploy([
      stakingToken,
      rewardsTokens,
      stakeLimit,
      throttleRoundSeconds,
      throttleRoundCap,
      contractStakeLimit,
      name,
    ]);
  }

  async setReceiverWhitelisted(receiverAddress: string, whitelisted: boolean): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'setReceiverWhitelisted', [receiverAddress, whitelisted]);
  }

  async getThrottleRoundDuration(): Promise<Duration> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const seconds: BigNumber = await this.contract.throttleRoundSeconds();
    return createDuration(seconds.toNumber(), DurationUnits.seconds);
  }

  async getThrottleRoundCap(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.throttleRoundCap();
  }

  async receiversWhitelist(receiver: string): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const whitelisted: boolean = await this.contract.receiversWhitelist(receiver);

    return whitelisted;
  }
}
