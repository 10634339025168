import React, { useEffect, useState } from 'react';
import { CreatePool } from '../../../components/CreatePool';
import { CreatePoolState, OnCreatePoolProps, PoolType } from '../../../utils/helpers';
import { getProjectName } from '../../../ducks/project/selectors';
import { addDecimals } from '../../../sdk/helpers/addDecimals';
import { getNetworkName } from '../../../sdk/helpers/network';
import { getProtocolContract } from '../../../sdk/helpers/getProtocolContract';
import { LiquidityMiningCampaignPayment } from '../../../sdk/staking-v2/LiquidityMiningCampaignPayment';
import { LiquidityMiningCampaignPaymentTier } from '../../../sdk/staking-v2/lmc-features/LiquidityMiningCampaignPaymentTier';
import { networkConfigurations, stakingContractFundrs } from '../../../sdk/constants/networkConfig';
import { RewardsPoolBase } from '../../../sdk/staking-v2/RewardsPoolBase';
import { useHistory } from 'react-router-dom';
import { getTierValue, tierTenant } from '../../../components/CreatePool/getTierOptions';
import { useNetwork } from 'wagmi';
import { useSelector } from 'react-redux';

export const CreateLMPool: React.FC = () => {
  const { chain } = useNetwork();

  const history = useHistory();
  const [state, setState] = useState<CreatePoolState | undefined>(undefined);

  useEffect(() => {
    const s = history.location.state as any;
    if (s) {
      const rewardsPool = new RewardsPoolBase();
      rewardsPool
        .load(s.poolAddress)
        .then(() => {
          s.pool = rewardsPool;
          setState(s);
        })
        .catch(console.error);
    }
  }, [history]);

  const [network, setNetwork] = useState('');
  const [networkId, setNetworkId] = useState('');
  const projectName = useSelector(getProjectName);
  const isTierContract = projectName === tierTenant ? true : false;

  useEffect(() => {
    getNetworkName().then(setNetwork);
    setNetworkId(String(chain?.id));
  }, [chain?.id]);

  const createPool = async ({
    tokenAddress,
    rewards,
    userStakingLimit,
    stakingLimit,
    name,
    protocol,
    tier,
    tokenInformation,
  }: OnCreatePoolProps): Promise<any> => {
    let campaign: LiquidityMiningCampaignPayment | LiquidityMiningCampaignPaymentTier;

    if (!isTierContract) {
      const holdProcess = new LiquidityMiningCampaignPayment();

      await holdProcess.deploy(
        tokenAddress,
        rewards.map((reward) => reward.info.address),
        addDecimals(userStakingLimit.amount, userStakingLimit.decimals),
        addDecimals(stakingLimit.amount, stakingLimit.decimals),
        name,
        networkConfigurations[networkId].PAYMENT_ADDRESS,
      );

      campaign = holdProcess;
    } else {
      const holdProcess = new LiquidityMiningCampaignPaymentTier();

      await holdProcess.deploy(
        tokenAddress,
        rewards.map((reward) => reward.info.address),
        addDecimals(userStakingLimit.amount, userStakingLimit.decimals),
        addDecimals(stakingLimit.amount, stakingLimit.decimals),
        name,
        networkConfigurations[networkId].PAYMENT_ADDRESS,
        stakingContractFundrs[networkId],
        getTierValue(tier) as number,
      );

      campaign = holdProcess;
    }

    if (!campaign.contract) throw new Error('Deploy failed');

    await campaign.contract.deployed();

    const poolTokenContract = await getProtocolContract(protocol, tokenAddress);
    const tokenAddresses = await poolTokenContract.getTokens();

    const info = {
      type: 'createLMCampaign' as const,
      network: network,
      protocol: protocol,
      tokenAddresses: tokenAddresses,
      lpTokenAddress: tokenAddress.toLowerCase(),
      rewardsAddresses: rewards.map((reward) => reward.info),
      tokenInformation: tokenInformation,
    };

    const pool = new RewardsPoolBase();
    await pool.load(campaign.contract.address);

    return { pool, info };
  };

  return (
    <React.Fragment>
      <CreatePool
        title="Create new liquidity mining campaign"
        subTitle="You can create a new liquidity pool and provide liquidity to decentralised exchanges. Define your settings and set a reward."
        tokenTitle="LP token"
        tokenLabel="LP token address"
        showTier={isTierContract}
        onCreatePool={createPool}
        rewardsLimit={7}
        type={PoolType.LIQUIDITY_MINING}
        state={state}
        newForm={!history.location.state}
        showProtocol
      />
    </React.Fragment>
  );
};
