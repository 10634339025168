import { getNetworkConfig } from './network';
import { useWeb3 } from './useWeb3';

export type DurationUnit = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years';

export const DurationUnits: {
  [key in DurationUnit]: DurationUnit;
} = {
  milliseconds: 'milliseconds',
  seconds: 'seconds',
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  years: 'years',
};

export interface Duration {
  unit: DurationUnit;
  value: number;
}

export const MILLISECOND = 0.001;
export const SECOND = 1;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30.5;
export const YEAR = DAY * 365;

export const createDuration = (value: number, unit: DurationUnit): Duration => {
  return {
    unit: unit,
    value: value,
  };
};

export const durationToSeconds = (duration: Duration): number => {
  if (duration.unit === DurationUnits.milliseconds) return duration.value * MILLISECOND;
  if (duration.unit === DurationUnits.seconds) return duration.value;
  if (duration.unit === DurationUnits.minutes) return duration.value * MINUTE;
  if (duration.unit === DurationUnits.hours) return duration.value * HOUR;
  if (duration.unit === DurationUnits.days) return duration.value * DAY;
  if (duration.unit === DurationUnits.weeks) return duration.value * WEEK;
  if (duration.unit === DurationUnits.months) return duration.value * MONTH;
  if (duration.unit === DurationUnits.years) return duration.value * YEAR;
  throw new Error('Invalid duration');
};

export const durationToDays = (duration: Duration): number => {
  const durationInSeconds = durationToSeconds(duration);
  return durationInSeconds / DAY;
};

export const durationToUnit = (duration: Duration, unit: DurationUnit): number => {
  const seconds = durationToSeconds(duration);
  if (unit === DurationUnits.milliseconds) return seconds / MILLISECOND;
  if (unit === DurationUnits.seconds) return seconds;
  if (unit === DurationUnits.minutes) return seconds / MINUTE;
  if (unit === DurationUnits.hours) return seconds / HOUR;
  if (unit === DurationUnits.days) return seconds / DAY;
  if (unit === DurationUnits.weeks) return seconds / WEEK;
  if (unit === DurationUnits.months) return seconds / MONTH;
  if (unit === DurationUnits.years) return seconds / YEAR;
  throw new Error('Invalid duration');
};

export const blocksToDuration = async (blocks: number): Promise<Duration> => {
  const networkConfig = await getNetworkConfig();
  const blocksPerSecond = 1 / networkConfig.BLOCK_TIME;

  return {
    unit: DurationUnits.seconds,
    value: blocks * blocksPerSecond,
  };
};

export const durationToBlocks = async (duration: Duration): Promise<number> => {
  const networkConfig = await getNetworkConfig();
  const blocksPerSecond = 1 / networkConfig.BLOCK_TIME;

  return Math.ceil(durationToSeconds(duration) * blocksPerSecond);
};

export const getFutureBlockNumber = async (
  duration: Duration,
): Promise<{
  currentBlockNumber: number;
  futureBlockNumber: number;
}> => {
  const web3 = useWeb3();

  const currentBlockNumber = await web3.provider.getBlockNumber();
  const blocksToAdvance = await durationToBlocks(duration);
  const futureBlockNumber = Math.round(currentBlockNumber + blocksToAdvance);

  return {
    currentBlockNumber,
    futureBlockNumber,
  };
};

export const blockToDate = async (blockNumber: number): Promise<Date> => {
  const web3 = useWeb3();
  let block;
  try {
    block = await web3.provider.getBlock(blockNumber);
    return new Date(block.timestamp * 1000);
  } catch (e) {
    const networkConfig = await getNetworkConfig();

    const currentBlockNumber = await web3.provider.getBlockNumber();
    const currentBlock = await web3.provider.getBlock(currentBlockNumber);

    return new Date((currentBlock.timestamp + (blockNumber - currentBlockNumber) * networkConfig.BLOCK_TIME) * 1000);
  }
};

export const dateToBlock = async (date: Date): Promise<number> => {
  const web3 = useWeb3();
  const networkId = String((await web3.provider.getNetwork()).chainId);
  const networkConfig = await getNetworkConfig();

  const currentBlockNumber = await web3.provider.getBlockNumber();
  const currentBlock = await web3.provider.getBlock(currentBlockNumber);

  const secondsDifference = date.getTime() / 1000 - currentBlock.timestamp;

  return Math.floor(currentBlockNumber + secondsDifference / networkConfig.BLOCK_TIME);
};
