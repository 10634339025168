import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { providers, Signer } from 'ethers';

export interface Web3ProviderState {
  provider: providers.Provider;
  signer: Signer;
}

const initialState: Web3ProviderState = (null as unknown) as Web3ProviderState;

export const web3Slice = createSlice({
  name: '@@ethers/web3',
  initialState,
  reducers: {
    setWeb3Provider: (_, action: PayloadAction<Web3ProviderState>): Web3ProviderState => action.payload,
    connectWeb3ProviderFail: (): void => undefined,
  },
});
