import artifacts from './RewardsPoolBase.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { sendMultisig } from '../helpers/sendMultisig';
import { AbstractRewardsPoolBase } from './AbstractRewardsPoolBase';

export class RewardsPoolBase extends AbstractRewardsPoolBase {
  constructor() {
    super(
      new ContractLoader({
        name: 'RewardsPoolBaseV2',
        bytecode: artifacts.bytecode,
        abi: artifacts.abi,
      }),
      null,
    );
  }

  async cancel(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'cancel', []);
  }

  async start(startTimestamp: number, endTimestamp: number, rewards: BigNumber[]): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const numSeconds = endTimestamp - startTimestamp;
    const rewardsPerSecond = rewards.map((reward) => reward.div(numSeconds).toString());

    return await sendMultisig(this.contract, 'start', [startTimestamp, endTimestamp, rewardsPerSecond]);
  }

  async withdrawExcessRewards(recipient: string | undefined): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');
    if (recipient === undefined) throw new Error('Trying to use a undefined wallet');

    return await sendMultisig(this.contract, 'withdrawExcessRewards', [recipient]);
  }

  async getExtensionDuration(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.extensionDuration();
  }

  async getExtensionRewardPerSecond(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.extensionRewardPerSecond(index);
  }

  async isDone(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const endTimestamp = await this.contract.endTimestamp();

    return Date.now() / 1000 > endTimestamp;
  }
}
