import React from 'react';
import { PrivateRoute } from '../../containers/routes/PrivateRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CreateInfiniteStakingPool } from './CreateInfiniteStakingPool';
import { AwaitingInfiniteStaking } from './Awaiting';
import { InfiniteStakingActive } from './Active';
import { InfiniteStakingPaused } from './Paused';
import { InfiniteStakingScheduled } from './Scheduled';

interface IProps {
  baseUrl: string;
}

export const InfiniteStaking: React.FC<IProps> = ({ baseUrl }) => {
  return (
    <PrivateRoute path={baseUrl} redirectTo="/register">
      <Switch>
        <Route path={baseUrl + '/new'}>
          <CreateInfiniteStakingPool />
        </Route>
        <Route path={baseUrl + '/awaiting'}>
          <AwaitingInfiniteStaking />
        </Route>
        <Route path={baseUrl + '/active'}>
          <InfiniteStakingActive />
        </Route>
        <Route path={baseUrl + '/scheduled'}>
          <InfiniteStakingScheduled />
        </Route>
        <Route path={baseUrl + '/paused'}>
          <InfiniteStakingPaused />
        </Route>
        <Redirect path={baseUrl} to={baseUrl + '/active'} />
      </Switch>
    </PrivateRoute>
  );
};
