import { loadContract } from '../helpers/loadContract';

import paymentArtifacts from '../payment/payment.json';
import { getNetworkConfig } from '../helpers/network';
import { Contract } from 'ethers';

export const usePayment = async (): Promise<Contract> => {
  const network = await getNetworkConfig();

  if (!network.PAYMENT_ADDRESS) {
    throw new Error('Unsupported network');
  }

  return loadContract('payment', network.PAYMENT_ADDRESS, paymentArtifacts) as Contract;
};
