import { LockScheme } from '../staking-v1/LockScheme';
import { getNetworkConfig } from './network';

export const getSchemeContract = async (address: string): Promise<any> => {
  const networkConfig = await getNetworkConfig();
  const calculatorAddress = networkConfig.CALCULATOR_ADDRESS;

  const schemeContract = new LockScheme(calculatorAddress);

  await schemeContract.load(address);

  return schemeContract.contract;
};
