import artifacts from './RewardsPoolBase.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { sendMultisig } from '../helpers/sendMultisig';
import { useWeb3 } from '../helpers/useWeb3';

export class RewardsPoolBase {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'RewardsPoolBaseV1',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async addLockSchemes(lockSchemaAddresses: Array<string>, rewardPoolAddress: string): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'setLockSchemesToLMC', [lockSchemaAddresses, rewardPoolAddress]);
  }

  async getStartBlock(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.startBlock();
  }

  async getEndBlock(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.endBlock();
  }

  async getTotalStaked(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.totalStaked();
  }

  async getStakingToken(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.stakingToken();
  }

  async getRewardPerBlock(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.rewardPerBlock(index);
  }

  async getRewardTokens(): Promise<string[]> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const count = await this.contract.getRewardTokensCount();
    const tokens = [];

    for (let i = 0; i < count; i++) {
      tokens.push(await this.contract.rewardsTokens(i));
    }

    return tokens;
  }

  async getStakeLimit(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.stakeLimit();
  }

  async getContractStakeLimit(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.contractStakeLimit();
  }

  async isDone(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const web3 = useWeb3();

    const currentBlock = await web3.provider.getBlockNumber();
    const endBlock = await this.contract.endBlock();

    return currentBlock > endBlock;
  }
}
