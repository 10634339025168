import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthState, getShortPublicKey, getAuthAddress } from '../../ducks/auth/selectors';
import { AuthState } from '../../ducks/auth/slice';
import allianceBlockLogo from '../../assets/svg/alliance-block.svg';
import { Button } from '../../components/Button';
import { Icons } from '../../assets/icons';
import { Notification } from '../Notification';
import { Link } from 'react-router-dom';
import {
  connectWeb3ProviderFail,
  connectWeb3ProviderSuccess,
  reconnectWeb3Provider,
  setWeb3Provider,
} from '../../ducks/ethers/web3/actions';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import {
  checkAuthorization,
  connectWallet,
  offAuthorization,
  removeAuthorization,
  revokeAuthorization,
} from '../../ducks/auth/actions';
import { getNetworkConfig } from '../../sdk/helpers/network';
import { getEthMultisig, getProjectName } from '../../ducks/project/selectors';
import { useAccount, useConnect, useNetwork, useProvider, useSigner } from 'wagmi';
import { metamaskConnector } from '../../wagmi';
import { truncateAddress } from '../../utils/address';
import { Signer } from 'ethers';

export const Header: React.FC = () => {
  const { connectAsync, isLoading } = useConnect();
  const { isConnected, address: wallet, isConnecting, status, isDisconnected } = useAccount();

  const dispatch = useDispatch();
  const authState = useSelector(getAuthState);
  const addressFull = useSelector(getAuthAddress);
  const multisigWallet = useSelector(getEthMultisig);
  const projectName = useSelector(getProjectName);

  const { data: signer } = useSigner();
  const provider = useProvider();

  useEffect(() => {
    const connect = (): void => {
      try {
        dispatch(setWeb3Provider({ provider, signer: signer as Signer }));
        dispatch(reconnectWeb3Provider(provider));
        connectWeb3ProviderSuccess();
      } catch (e) {
        console.error(e);

        addSnackbar({
          type: 'error',
          title: 'Web3 connection failed',
          content: 'Unable to connect to web3 provider',
        });
        connectWeb3ProviderFail();
      }
    };

    if (isConnected && provider && signer) {
      connect();
    }
  }, [isConnected, provider, signer]);

  const onConnectWallet = async (): Promise<void> => {
    const { provider } = await connectAsync({ connector: metamaskConnector });

    dispatch(connectWallet(provider));
  };

  useEffect(() => {
    const walletChanged = (): void => {
      dispatch(reconnectWeb3Provider(provider));
      localStorage.removeItem('theme');
      localStorage.removeItem('brand_color');
      localStorage.removeItem('brand_color_hover');
      localStorage.removeItem('brand_color_shadow');
      localStorage.removeItem('brand_element');
      localStorage.removeItem('contact_email');
      localStorage.removeItem('third_party_integrations');
      localStorage.removeItem('social_media');
      localStorage.removeItem('logo_url');
      dispatch(checkAuthorization());
    };

    if (wallet) {
      walletChanged();
    }
  }, [wallet]);

  useEffect(() => {
    if (isDisconnected) {
      dispatch(offAuthorization());
    }
  }, [isDisconnected]);

  useEffect((): void => {
    // Check if user already connected wallet
    dispatch(reconnectWeb3Provider(provider));
  }, []);

  return (
    <header>
      <div className="header-wrapper">
        <Link to="/">
          <div className="header-logo">
            <img src={allianceBlockLogo} />
            <div className="header-title-text">
              <div className="brand-text">AllianceBlock</div>
              <div className="product-text">DeFi Terminal</div>
              {authState === AuthState.AUTHORIZED && projectName !== 'unknown' && (
                <div className="brand-text">
                  Tenant: <strong>{projectName}</strong>
                </div>
              )}
            </div>
          </div>
        </Link>
        <div className="header-controls">
          {/* TODO: move this logic to container and implement functionality */}
          {!!wallet && wallet !== 'unknown' ? (
            <>
              <Notification />
              <div className="header-address">
                <div className="paragraph-regular header-address-text">{truncateAddress(wallet)}</div>
                <div className="header-button connected">
                  <img className="header-wallet-icon" src={Icons.wallet} />
                  {/*<img className="header-arrow-icon" src={Icons.expand_white} />*/}
                </div>
              </div>
            </>
          ) : (
            <div className="header-button header-regular" onClick={onConnectWallet}>
              Connect wallet
            </div>
          )}
          {/*<Menu />*/}
        </div>
      </div>
    </header>
  );
};
