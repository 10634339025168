import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icons } from '../../../assets/icons/index';

export interface SidebarMenuItemProps {
  id: string;
  title: string;
  baseLocation?: string;
  icon: string;
}

/**
 * Primary UI component for user interaction
 */
export const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({ id, baseLocation, title, icon }) => {
  if (baseLocation === '/') {
    baseLocation = '';
  }

  return (
    <NavLink className={'sidebar-menu-item'} to={`${baseLocation}/${id}`}>
      {Icons[icon] && <img width="15" height="15" src={Icons[icon]} alt="" />}
      {title}
      <span style={{ backgroundImage: `url(${Icons[`caret_right`]})` }} />
    </NavLink>
  );
};
