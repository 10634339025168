import { Contract, ContractInterface } from 'ethers';
import { storeContract } from '../../ducks/ethers/contracts/actions';
import { getEthersContract } from '../../ducks/ethers/contracts/selectors';
import { store } from '../../ducks/store';
import { CANT_LOAD_CONTRACT } from '../constants/errors';
import { useWeb3 } from './useWeb3';

export const loadContract = (contractName: string, address?: string, abi?: ContractInterface): Contract => {
  const state = store.getState();
  const web3 = useWeb3();
  const storedContract = getEthersContract(state, { name: contractName + (address || '') });

  if (!storedContract && address === undefined && !abi) {
    throw new Error(CANT_LOAD_CONTRACT);
  }

  const addressString = address as string;
  const abiContractInterface = abi as ContractInterface;
  const contract: Contract = storedContract
    ? storedContract
    : new Contract(addressString, abiContractInterface, web3.signer);

  if (storedContract === undefined) {
    store.dispatch(storeContract(contractName + (address || ''), contract));
  }

  return contract;
};
