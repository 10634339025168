import {
  all,
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import {
  addToProcessing,
  confirmTransaction,
  getTransactionsData,
  hideNotification,
  removeFromProcessing,
  saveHiddenNotifications,
  storeBlocks,
  storeRawTransactions,
} from './actions';
import { getEthersWeb3Provider } from '../ethers/web3/selectors';
import { getMultisigContract, MultisigContract } from '../ethers/multisig/getMultisigContract';
import { getWalletContract } from '../ethers/multisig/selectors';
import { getHiddenNotifications, getTransactionForSaga, TransactionForSaga } from './selectors';
import { getAdmins } from '../admins/selectors';
import { ContractTransaction, providers } from 'ethers';
import { MultisigTransaction } from '../../types/Transaction';
import { getFullTransactionContext, TransactionContext } from '../../utils/decoder';

import { store } from '../store';
import { getAuthAddress } from '../auth/selectors';
import { setPublicKey } from '../auth/actions';

function* fetchTransactionsData(): Generator<
  | PutEffect
  | CallEffect
  | SelectEffect
  | Promise<providers.Log[][]>
  | Promise<MultisigTransaction[]>
  | Promise<TransactionContext[]>
  | Promise<providers.Block[]>
  | Promise<MultisigContract>
  | Promise<number>
  | Promise<providers.Network>,
  void,
  MultiSigWallet &
    providers.Log[][] &
    providers.Block[] &
    MultisigTransaction[] &
    providers.Provider &
    TransactionContext[] &
    MultisigContract &
    number &
    providers.Network
> {
  try {
    const web3ProviderState = yield select(getEthersWeb3Provider);
    if (!web3ProviderState) throw Error('no provider');
    const { provider } = web3ProviderState;
    const contract = yield select(getWalletContract);
    // let { blockNumber: fromBlock } = yield getMultisigContract();
    let fromBlock;
    // Fix because BSC nodes only allow getting logs from last 5000 blocks
    // https://github.com/binance-chain/bsc/issues/113
    const currentBlockNumber = yield provider.getBlockNumber();
    const network = yield provider.getNetwork();
    // if (network.chainId === 56) {
    // fromBlock = currentBlockNumber - 4990;
    // }

    if (network.chainId === 43113 || network.chainId === 43114) {
      fromBlock = currentBlockNumber - 2022;
    } else {
      fromBlock = currentBlockNumber - 3400;
    }

    const logs = yield Promise.all([
      provider.getLogs({ ...contract.filters.TransactionSubmitted(null), fromBlock }),
      provider.getLogs({ ...contract.filters.TransactionConfirmed(null, null), fromBlock }),
      provider.getLogs({ ...contract.filters.TransactionFailed(null), fromBlock }),
      provider.getLogs({ ...contract.filters.TransactionExecuted(null), fromBlock }),
    ]);

    const [submitted, confirmed, failed, executed] = logs.map((log) =>
      log.map((entry) => contract.interface.parseLog(entry).args),
    );

    const transactions: {
      [id: string]: MultisigTransaction;
    } = {};

    const fetchTransaction = async (id: string): Promise<MultisigTransaction> => {
      const transaction = await contract.transactions(id);
      transactions[id] = transaction;
      return transaction;
    };

    const fetchAllTransactions: Promise<MultisigTransaction>[] = [];

    submitted.forEach(({ transactionId, sender }) => {
      fetchAllTransactions.push(fetchTransaction(transactionId));
    });

    yield Promise.all(fetchAllTransactions);

    const transactionContexts: {
      [id: string]: TransactionContext;
    } = {};

    const fetchTransactionContext = async (
      id: string,
      transaction: MultisigTransaction,
    ): Promise<TransactionContext> => {
      const context = await getFullTransactionContext(transaction.data, transaction.destination, provider);
      transactionContexts[id] = context;
      return context;
    };

    const fetchAllTransactionContexts: Promise<TransactionContext>[] = [];

    for (const transactionId of Object.keys(transactions)) {
      const transaction = transactions[transactionId];
      fetchAllTransactionContexts.push(fetchTransactionContext(transactionId, transaction));
    }

    yield Promise.all(fetchAllTransactionContexts);

    const rawTransactions = {
      submitted: submitted
        .filter(({ transactionId, sender }) => transactionContexts[transactionId])
        .map(({ transactionId }, index) => ({
          blockNumber: logs[0][index].blockNumber,
          transactionHash: logs[0][index].transactionHash,
          transactionIndex: logs[0][index].transactionIndex,
          transactionId: transactionId.toNumber(),
          context: transactionContexts[transactionId],
        })),
      confirmed: confirmed
        .filter(({ transactionId, sender }) => transactions[transactionId] && transactionContexts[transactionId])
        .map(({ transactionId, sender }) => ({
          sender,
          transactionId: transactionId.toNumber(),
          txData: transactions[transactionId].data,
          context: transactionContexts[transactionId],
        })),
      failed: failed
        .filter(({ transactionId, sender }) => transactions[transactionId] && transactionContexts[transactionId])
        .map(({ transactionId }) => ({
          transactionId: transactionId.toNumber(),
          txData: transactions[transactionId].data,
          context: transactionContexts[transactionId],
        })),
      executed: executed
        .filter(({ transactionId, sender }) => transactions[transactionId] && transactionContexts[transactionId])
        .map(({ transactionId }) => ({
          transactionId: transactionId.toNumber(),
          txData: transactions[transactionId].data,
          context: transactionContexts[transactionId],
        })),
    };

    yield put(storeRawTransactions(rawTransactions));

    const rawBlocks = yield Promise.all(logs[0].map(({ blockNumber }) => provider.getBlock(blockNumber)));
    const blocks = rawBlocks.reduce(
      (previous, current: providers.Block) => ({
        ...previous,
        [current.number]: { timestamp: current.timestamp * 1000 },
      }),
      {},
    );
    yield put(storeBlocks(blocks));
  } catch (e) {
    console.error(e);
  }
}

const getHiddenNotificationKey = (): string => {
  const publicKey = getAuthAddress(store.getState());
  return `hiddenNotificationsKey_v2_${publicKey}`;
};

function* storeHiddenNotifications(): Generator<SelectEffect, void, number[]> {
  try {
    const notifications = yield select(getHiddenNotifications);
    localStorage.setItem(getHiddenNotificationKey(), JSON.stringify(notifications));
  } catch (e) {
    console.error(e);
  }
}
function* loadHiddenNotifications(): Generator<PutEffect, void, void> {
  try {
    const notifications = localStorage.getItem(getHiddenNotificationKey());
    if (notifications) yield put(saveHiddenNotifications(JSON.parse(notifications)));
  } catch (e) {
    console.error(e);
  }
}

function* confirmTransactionAndExecute(
  action: ReturnType<typeof confirmTransaction>,
): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  TransactionForSaga & string[] & MultiSigWallet & ContractTransaction
> {
  try {
    const { transaction, votes, voted } = yield select(getTransactionForSaga, { id: action.payload });
    const admins = yield select(getAdmins);
    if (!transaction) throw Error('Transaction not found!');

    const requiredApproval = Math.floor(admins.length / 2 + 1);
    const contract = (yield select(getWalletContract)) as MultiSigWallet;

    yield put(addToProcessing(action.payload));

    const tx = yield call(() => {
      if (!voted && votes.length >= requiredApproval - 1)
        return contract.confirmAndExecuteTransaction(action.payload, {
          gasLimit: 4000000,
        });
      if (voted && votes.length >= requiredApproval)
        return contract.executeTransaction(action.payload, {
          gasLimit: 4000000,
        });
      return contract.confirmTransaction(action.payload);
    });
    yield call(tx.wait);

    yield delay(5000);
    yield put(removeFromProcessing(action.payload));
  } catch (e) {
    console.error(e);
  }
}

export function* transactionsSagaWatcher(): Generator {
  yield all([
    takeLatest(getTransactionsData, fetchTransactionsData),
    takeEvery(hideNotification, storeHiddenNotifications),
    takeLatest(setPublicKey, loadHiddenNotifications),
    takeEvery(confirmTransaction, confirmTransactionAndExecute),
  ]);
}
