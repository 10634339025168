import React, { ReactChild, useState } from 'react';
import cls from 'classnames';

interface ITabViewProps {
  tabs: {
    name: string;
    component: ReactChild;
  }[];
}

export const TabView: React.FC<ITabViewProps> = ({ tabs }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const onTabClick = (id: number) => (): void => setTabIndex(id);

  return (
    <div className="tabs-view-container">
      <div className="tabs-navigation">
        {tabs.map(({ name }, index) => (
          <div key={name} className={cls('tab', { active: index === tabIndex })} onClick={onTabClick(index)}>
            {name}
          </div>
        ))}
      </div>
      <div className={cls('tabs-content', { 'tabs-content-left-radius': tabIndex != 0 })}>
        {tabs[tabIndex].component}
      </div>
    </div>
  );
};
