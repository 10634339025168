import React from 'react';
import { Icons } from '../../assets/icons';
import cls from 'classnames';

export interface CoinChainProps {
  data: {
    icon: string;
    name: string;
  }[];
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const CoinChain: React.FC<CoinChainProps> = ({ data, ...props }) => {
  return (
    <div className="coin-chain" {...props}>
      {data.map((props, index) => (
        <>
          <img src={Icons[`line_arrow`]} alt={''} />
          <span key={index}>
            <img src={props.icon} alt={''} />
            <strong>{props.name}</strong>
          </span>
        </>
      ))}
    </div>
  );
};
