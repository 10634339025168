import React from 'react';
import { Icons } from '../../assets/icons';

interface IProps {
  count: number;
  onClick?: () => void;
}

export const Chip: React.FC<IProps> = ({ count, onClick }) => (
  <div className="chip" onClick={onClick}>
    <img src={Icons.red_bell} />
    <span className="chip-text header-light-small">Pending</span>
    <div className="chip-badge paragraph-smallest">
      <span>{count}</span>
    </div>
  </div>
);
