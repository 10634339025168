import artifacts from './LiquidityMiningCampaignPayment.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';

export class LiquidityMiningCampaignPayment {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'LiquidityMiningCampaignPayment',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deploy(
    stakingToken: string,
    rewardsTokens: Array<string>,
    stakeLimit: BigNumber,
    contractStakeLimit: BigNumber,
    name: string,
    paymentContract: string,
  ): Promise<void> {
    this.contract = await this.loader.deploy([
      stakingToken,
      rewardsTokens,
      stakeLimit,
      contractStakeLimit,
      name,
      paymentContract,
    ]);
  }
}
