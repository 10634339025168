import React from 'react';
import { TableHeader, TransactionTableSort, TransactionTableSortDirection } from './TableHeader';
import { ITableItemProps, TableRow } from './TableRow';

interface IProps {
  onApproveClick: (id: string) => void;
  rows: Omit<ITableItemProps, 'onApproveClick'>[];
  sort: TransactionTableSort;
  direction: TransactionTableSortDirection;
  onSortClick: (id: TransactionTableSort) => void;
}

const getSortedStrings = (stringA: string, stringB: string, direction: 'asc' | 'desc'): number => {
  if (direction === 'asc') {
    return stringA > stringB ? 1 : -1;
  } else {
    return stringA < stringB ? 1 : -1;
  }
};

const getSortedNumbers = (numberA: number, numberB: number, direction: 'asc' | 'desc'): number => {
  return direction === 'asc' ? numberA - numberB : numberB - numberA;
};

const getSortedDates = (dateA: Date, dateB: Date, direction: 'asc' | 'desc'): number => {
  if (direction === 'asc') {
    return dateA > dateB ? 1 : -1;
  } else {
    return dateB > dateA ? 1 : -1;
  }
};

export const TransactionsTable: React.FC<IProps> = ({ rows, onApproveClick, ...sort }) => {
  const sortTransactions = (
    a: Omit<ITableItemProps, 'onApproveClick'>,
    b: Omit<ITableItemProps, 'onApproveClick'>,
  ): number => {
    const direction: 'asc' | 'desc' = sort.direction;

    switch (sort.sort) {
      case 'id':
        return getSortedNumbers(parseInt(a[sort.sort]), parseInt(b[sort.sort]), direction);
      case 'type':
        return getSortedStrings(a[sort.sort], b[sort.sort], direction);
      case 'date':
        return getSortedDates(new Date(a[sort.sort]), new Date(b[sort.sort]), direction);
      default:
        return -1;
    }
  };
  return (
    <div className="table table-text">
      <TableHeader {...sort} />
      <div className="table-container">
        {rows
          .sort((elementA, elementB) => sortTransactions(elementA, elementB))
          .map((props) => (
            <TableRow key={props.id} onApproveClick={onApproveClick} {...props} />
          ))}
      </div>
    </div>
  );
};
