import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StakingTitle } from '../../../components/StakingTitle';
import { Form } from './FormInput';

export const Tokens: React.FC = () => {
  const history = useHistory();
  const state: any = history.location.state;

  useEffect(() => {
    if (!state) history.push('/configuration/brand');
  }, [state]);

  const onSubmit = (data: any): void => {
    history.push('/configuration/contact_email', state);
  };

  const goBack = (): void => {
    history.push('/configuration/dapps_integration', { ...state });
  };

  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Tokens"
          message="Please provide your token addresses."
          steps={8}
          currentStep={4}
        />
        <Form onSubmit={onSubmit} goBack={goBack} savedField={state?.contact_email} />
      </div>
    </>
  );
};
