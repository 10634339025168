import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/Button';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInputField } from '../../../components/TokenInputField';
import { getTokenInformation, TokenInformation } from '../../../sdk/helpers/getTokenInformation';
import axios from 'axios';
import { getEthersWeb3Provider } from '../../../ducks/ethers/web3/selectors';
import { utils } from 'ethers';
import { getSignature } from '../../../utils/getSignature';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { getNetworkIdByName } from '../../../sdk/helpers/network';

export interface Props {
  onSubmit: (data: any) => void;
  goBack: () => void;
  savedField?: 'string';
}

export const Form: React.FC<Props> = ({ onSubmit, goBack, savedField }): JSX.Element => {
  const { address: wallet } = useAccount();
  const { chain } = useNetwork();
  const provider = useProvider();

  const web3 = useSelector(getEthersWeb3Provider);
  const config = useSelector(getProjectConfigAll);
  const dispatch = useDispatch();

  const [tokenInformation, setTokenInformation] = useState<
    {
      title: string;
      network: string;
      rpcUrl: string;
      info: TokenInformation;
    }[]
  >([
    {
      title: 'ETH',
      network: 'eth',
      rpcUrl: process.env.REACT_APP_ETH_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
    {
      title: 'BSC',
      network: 'bsc',
      rpcUrl: process.env.REACT_APP_BSC_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
    {
      title: 'Polygon',
      network: 'polygon',
      rpcUrl: process.env.REACT_APP_POL_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
    {
      title: 'Avalanche',
      network: 'avalanche',
      rpcUrl: process.env.REACT_APP_AVA_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
    {
      title: 'Ewc',
      network: 'ewc',
      rpcUrl: process.env.REACT_APP_EWC_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
    {
      title: 'Moonbeam',
      network: 'moonbeam',
      rpcUrl: process.env.REACT_APP_MOONBEAM_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
    {
      title: 'SongBird',
      network: 'songbird',
      rpcUrl: process.env.REACT_APP_SONGBIRD_RPC as string,
      info: {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      },
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [tokenLoading, setTokenLoading] = useState(false);

  useEffect(() => {
    if (!config) return;

    setLoading(true);

    const promises = [];
    console.log(config.tokens);
    if ('eth' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('eth')),
            tokenAddress: config.tokens.eth.address,
            provider,
          });
          info.symbol = config.tokens.eth.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 0) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    if ('bsc' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('bsc')),
            tokenAddress: config.tokens.bsc.address,
            provider,
          });
          info.symbol = config.tokens.bsc.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 1) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    if ('polygon' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('polygon')),
            tokenAddress: config.tokens.polygon.address,
            provider,
          });
          info.symbol = config.tokens.polygon.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 2) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    if ('avalanche' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('avalanche')),
            tokenAddress: config.tokens.avalanche.address,
            provider,
          });
          info.symbol = config.tokens.avalanche.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 3) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    if ('ewc' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('ewc')),
            tokenAddress: config.tokens.ewc.address,
            provider,
          });
          info.symbol = config.tokens.ewc.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 4) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    if ('moonbeam' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('moonbeam')),
            tokenAddress: config.tokens.moonbeam.address,
            provider,
          });
          info.symbol = config.tokens.moonbeam.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 5) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    if ('songbird' in config.tokens) {
      promises.push(
        (async (): Promise<void> => {
          const info = await getTokenInformation({
            chainId: Number(getNetworkIdByName('songbird')),
            tokenAddress: config.tokens.songbird.address,
            provider,
          });
          info.symbol = config.tokens.songbird.symbol;
          setTokenInformation(
            tokenInformation.map((t, i) => {
              if (i === 6) {
                t.info = info;
              }
              return t;
            }),
          );
        })(),
      );
    }

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, [config]);

  const handleOnSubmit = async (): Promise<void> => {
    const filteredProject = tokenInformation.filter((project) => project.info.coinGeckoID.length > 0);

    if (!filteredProject[0] || !filteredProject[0].info || !filteredProject[0].info.coinGeckoID) {
      onSubmit({});
      return;
    }

    const coinGeckoId = filteredProject[0].info.coinGeckoID;

    for (const info of tokenInformation) {
      if (!utils.isAddress(info.info.address) || !info.info.symbol || !coinGeckoId) continue;

      const { signature, timestamp } = await getSignature(web3, dispatch);

      axios.post(process.env.REACT_APP_API + '/project/add-network', {
        name: info.info.name,
        decimals: info.info.decimals,
        tokenAddress: info.info.address,
        tokenSymbol: info.info.symbol,
        coinGeckoID: coinGeckoId,
        network: info.network,
        wallet,
        signature: signature,
        timestamp,
      });
    }

    onSubmit({});
  };

  const BackFunc = (): void => {
    goBack();
  };

  return (
    <>
      <div className="text-field-container">
        {!loading &&
          tokenInformation.map((token, index) => (
            <TokenInputField
              id="token"
              name="token"
              key={index}
              value={token.info.address}
              rpcUrl={token.rpcUrl}
              onChange={(e): void => {
                setTokenLoading(true);
                setTokenInformation(
                  tokenInformation.map((t, i) => {
                    if (i === index) t.info.address = e.target.value;
                    return t;
                  }),
                );
                getTokenInformation({ chainId: chain?.id as number, tokenAddress: e.target.value, provider })
                  .then((info) => {
                    setTokenInformation(
                      tokenInformation.map((t, i) => {
                        if (i === index) t.info = info;
                        return t;
                      }),
                    );
                  })
                  .then(() => setTokenLoading(false));
              }}
              label={token.title}
              required={true}
              icon="discord_icon_input"
              type="text"
              fileInputName="basic_setup_token_image"
              onChangeFile={(): void => {
                return;
              }}
              fileValue={''}
              hideAddIcon={true}
            />
          ))}

        {loading && <div>Loading...</div>}
      </div>
      <div className="button-container">
        <Button
          color="tertiary"
          iconposition="left"
          icon="arrow_left"
          label="Go back"
          onClick={BackFunc}
          size="large"
        />
        <Button
          data-cy="configuration-token-next"
          color="primary"
          label="Next"
          icon="arrow_right"
          onClick={handleOnSubmit}
          size="large"
          iconposition="right"
          disabled={tokenLoading || loading}
        />
      </div>
    </>
  );
};
