import React from 'react';
import { ListItemUser } from '../List/ListItemUser';
import { Badge } from '../Badge';
import { Icons } from '../../assets/icons';

export interface IAdmins {
  name?: string;
  avatar?: string;
  address: string;
  email?: string;
}

export type NotificationTransaction =
  | string
  | {
      value: number;
      coin: string;
      icon?: string;
    };

interface IProps {
  sendingTo: {
    name?: string;
    avatar?: string;
    address: string;
    email?: string;
  };
  transaction: NotificationTransaction;
  confirmed: IAdmins[];
  awaiting: IAdmins[];
}

const truncateAddress = (address: string): string => {
  return `${address.substring(0, 10)} ... ${address.substring(37, address.length - 1)}`;
};

export const NotificationTransaction: React.FC<IProps> = ({ sendingTo, transaction, confirmed, awaiting }) => (
  <div className="transaction-container">
    <h6>SENDING TO:</h6>
    <div className="sending-to">
      {sendingTo && (
        <ListItemUser name={sendingTo.name} address={sendingTo.address} email={sendingTo.email} length={12} compact />
      )}
      <h5>
        {!transaction ? (
          'unknown'
        ) : typeof transaction === 'string' ? (
          transaction
        ) : (
          <>
            {transaction.value}
            {transaction.icon ? <img src={transaction.icon} /> : ' '}
            {transaction.coin}
          </>
        )}
      </h5>
    </div>
    <h6>CONFIRMED</h6>
    {confirmed &&
      confirmed.map(({ name = 'unknown', address, avatar }) => (
        <div key={address} className="transaction-user">
          <div className="header-big transaction-user-title">
            {avatar && <img className="avatar" src={avatar} />}
            {name}
          </div>
          <div className="header-light-big">
            {address && truncateAddress(address)}
            <img src={Icons.copy_light} />
            <img src={Icons.open_link_light} />
          </div>
        </div>
      ))}
    <h6 className="awaiting-info">{awaiting.length} MORE AWAITING</h6>
    {awaiting &&
      awaiting.map(({ name = 'unknown', address, avatar }) => (
        <div key={address} className="transaction-user">
          <div className="header-big transaction-user-title">
            {avatar && <img className="avatar" src={avatar} />}
            {name}
          </div>
          <div>
            <Badge type="awaiting" />
          </div>
        </div>
      ))}
  </div>
);
