import theme_dark_blue from './theme-dark-blue.svg';
import theme_dark_red from './theme-dark-red.svg';
import theme_dark from './theme-dark.svg';
import theme_light from './theme-light.svg';
import theme_check_blue from './theme-check-blue.svg';

export interface IconContainer {
  [key: string]: any;
}

export const Svg: IconContainer = {
  theme_dark_blue,
  theme_dark_red,
  theme_dark,
  theme_light,
  theme_check_blue,
};
