import { web3Slice } from './slice';
import { createAction } from '@reduxjs/toolkit';
import { WagmiConnectionResult } from './sagas';

// re-export actions from slice
export const { setWeb3Provider, connectWeb3ProviderFail } = web3Slice.actions;

// custom actions for sagas
export const reconnectWeb3Provider = createAction<WagmiConnectionResult['provider']>('@@ethers/web3/reconnect');
export const connectWeb3Provider = createAction<WagmiConnectionResult['provider']>('@@ethers/web3/connect');

export const connectWeb3ProviderSuccess = createAction('@@ethers/web3/connect_success');

export const getWeb3UserInfoInConsole = createAction('@@ethers/web3/user_info_in_console');
