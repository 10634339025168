import { createAction } from '@reduxjs/toolkit';
import { transactionsSlice } from './slice';

export const {
  storeRawTransactions,
  storeBlocks,
  hideNotification,
  saveHiddenNotifications,
  addToProcessing,
  removeFromProcessing,
} = transactionsSlice.actions;

export const getTransactionsData = createAction('transactions/get-transactions-data');

export const confirmTransaction = createAction<number>('transactions/confirm-transaction');
