import { ERC20ABI } from './erc20abi';
import { Contract, ethers, providers } from 'ethers';

const FUNCTION_INTERFACES: { [signature: string]: string } = Object.freeze({
  '0febf479': 'Liquidity Mining Campaign v1_Deploy',
  d093410b: 'Liquidity Mining Campaign v1_Set Lock Schemes',
  '986c1d0e': 'Liquidity Mining Campaign v1_Extend',
  '6c32bf69': 'Liquidity Mining Campaign v2_Extend',
  '54d6424d': 'Staking Campaign v1_Deploy',
  '0d50caf2': 'Staking Campaign v1_Whitelist',
  a861a7a3: 'Staking Campaign v2_Whitelist',
  '4e71d92d': 'Staking Campaign v1_Claim',
  b01eb660: 'Staking Campaign v1_Complete Exit',
  e9fad8ee: 'Staking Campaign v1_Exit',
  '2240e63c': 'Staking Campaign v1_Exit and Transfer',
  '2e1a7d4d': 'Staking Campaign v1_Withdraw',
  '869d8ead': 'Campaign v2_Start',
  e2b2056c: 'Treasury_Withdraw Liquidity',
  '2491b5f5': 'Treasury_Return Liquidity',
  '658e96a3': 'Treasury_Add Uniswap Liquidity',
  '364c9074': 'Treasury_Remove Uniswap Liquidity',
  '095ea7b3': 'ERC20_Approve',
  a9059cbb: 'ERC20_Transfer',
  '23b872dd': 'ERC20_Transfer From',
  eb12d61e: 'Multisig_Add Signer',
  '0e316ab7': 'Multisig_Remove Signer',
  '79ba5097': 'Contract Deployment_Accept Ownership',
  ea8a1af0: 'Campaign v2_Cancel',
  '2c3f455c': 'Staking Campaign v2_Withdraw excess rewards',
  bbda69c2: 'Campaign Cancel_Cancel & Refund',
  '3442a20e': 'Campaign Extend Cancel_Cancel Extension & Refund',
  f03a269b: 'Campaign Start_Start & Use Credit',
  '17c582dc': 'Campaign Extend Start_Start Extension & Use Credit',
  '95805dad': 'Campaign Start_Start Infinite Staking Campaign',
  '8fb4b573': 'Infinite Staking Campaign v1_Start pool',
});

export interface TransactionContext {
  actionName: string;
  amount: string;
  isErc20: boolean;
  assetName?: string;
  contractName?: string;
}

export const getTokenSymbol = async (tokenAddress: string, provider: providers.Provider): Promise<string> => {
  const contract = new Contract(tokenAddress, ERC20ABI, provider);
  try {
    const symbol = await contract.symbol();
    if (symbol) {
      return symbol;
    } else {
      return 'Unknown ERC20';
    }
  } catch {
    return 'Unknown ERC20';
  }
};

export const getFullTransactionContext = async (
  txData: string,
  destinationAddress: string,
  provider: providers.Provider,
): Promise<TransactionContext> => {
  const txContext = getTransactionContext(txData);
  let assetName = undefined;
  if (txContext.isErc20) {
    assetName = await getTokenSymbol(destinationAddress, provider);
  }

  return {
    ...txContext,
    assetName,
  };
};

const getTransactionContext = (txData: string): TransactionContext => {
  const fragment = txData.slice(2, 10);
  if (fragment in FUNCTION_INTERFACES) {
    const contractName = FUNCTION_INTERFACES[fragment].split('_')[0];
    if (contractName === 'ERC20') {
      // Parse token transfer for amount
      const functionName = FUNCTION_INTERFACES[fragment].split('_')[1];
      const contractInterface = new ethers.utils.Interface(ERC20ABI);
      try {
        const result = contractInterface.decodeFunctionData('0x' + fragment, txData);
        return {
          actionName: functionName,
          amount: ethers.utils.formatEther(result['_value']).substr(0, 6),
          isErc20: true,
        };
      } catch (e) {
        console.error(`Error while trying to decode fragment ${fragment}: ${e}`);
        return {
          actionName: `Unknown ${functionName}`,
          amount: 'N/A',
          isErc20: true,
        };
      }
    }

    return {
      contractName: FUNCTION_INTERFACES[fragment].split('_')[0],
      actionName: FUNCTION_INTERFACES[fragment].split('_')[1],
      amount: '',
      isErc20: false,
    };
  }

  console.warn('Unknown function fragment: ', fragment);
  return {
    actionName: 'Unknown',
    amount: 'Unknown',
    isErc20: false,
  };
};
