import React, { useEffect, useState } from 'react';
import { Chip } from '../../components/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationCount, getNotificationProcessing, getNotifications } from '../../ducks/transactions/selectors';
import { Notification as NotificationComponent } from '../../components/Notification';
import cls from 'classnames';
import { confirmTransaction, hideNotification } from '../../ducks/transactions/actions';

export const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(getNotificationCount);

  const [visible, setVisible] = useState(false);
  const toggleVisibility = (): void => {
    setVisible(!visible);
  };
  useEffect(() => {
    if (notificationsCount === 0) setVisible(false);
  }, [notificationsCount]);
  const [page, setPage] = useState(1);
  const handleRightClick = (): void => {
    if (page !== notificationsCount) setPage(page + 1);
  };
  const handleLeftClick = (): void => {
    if (page !== 0) setPage(page - 1);
  };

  const notifications = useSelector(getNotifications);
  const transactionId = notifications[page - 1]?.transactionId || 0;

  const handleConfirmClick = (): void => {
    dispatch(confirmTransaction(transactionId));
  };
  const handleDeclineClick = (): void => {
    dispatch(hideNotification(transactionId));
  };

  const processing = useSelector(getNotificationProcessing);

  if (notificationsCount === 0) return null;
  return (
    <div data-cy="notification-dropdown-container" className="notification-dropdown-container">
      <Chip count={notificationsCount} onClick={toggleVisibility} />
      <div className={cls('notification-dropdown-container-content', { visible })}>
        <NotificationComponent
          {...notifications[page - 1]}
          processing={processing.some((id) => id === transactionId)}
          currentPage={page}
          notifications={notificationsCount}
          onRightArrowClick={handleRightClick}
          onLeftArrowClick={handleLeftClick}
          onConfirmClick={handleConfirmClick}
          onDeclineClick={handleDeclineClick}
          onChipClick={toggleVisibility}
        />
      </div>
    </div>
  );
};
