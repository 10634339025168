import { Dispatch } from 'redux';
import { Web3ProviderState } from '../ducks/ethers/web3/slice';
import { addSnackbar } from '../ducks/snackbar/action';

function sleep(time: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export async function getSignature(
  web3: Web3ProviderState,
  dispatch: Dispatch,
): Promise<{ signature: string; timestamp: number }> {
  if (!web3?.signer)
    throw new Error('No web3 wallet found', {
      cause: new Error('No web3 wallet attached to the browser. Please install a web3 wallet like MetaMask.'),
    });

  for (let retries = 0; retries < 5; retries++) {
    try {
      const timestamp = Date.now();
      const signature = await web3?.signer.signMessage(`Verification message: ${timestamp.toString()}`);

      if (Date.now() > timestamp + 120 * 1000) {
        dispatch(
          addSnackbar({
            type: 'error',
            title: 'Signature expired',
            content:
              'The signature is expired because you waited longer then 2 minutes to sign it. Retrying automatically in 5 seconds, please wait...',
          }),
        );
        await sleep(5000);
      } else if (signature) {
        return { signature, timestamp };
      }
    } catch (e: any) {
      if (e.code) {
        switch (e.code) {
          case 4001:
            dispatch(
              addSnackbar({
                type: 'error',
                title: 'Signature rejected',
                content: 'You rejected the signature request. Please sign the message to continue.',
              }),
            );
            break;
          case 4100:
            throw new Error('No web3 wallet found', {
              cause: new Error(`Can't request account to sign. Please check if the wallet is unlocked and connected.`),
            });
        }
      }
    }
  }

  throw new Error('Failed to sign message');
}
