import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from './FormInput';
import { StakingTitle } from '../../../components/StakingTitle';

export const Brand: React.FC = () => {
  const history = useHistory();
  const state: any = history.location.state;

  const onSubmit = (data: any): void => {
    const newData = { ...state, brand: data };
    history.push('/configuration/theme', newData);
  };

  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Brand"
          message="Please upload you brand logo. Logo should be in SVG or transparent PNG format (500px x 500px maximum)."
          steps={9}
          currentStep={1}
        />
        <Form onSubmit={onSubmit} savedFile={state?.brand} />
      </div>
    </>
  );
};
