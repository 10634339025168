import axios from 'axios';

export enum CoingeckoCurrencies {
  USD = 'usd',
}

export interface CoingeckoTokenInfo {
  id: string;
  symbol: string;
  name: string;
  asset_platform_id: string;
  image: {
    thumb: string;
    small: string;
    large: string;
  };
  detail_platforms: { [asset_platform_id: string]: { decimal_place: number; contract_address: string } };
  contract_address?: string;
  error?: string;
}

interface CachedResponse {
  data: any;
  date: Date;
}

const cacheMap: { [key: string]: CachedResponse } = {};

export const coingeckoFetch = async (path: string): Promise<any> => {
  const { REACT_APP_COINGECKO_API, REACT_APP_COINGECKO_API_FALLBACK } = process.env;

  const cachedResponse = cacheMap[path];
  if (cachedResponse && cachedResponse.date.getTime() + 900_000 > Date.now()) {
    return cachedResponse.data;
  }
  try {
    const data = await axios.get(REACT_APP_COINGECKO_API + path).then((response) => response.data);

    cacheMap[path] = {
      data,
      date: new Date(),
    };

    return data;
  } catch (err) {
    if (REACT_APP_COINGECKO_API_FALLBACK) {
      try {
        const data = await axios.get(REACT_APP_COINGECKO_API_FALLBACK + path).then((response) => response.data);

        cacheMap[path] = {
          data,
          date: new Date(),
        };

        return data;
      } catch (err) {
        console.error('fallback', err, 'path', REACT_APP_COINGECKO_API_FALLBACK + path);
      }
    }

    console.error(err, 'path', REACT_APP_COINGECKO_API + path);

    if (!cachedResponse?.data) {
      console.error('No cached response found for coingecko path', path);
    }
    return cachedResponse?.data;
  }
};

export const getCoingeckoCoinList = async (): Promise<any> => coingeckoFetch('/coins/list');

export const getCoingeckoTokenPrice = async (tokenId: string, currency: CoingeckoCurrencies): Promise<number> => {
  const data = await coingeckoFetch(`/simple/price?ids=${tokenId}&vs_currencies=${currency}`);
  return data[tokenId][currency];
};

export const getCoingeckoTokenInfo = async (tokenId: string): Promise<CoingeckoTokenInfo> =>
  coingeckoFetch(`coins/${tokenId}`);

export const getCoingeckoTokenInfoByTokenAddress = async (
  network: string,
  tokenAddress: string,
): Promise<CoingeckoTokenInfo> => coingeckoFetch(`/coins/${network}/contract/${tokenAddress}`);
