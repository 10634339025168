import axios, { AxiosResponse } from 'axios';
import { GetPrivateDataDto } from '../types/dto/PrivateData.dto';

export const TIMEOUT = 5 * 1000;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: TIMEOUT,
});

export const getPrivateData = (id: number): Promise<AxiosResponse<GetPrivateDataDto>> => instance.get(`blah/${id}`);
