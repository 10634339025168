import React from 'react';
import { StakingPoolList } from '../../../components/StakingPoolList';

export const StakingScheduled: React.FC = () => {
  return (
    <StakingPoolList
      title="Scheduled pools"
      subTitle="List of scheduled pools (new pools can take a few minutes to show up here)"
      filter={(pool: any): boolean => {
        return pool.start > new Date();
      }}
    />
  );
};
