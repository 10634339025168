import { BigNumber, utils } from 'ethers';
import { WeiAmount } from '../../components/WeiInput';
import { AbstractRewardsPoolBase } from '../staking-v2/AbstractRewardsPoolBase';
import { RewardsPoolBase } from '../staking-v2/RewardsPoolBase';
import { addDecimals } from './addDecimals';
import { TokenInformation } from './getTokenInformation';
import { loadERC20 } from './loadERC20';
import { DispatchProp } from 'react-redux';
import { addSnackbar } from '../../ducks/snackbar/action';
import { Dispatch } from 'react';

export const checkRewardBalanceV1 = async (
  address: string,
  rewards: { info: TokenInformation; reward: WeiAmount }[],
): Promise<string> => {
  try {
    for (const reward of rewards) {
      if (!utils.isAddress(reward.info.address)) continue;

      const rewardAmount = addDecimals(reward.reward.amount || '0', reward.reward.decimals);

      const rewardContract = loadERC20(reward.info.address);

      const balance = await rewardContract.balanceOf(address);
      if (BigNumber.from(balance).lt(rewardAmount)) {
        return (
          utils.formatUnits(BigNumber.from(rewardAmount).sub(balance), reward.reward.decimals) +
          ' ' +
          (await rewardContract.getSymbol())
        );
      }
    }

    return '';
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const checkRewardBalanceV2 = async (
  dispatch: Dispatch<any>,
  pool: AbstractRewardsPoolBase,
  rewards: { info: { address: string }; reward: WeiAmount }[],
): Promise<{ needed: string; sendRewards: () => Promise<void> }> => {
  try {
    for (let i = 0; i < rewards.length; i++) {
      const reward = rewards[i];
      if (!utils.isAddress(reward.info.address)) continue;

      const balance = await pool.getAvailableBalance(i);
      const rewardAmount = addDecimals(reward.reward.amount || '0', reward.reward.decimals);

      if (BigNumber.from(balance).lt(rewardAmount)) {
        const rewardContract = loadERC20(reward.info.address);

        const rewardsNeeded = BigNumber.from(rewardAmount).sub(balance);
        return {
          needed: utils.formatUnits(rewardsNeeded, reward.reward.decimals) + ' ' + (await rewardContract.getSymbol()),
          sendRewards: async (): Promise<void> => {
            try {
              await (await rewardContract.transfer(pool.contract?.address as string, rewardsNeeded)).wait();
            } catch {
              dispatch(
                addSnackbar({
                  type: 'error',
                  title: 'Error',
                  content: `Unable to transfer tokens`,
                }),
              );
            }
          },
        };
      }
    }

    return {
      needed: '',
      sendRewards: async (): Promise<void> => {
        return;
      },
    };
  } catch (error) {
    console.log(error);
    return {
      needed: '',
      sendRewards: async (): Promise<void> => {
        return;
      },
    };
  }
};
