import { BigNumber, ContractTransaction, ethers } from 'ethers';
import { loadERC20 } from '../helpers/loadERC20';
import { usePayment } from '../helpers/usePayment';
import { getAddress } from '../helpers/getAddress';
import { getNetworkConfig } from '../helpers/network';
import { Duration, durationToUnit, DurationUnits } from '../helpers/duration';
import { useWeb3 } from '../helpers/useWeb3';
import { sendMultisig } from '../helpers/sendMultisig';
import paymentArtifacts from './payment.json';
import paymentArtifactsOld from './paymentOld.json';
import { accuracy, PoolVersion } from '../../utils/helpers';

export const approve = async (pairAddress: string, requiredLp: BigNumber): Promise<ContractTransaction> => {
  const payment = await usePayment();
  const token = loadERC20(pairAddress);

  return await token.approve(payment.address, requiredLp);
};

export const pay = async (userToken: string, days: number, wrapped: boolean): Promise<ContractTransaction> => {
  const payment = await usePayment();

  return days == 0 ? payment.payExtension(userToken, wrapped) : payment.pay(userToken, days, wrapped);
};

export const getCreditsCampaignsByDay = async (userToken: string, days: number): Promise<BigNumber> => {
  const payment = await getPayment();

  if (days == 0) {
    return payment.creditsCampaignExtension(userToken);
  } else {
    const type = days <= 34 ? 0 : days <= 178 ? 1 : 2;

    return payment.creditsCampaigns(userToken, type);
  }
};

export const getCreditsCampaignsByType = async (userToken: string, type: number): Promise<number> => {
  const payment = await getPayment();

  return type == 3 ? payment.creditsCampaignExtension(userToken) : payment.creditsCampaigns(userToken, type);
};

export const paymentUseCreditExtension = async (
  duration: Duration,
  rewards: BigNumber[],
  poolAddress: string,
  addAccuracy = false,
): Promise<string> => {
  const payment = await usePayment();

  const numSeconds = durationToUnit(duration, DurationUnits.seconds);
  const accuracy = addAccuracy ? ethers.utils.parseEther('1') : 1;
  const rewardsPerSecond = rewards.map((reward) => reward.mul(accuracy).div(numSeconds).toString());

  return await sendMultisig(payment, 'useCreditExtension', [numSeconds, rewardsPerSecond, poolAddress]);
};

export const paymentUseCredit = async (
  startTimestamp: number,
  endTimestamp: number,
  rewards: BigNumber[],
  poolAddress: string,
  version: PoolVersion,
): Promise<string> => {
  const payment = await usePayment();

  const numSeconds = endTimestamp - startTimestamp;
  const rewardsPerSecond = rewards.map((reward) =>
    reward
      .mul(version === '4.0' ? accuracy : 1)
      .div(numSeconds)
      .toString(),
  );

  return await sendMultisig(payment, 'useCredit', [startTimestamp, endTimestamp, rewardsPerSecond, poolAddress]);
};

export const refundCredit = async (poolAddress: string): Promise<string> => {
  const payment = await usePayment();

  return await sendMultisig(payment, 'refundCredit', [poolAddress]);
};

export const refundCreditExtension = async (poolAddress: string): Promise<string> => {
  const payment = await usePayment();

  return await sendMultisig(payment, 'refundCreditExtension', [poolAddress]);
};

export const getAllowance = async (pairAddress: string): Promise<BigNumber> => {
  const payment = await getPayment();
  const user = await getAddress();

  const token = loadERC20(pairAddress);

  return await token.allowance(user, payment.address);
};

export const getPriceCampaign = async (userToken: string, wrapped?: boolean): Promise<BigNumber[]> => {
  const network = await getNetworkConfig();
  const payment = await getPayment();

  let methodExtend: any;

  // Compose function name based on chain
  // bsc has new contract, it will be available for all chains soon
  if (network.ID == 56) {
    methodExtend = `priceCampaignExtension${wrapped ? 'Wrapped' : ''}UsdtToken`;
  } else {
    methodExtend = `priceCampaignExtension`;
  }

  // bsc has new contract, it will be available for all chains soon
  const [short, medium, long, extend] = await Promise.all([
    payment.getCampaignPrice(30, userToken, network.ID == 56 ? wrapped : null),
    payment.getCampaignPrice(150, userToken, network.ID == 56 ? wrapped : null),
    payment.getCampaignPrice(180, userToken, network.ID == 56 ? wrapped : null),
    payment[methodExtend](),
  ]);

  return [short[0], medium[0], long[0], extend];
};

export const getDefaultPriceCampaign = async (wrapped?: boolean): Promise<BigNumber[]> => {
  const network = await getNetworkConfig();
  const payment = await getPayment();

  let method: any;
  let methodExtend: any;

  // Compose function name based on chain
  // bsc has new contract, it will be available for all chains soon
  if (network.ID == 56) {
    method = `priceCampaign${wrapped ? 'Wrapped' : ''}UsdtToken`;
    methodExtend = `priceCampaignExtension${wrapped ? 'Wrapped' : ''}UsdtToken`;
  } else {
    method = `priceCampaign`;
    methodExtend = `priceCampaignExtension`;
  }

  return [await payment[method](0), await payment[method](1), await payment[method](2), await payment[methodExtend]()];
};

export const isWhitelisted = async (userToken: string): Promise<boolean> => {
  const payment = await getPayment();

  return await payment.whitelist(userToken);
};

export const getCampaignCredits = async (userToken: string): Promise<BigNumber[]> => {
  const payment = await getPayment();

  return [
    await payment.creditsCampaigns(userToken, 0),
    await payment.creditsCampaigns(userToken, 1),
    await payment.creditsCampaigns(userToken, 2),
    await payment.creditsCampaignExtension(userToken),
  ];
};

async function getPayment(): Promise<ethers.Contract> {
  const network = await getNetworkConfig();
  const web3 = useWeb3();

  return new ethers.Contract(
    network.PAYMENT_ADDRESS,
    network.ID === 56 ? paymentArtifacts : paymentArtifactsOld,
    web3.provider,
  );
}
