import artifacts from './TokenERC20.json';
import { Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { sendMultisig } from '../helpers/sendMultisig';

export class TokenERC20 {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'TokenERC20Reward',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async deploy(): Promise<void> {
    this.contract = await this.loader.deploy([]);
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async transferTokens(wallet: string, multisig: string): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const amountToTransfer = await this.contract.balanceOf(multisig);
    return await sendMultisig(this.contract, 'transfer', [wallet, amountToTransfer]);
  }

  async getDecimals(): Promise<number> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return this.contract.decimals();
  }
}
