import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-light.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg';
import { ReactComponent as OpenLinkIcon } from '../../assets/icons/open-link-light.svg';
import { getExplorer } from '../../ducks/auth/selectors';
import { Badge, BadgeType } from '../Badge';
import { ClipboardTooltip } from '../ClipboardTooltip';

export interface ITableItemProps {
  id: string;
  date: string;
  type: string;
  // TODO: implement href or tx for open link
  amount?: {
    value: number | string;
    icon?: string;
    coin: string;
  };
  status: BadgeType; // TODO: Probably will be enum
  admins: {
    img: string;
    name: string;
    status: BadgeType; // TODO: Probably will be enum
    self: boolean;
  }[];
  onApproveClick: (id: string) => void;
  transactionHash?: string;
}

export const TableRow: React.FC<ITableItemProps> = ({
  id,
  date,
  type,
  amount,
  status,
  admins,
  onApproveClick,
  transactionHash,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onExpandClick = (): void => setIsOpen(!isOpen);
  const explorerLink = useSelector(getExplorer);

  const handleApproveClick = (): void => {
    onApproveClick(id);
  };

  return (
    <>
      <div className="table-layout table-row">
        <div className="table-column">{id}</div>
        <div className="table-column">{date}</div>
        <div className="table-column bold">{type}</div>
        <div className="table-column">
          {amount ? (
            <>
              {amount.value} {amount.icon && <img className="coin" src={amount.icon} />}&nbsp;
              <span className="bold">{amount.coin}</span>
            </>
          ) : (
            'n/a'
          )}
        </div>
        <div className="table-column">
          <Badge type={status} />
        </div>
        <div className="table-column icons">
          {transactionHash && (
            <>
              <ClipboardTooltip content={transactionHash || ''}>
                <CopyIcon className="copy-icon" />
              </ClipboardTooltip>{' '}
              <a href={`${explorerLink}tx/${transactionHash}`} rel="noopener noreferrer" target="_blank">
                <OpenLinkIcon />
              </a>
            </>
          )}{' '}
          <ExpandIcon onClick={onExpandClick} />
        </div>
      </div>
      {isOpen &&
        admins.map((admin) => (
          <div key={admin.name} className="table-user">
            <div />
            <div className="user">
              {admin.img && <img src={admin.img} />}
              {admin.name}
            </div>
            <div className="separator" />
            <div className="badge badge-container">
              <Badge type={admin.status} />
            </div>
            <div>
              {admin.self && (
                <button className="approve" onClick={handleApproveClick}>
                  Approve
                </button>
              )}
            </div>
            <div />
          </div>
        ))}
    </>
  );
};
