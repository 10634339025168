import React from 'react';
import { InfiniteStakingPoolList } from '../../../components/InfiniteStakingPoolList';
import { InfiniteStakingPool, InfiniteStakingState } from '../../../components/InfiniteStakingPool';

export const AwaitingInfiniteStaking: React.FC = () => {
  return (
    <InfiniteStakingPoolList
      title="Awaiting pools"
      subTitle="List of awaiting pools (new pools can take a few minutes to show up here)"
      filter={(pool: InfiniteStakingPool): boolean => pool.state === InfiniteStakingState.NOT_STARTED}
    />
  );
};
