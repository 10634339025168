import { BigNumber } from 'ethers';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PaymentPanel } from './components';
import { ToggleSwitch } from '../../../components/ToggleSwitch';
import {
  getAllowance,
  getPriceCampaign,
  getCreditsCampaignsByType,
  getCampaignCredits,
  getDefaultPriceCampaign,
} from '../../../sdk/payment';
import { getAuthAddress } from '../../../ducks/auth/selectors';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { currencyFormatter } from '../../../utils/currency-formatter';
import { networkConfigurations } from '../../../sdk/constants/networkConfig';
import { getNetworkId, getNetworkName } from '../../../sdk/helpers/network';
import { loadERC20 } from '../../../sdk/helpers/loadERC20';
import { ethers } from 'ethers';

export const Payment: React.FC = () => {
  const userToken = useSelector(getAuthAddress);
  const config = useSelector(getProjectConfigAll);

  const [networkId, setNetworkId] = useState<string>('');
  const [network, setNetwork] = useState<string>('');
  const [multisigAddress, setMultisigAddress] = useState<any>('');

  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchingType, setFetchingType] = useState<string>('');
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));

  const [expiryDate, setExpiryDate] = useState<string>('');
  const [showBlock, setShowBlock] = useState<string>('');
  const [usdtAddress, setUsdtAddress] = useState<string>('');
  const [tokenName, setTokenName] = useState<string>('');

  const [priceShort, setPriceShort] = useState<BigNumber>(BigNumber.from(0));
  const [priceMedium, setPriceMedium] = useState<BigNumber>(BigNumber.from(0));
  const [priceLong, setPriceLong] = useState<BigNumber>(BigNumber.from(0));
  const [priceExtension, setPriceExtension] = useState<BigNumber>(BigNumber.from(0));

  const [defaultPriceShort, setDefaultPriceShort] = useState<BigNumber>(BigNumber.from(0));
  const [defaultPriceMedium, setDefaultPriceMedium] = useState<BigNumber>(BigNumber.from(0));
  const [defaultPriceLong, setDefaultPriceLong] = useState<BigNumber>(BigNumber.from(0));
  const [defaultPriceExtension, setDefaultPriceExtension] = useState<BigNumber>(BigNumber.from(0));

  const [creditsShort, setCreditsShort] = useState<number>(0);
  const [creditsMedium, setCreditsMedium] = useState<number>(0);
  const [creditsLong, setCreditsLong] = useState<number>(0);
  const [creditsExtension, setCreditsExtension] = useState<number>(0);

  const [decimals, setDecimals] = useState<number>(6);

  const [wrappedSwitchSelected, setWrappedSwitchSelected] = useState<boolean>(false);

  useEffect(() => {
    getNetworkName().then((network) => {
      setNetwork(network);
      setMultisigAddress(
        config.multisig[network as 'eth' | 'bsc' | 'polygon' | 'avalanche' | 'ewc' | 'moonbeam' | 'songbird'],
      );
      getNetworkId().then((networkId) => {
        if (!networkConfigurations[networkId].PAYMENT_USDT_ADDRESS) setWrappedSwitchSelected(true);
        setNetworkId(networkId);
      });
    });

    return (): void => {
      setNetworkId('');
      setNetwork('');
      setMultisigAddress('');
    };
  }, []);

  const fetchData = async (): Promise<void> => {
    if (config && networkId && multisigAddress) {
      setFetching(true);

      const usdtAddress = wrappedSwitchSelected
        ? networkConfigurations[networkId].PAYMENT_WRAPPED_USDT_ADDRESS
        : networkConfigurations[networkId].PAYMENT_USDT_ADDRESS;
      setUsdtAddress(usdtAddress);

      // get decimals (bsc usdt has 18 decimals instead of 6)
      const tetherContract = loadERC20(usdtAddress);
      const decimalsBN = await tetherContract.getDecimals();

      setDecimals(Number(decimalsBN));

      setTokenName(wrappedSwitchSelected ? 'WUSDT' : 'USDT');

      const allowance = await getAllowance(usdtAddress);
      setAllowance(allowance);

      const campaignPrice = await getPriceCampaign(multisigAddress, wrappedSwitchSelected);
      const campaignDefaultPrice = await getDefaultPriceCampaign(wrappedSwitchSelected);
      const campaignCredits = await getCampaignCredits(multisigAddress);

      setPriceShort(campaignPrice[0]);
      setPriceMedium(campaignPrice[1]);
      setPriceLong(campaignPrice[2]);
      setPriceExtension(campaignPrice[3]);

      setDefaultPriceShort(campaignDefaultPrice[0]);
      setDefaultPriceMedium(campaignDefaultPrice[1]);
      setDefaultPriceLong(campaignDefaultPrice[2]);
      setDefaultPriceExtension(campaignDefaultPrice[3]);

      setCreditsShort(Number(campaignCredits[0]));
      setCreditsMedium(Number(campaignCredits[1]));
      setCreditsLong(Number(campaignCredits[2]));
      setCreditsExtension(Number(campaignCredits[3]));
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchData();

    return (): void => {
      setUsdtAddress('');
      setTokenName('');
      setCreditsShort(0);
      setCreditsMedium(0);
      setCreditsLong(0);
      setCreditsExtension(0);
      setDefaultPriceShort(BigNumber.from(0));
      setDefaultPriceMedium(BigNumber.from(0));
      setDefaultPriceLong(BigNumber.from(0));
      setDefaultPriceExtension(BigNumber.from(0));
      setPriceShort(BigNumber.from(0));
      setPriceMedium(BigNumber.from(0));
      setPriceLong(BigNumber.from(0));
      setPriceExtension(BigNumber.from(0));
    };
  }, [networkId, wrappedSwitchSelected]);

  const getPriceFromUsdt = (price: BigNumber): string => {
    return currencyFormatter(Number(price.div(BigNumber.from(10).pow(decimals))));
  };

  return (
    <div className="manage-payment">
      <style>{'\
        .content--sidebar{\
          display: none;\
        }\
      '}</style>
      <div className="flex wrappedSwitchContainer">
        <div>
          <h3 className="mt-0">Credits</h3>
          <p>In order to deploy a campaign you need to buy credits first.</p>
        </div>
        {networkId &&
          networkConfigurations[networkId].PAYMENT_WRAPPED_USDT_ADDRESS &&
          networkConfigurations[networkId].PAYMENT_USDT_ADDRESS && (
            <div className={`toggleSwitch checked-${wrappedSwitchSelected}`}>
              <span>Native</span>
              <ToggleSwitch
                onChange={(value: boolean): void => {
                  setWrappedSwitchSelected(value);
                  setUsdtAddress('');
                }}
                disabled={fetching}
                checked={wrappedSwitchSelected}
              />
              <span>Wrapped</span>
            </div>
          )}
      </div>
      {networkId && usdtAddress && decimals && (
        <div className="payment-cols mt-24">
          <PaymentPanel
            onPaid={(): any => {
              fetchData();
            }}
            setFetching={(value: boolean): void => {
              setFetching(value);
              setFetchingType('short');
            }}
            title="Short Campaign"
            description={`Required to deploy a campaign which will be shorter than 35 days.`}
            icon="usdt"
            token={tokenName}
            userToken={multisigAddress}
            tokenDecimals={decimals}
            tokenPriceAmount={getPriceFromUsdt(priceShort)}
            tokenPriceAmountBN={priceShort}
            tokenDefaultPriceAmountBN={defaultPriceShort}
            paymentAddress={networkConfigurations[networkId].PAYMENT_ADDRESS}
            tokenAddress={usdtAddress}
            availableCredits={creditsShort}
            disabled={fetching}
            fetching={!fetchingType || fetchingType === 'short'}
            allowance={allowance}
            wrapped={wrappedSwitchSelected}
            days={30}
          />
          <PaymentPanel
            onPaid={(): any => {
              fetchData();
            }}
            setFetching={(value: boolean): void => {
              setFetching(value);
              setFetchingType('medium');
            }}
            title="Medium Campaign"
            description={`Required to deploy a campaign which will be between 35 and 179 days.`}
            icon="usdt"
            token={tokenName}
            userToken={multisigAddress}
            tokenDecimals={decimals}
            tokenPriceAmount={getPriceFromUsdt(priceMedium)}
            tokenPriceAmountBN={priceMedium}
            tokenDefaultPriceAmountBN={defaultPriceMedium}
            paymentAddress={networkConfigurations[networkId].PAYMENT_ADDRESS}
            tokenAddress={usdtAddress}
            availableCredits={creditsMedium}
            disabled={fetching}
            fetching={!fetchingType || fetchingType === 'medium'}
            allowance={allowance}
            wrapped={wrappedSwitchSelected}
            days={100}
          />
          <PaymentPanel
            onPaid={(): any => {
              fetchData();
            }}
            setFetching={(value: boolean): void => {
              setFetching(value);
              setFetchingType('long');
            }}
            title="Long Campaign"
            description={`Required to deploy a campaign which will be longer than 179 days.`}
            icon="usdt"
            token={tokenName}
            userToken={multisigAddress}
            tokenDecimals={decimals}
            tokenPriceAmount={getPriceFromUsdt(priceLong)}
            tokenPriceAmountBN={priceLong}
            tokenDefaultPriceAmountBN={defaultPriceLong}
            paymentAddress={networkConfigurations[networkId].PAYMENT_ADDRESS}
            tokenAddress={usdtAddress}
            availableCredits={creditsLong}
            disabled={fetching}
            fetching={!fetchingType || fetchingType === 'long'}
            allowance={allowance}
            wrapped={wrappedSwitchSelected}
            days={200}
          />

          <PaymentPanel
            onPaid={(): any => {
              fetchData();
            }}
            setFetching={(value: boolean): void => {
              setFetching(value);
              setFetchingType('extend');
            }}
            title="Extend Campaign"
            description={`Required to extend a campaign. This will be used when a campaign is extended.`}
            icon="usdt"
            token={tokenName}
            userToken={multisigAddress}
            tokenDecimals={decimals}
            tokenPriceAmount={getPriceFromUsdt(priceExtension)}
            tokenPriceAmountBN={priceExtension}
            tokenDefaultPriceAmountBN={defaultPriceExtension}
            paymentAddress={networkConfigurations[networkId].PAYMENT_ADDRESS}
            tokenAddress={usdtAddress}
            availableCredits={creditsExtension}
            disabled={fetching}
            fetching={!fetchingType || fetchingType === 'extend'}
            allowance={allowance}
            wrapped={wrappedSwitchSelected}
            days={0}
          />
        </div>
      )}
    </div>
  );
};
