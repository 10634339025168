import { Dispatch } from 'redux';
import { addSnackbar } from '../ducks/snackbar/action';
import { METAMASK_POSSIBLE_ERRORS } from './errorCodes';

export function handleError<T>(
  promise: Promise<T>,
  dispatch: Dispatch,
  title: string,
  success: string,
  onSuccess?: () => void,
): Promise<void> {
  return promise
    .then(() => {
      dispatch(
        addSnackbar({
          type: 'info',
          title: title + ' success',
          content: success,
        }),
      );

      if (onSuccess) onSuccess();
    })
    .catch((e) => {
      handleMetamaskError(e, dispatch, title);
    });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleMetamaskError(e: any, dispatch: Dispatch, title: string): void {
  console.error(e);

  if (METAMASK_POSSIBLE_ERRORS[e.code] !== undefined) {
    dispatch(
      addSnackbar({
        type: 'error',
        title: title + ' failed',
        content: e.code === 4001 ? e.message : METAMASK_POSSIBLE_ERRORS[e.code].message,
      }),
    );
  } else {
    dispatch(
      addSnackbar({
        type: 'error',
        title: title + ' failed',
        content: 'Transaction failed due to an error',
      }),
    );
  }
}
