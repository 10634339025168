import React, { useState, useEffect } from 'react';

import { AddIconButton } from '../AddIconButton';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import { getSignature } from '../../utils/getSignature';

import { Icons } from '../../assets/icons';
import { useAccount } from 'wagmi';

export type CoingeckoInputField = 'text' | 'password';

export interface CoingeckoInputFieldProps {
  /*
      Title string
  */
  title?: string;
  /*
      Label string
  */
  label?: string;
  /*
      Input type
  */
  type: CoingeckoInputField;

  /*
      Optional default value
  */
  initialValue?: string;
  /*
       value
  */
  value?: any;
  /*
      Optional required
  */
  required?: boolean;
  /*
      Left icon name
  */
  icon?: string;
  /*
      Optional name string
  */
  name?: string;
  /*
      unique id
  */
  address: string;
  tokenSymbol: string;
  tokenIcon?: string;
  /*
      unique id
  */
  onChange?: (coinGeckoID: string) => void;
  id?: string;
  /*
      Optional right component handler
  */
  fileInputName: string;
  onChangeFile: (event: any) => void;
  fileValue: any;
  onError?: (value: boolean) => void;
  disabled?: boolean;
  idCoingecko?: string;
  autocomplete?: any;
  hideAddIconBtn?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const CoingeckoInputField: React.FC<CoingeckoInputFieldProps> = ({
  title,
  label,
  icon,
  required,
  type,
  onChange,
  onChangeFile,
  fileValue,
  fileInputName,
  value,
  address,
  tokenSymbol,
  tokenIcon,
  onError,
  disabled = false,
  idCoingecko = '',
  autocomplete,
  hideAddIconBtn,
  ...props
}) => {
  const dispatch = useDispatch();

  const { address: wallet } = useAccount();

  const [coinGeckoID, setCoinGeckoID] = useState(idCoingecko);
  const [coinGeckoUrl, setCoinGeckoUrl] = useState('');
  const [loadedCoinGeckoID, setLoadedCoinGeckoId] = useState(false);
  const [validCoingecko, setValidCoingecko] = useState(true);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [localIcon, setLocalIcon] = useState<string | undefined>();
  const web3 = useSelector(getEthersWeb3Provider);

  const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  const coingeckoUrlRegex = 'coingecko.com/.{2,4}/coins/';
  const coingeckoIdRegex = '^[a-z0-9][a-z0-9-]*$';

  useEffect(() => {
    if (!address) return;
    let isApiSubscribed = true;

    setCoinGeckoID('');
    setLoadedCoinGeckoId(false);
    axios
      .get(process.env.REACT_APP_API + '/logo/coingeckoid', {
        params: {
          address,
        },
      })
      .then((response) => {
        if (isApiSubscribed) {
          setCoinGeckoID(response.data.coinGeckoID);
          setValidCoingecko(true);
          if (response.data.coinGeckoID) setLoadedCoinGeckoId(true);
          onError?.(!!response.data.coinGeckoID);
        }
      })
      .catch((error) => {
        onError?.(false);
      });
    return (): void => {
      isApiSubscribed = false;
      setCoinGeckoID('');
      setLoadedCoinGeckoId(false);
      setValidCoingecko(false);
    };
  }, [address]);

  useEffect(() => {
    const clearSuggestionIfCoingeckoIdIsLoaded = (): void => {
      if (tokenSymbol) {
        setSuggestions([]);
      }
    };

    clearSuggestionIfCoingeckoIdIsLoaded();
  }, [tokenSymbol]);

  const handleChange = async (e: any): Promise<void> => {
    try {
      if (onChange) {
        setCoinGeckoUrl(e.target.value);
        if (!!e.target.value.match(urlRegex)) {
          setValidCoingecko(!!e.target.value.match(coingeckoUrlRegex));
        } else {
          setValidCoingecko(!!e.target.value.match(coingeckoIdRegex));
        }
        onError?.(!!e.target.value.match(coingeckoUrlRegex) || !!e.target.value.match(coingeckoIdRegex));
        const splitArr = e.target.value.split('/');
        const coinGeckoID = splitArr[splitArr.length - 1];
        setCoinGeckoID(coinGeckoID);
        onChange(coinGeckoID);
      }
      if (autocomplete) {
        if (e.target.value) {
          const suggestions = autocomplete.filter((item: any) => {
            return (
              item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
              item.symbol.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
            );
          });
          setSuggestions(suggestions);
        } else {
          setSuggestions([]);
        }
      }
    } catch (e) {}
    // loadTicker(e.target.value);
  };

  const handleFileChange = (e: any): void => {
    const uploadFile = async (): Promise<void> => {
      const { signature, timestamp } = await getSignature(web3, dispatch);

      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('address', address);
      formData.append('coinGeckoID', coinGeckoID);
      formData.append('wallet', wallet as string);
      formData.append('signature', signature);
      formData.append('timestamp', timestamp.toString());
      await axios.post(process.env.REACT_APP_API + '/logo/token-logo', formData).then((response) => {
        dispatch(
          addSnackbar({
            type: 'info',
            title: 'Upload success',
            content: response?.data.message,
          }),
        );
      });
      setLoadedCoinGeckoId(true);
      onChangeFile?.(e);
      setLocalIcon(URL.createObjectURL(e.target.files[0]));
    };

    uploadFile().catch((e) => {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Uploading file failed',
          content: e.response?.data ? e.response.data.error : 'There was an error during upload',
        }),
      );
    });
  };

  return (
    <>
      <div className="token-input-field-container" style={{ position: 'relative' }}>
        <div className="col">
          <div className="textField">
            <input
              type={type}
              style={{
                backgroundImage: `url('${
                  localIcon
                    ? localIcon
                    : tokenIcon
                    ? tokenIcon
                    : address
                    ? `${process.env.REACT_APP_ASSETS}/${address.toLocaleLowerCase()}`
                    : Icons['assets']
                }')`,
              }}
              className={`textField--input${(tokenSymbol || address) && validCoingecko ? ' token' : ''}`}
              placeholder={label}
              required={required}
              value={idCoingecko !== '' ? idCoingecko : loadedCoinGeckoID ? coinGeckoID : coinGeckoUrl}
              onChange={handleChange}
              disabled={disabled || loadedCoinGeckoID}
            />
            <span>{validCoingecko ? tokenSymbol : ''}</span>
            {title && <label>{title}</label>}
          </div>
        </div>
        {!hideAddIconBtn && !loadedCoinGeckoID && (
          <div className="col">
            <AddIconButton
              label="Add icon"
              maxHeight={500}
              maxWidth={500}
              required
              name={fileInputName}
              id={fileInputName}
              handleChange={handleFileChange}
              disabled={!validCoingecko || loadedCoinGeckoID || !coinGeckoID}
            />
          </div>
        )}
        {suggestions && suggestions.length > 0 && (
          <div className="autocomplete-suggestions">
            <div className="suggestions-list">
              {suggestions.map((item: any, index: number) => {
                return (
                  <div
                    className="suggestions-list-item"
                    key={index}
                    onClick={(): void => {
                      setCoinGeckoID(item.id);
                      setCoinGeckoUrl(item.id);
                      setSuggestions([]);
                      setValidCoingecko(true);
                      if (onChange) onChange(item.id);
                    }}
                  >
                    {`${item.name} (${item.symbol})`}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="text-field-validation textField header-small coingecko-error-message">
        {!validCoingecko && 'Invalid Coingecko ID or URL'}
      </div>
    </>
  );
};
