import spinner from './spinner.png';
import spinner_animated from './spinner.svg';
import check from './check.svg';
import check_blue from './check-blue.svg';
import times from './times.svg';
import left_arrow from './left-arrow.svg';
import right_arrow from './right-arrow.svg';
import assets from './assets.svg';
import transactions from './transactions.svg';
import admins from './admins.svg';
import manage_roles from './manage-roles.svg';
import caret_right from './caret-right.svg';
import copy_light from './copy-light.svg';
import open_link_light from './open-link-light.svg';
import copy_dark from './copy-dark.svg';
import open_link_dark from './open-link-dark.svg';
import failed from './failed.svg';
import finished from './finished.svg';
import awaiting from './awaiting.svg';
import approved from './approved.svg';
import declined from './declined.svg';
import red_bell from './red-bell.svg';
import info from './info.svg';
import hamburger from './hamburger.svg';
import expand_white from './expand-white.svg';
import expand from './expand.svg';
import wallet from './wallet.svg';
import arrow_left_black from './arrow-left-black.svg';
import arrow_left from './arrow-left.svg';
import arrow_right from './arrow-right.svg';
import declined_black from './declined-black.svg';
import edit from './edit.svg';
import wallet_black from './wallet-black.svg';
import plus from './plus.svg';
import bin from './bin.svg';
import approved_white from './approved-white.svg';
import email from './email.svg';
import project from './project.svg';
import symbol from './symbol.svg';
import token from './token.svg';
import tokens from './tokens.svg';
import website from './website.svg';
import arrow_long_right from './arrow-long-right.svg';
import arrow_long_left from './arrow-long-left.svg';
import overview from './overview.svg';
import data_table from './data-table.svg';
import download from './download.svg';
import line_arrow from './line-arrow.svg';
import staking from './staking.svg';
import pool from './pool.svg';
import pool_blue from './pool-blue.svg';
import calendar from './calendar.svg';
import calendar_black from './calendar-black.svg';
import discord_icon_input from './discord-icon-input.svg';
import twitter_icon_input from './twitter-icon-input.svg';
import telegram_icon_input from './telegram-icon-input.svg';
import clock from './clock.svg';
import alert_icon from './alert-icon.svg';
import lp_token from './lp-token.svg';
import reload from './reload.svg';
import update from './update.svg';
import close from './close.svg';
import albt from './albt.svg';
import eth from './eth.svg';
import weth from './eth.svg';
import usdt from './usdt.svg';
import uniswap from './uniswap.svg';
import balancer from './balancer.svg';
import pancakeswap from './pancakeswap.svg';
import unknown_coin from './unknown-coin.svg';
import quickswap from './quickswap.png';
import arrakis from './arrakis.png';
import avalanche from './avalanche.png';
import pangolin from './pangolin.svg';
import ewc from './ewc.png';
import solarflare from './solarflare.png';
import moonbeam from './moonbeam.png';
import songbird from './songbird.svg';
import infinity from './infinity.svg';
import blockchain from './blockchain.svg';
import color_picker from './color-picker.svg';
import world from './world.svg';
import roles from './roles.svg';
import subscription from './subscription.svg';
import nav_circle_ok from './nav-circle-ok.svg';
import allianceblock from './allianceblock.png';
import alliancedex from './alliancedex.svg';
import percent from './percent.svg';
import traderjoe from './traderjoe.svg';
import pass_through from './pass-through.svg';

export interface IconContainer {
  [key: string]: any;
}

export const Icons: IconContainer = {
  spinner,
  spinner_animated,
  check,
  check_blue,
  times,
  left_arrow,
  right_arrow,
  assets,
  transactions,
  admins,
  manage_roles,
  caret_right,
  copy_light,
  open_link_light,
  copy_dark,
  open_link_dark,
  failed,
  finished,
  awaiting,
  approved,
  declined,
  red_bell,
  info,
  hamburger,
  expand,
  expand_white,
  wallet,
  arrow_left_black,
  arrow_left,
  arrow_right,
  declined_black,
  edit,
  wallet_black,
  plus,
  bin,
  approved_white,
  email,
  project,
  symbol,
  token,
  tokens,
  website,
  arrow_long_right,
  arrow_long_left,
  overview,
  data_table,
  download,
  line_arrow,
  staking,
  pool,
  pool_blue,
  calendar,
  calendar_black,
  discord_icon_input,
  twitter_icon_input,
  telegram_icon_input,
  clock,
  alert_icon,
  lp_token,
  reload,
  update,
  close,
  albt,
  usdt,
  eth,
  weth,
  uniswap,
  balancer,
  pancakeswap,
  unknown_coin,
  quickswap,
  arrakis,
  avalanche,
  ewc,
  moonbeam,
  solarflare,
  pangolin,
  infinity,
  blockchain,
  color_picker,
  world,
  roles,
  subscription,
  nav_circle_ok,
  allianceblock,
  alliancedex,
  percent,
  traderjoe,
  songbird,
  pass_through,
};
