import React from 'react';

export interface ContentProps {
  sidebar: React.ReactNode;
  mainContent: React.ReactNode;
}

export const Content: React.FC<ContentProps> = ({ sidebar, mainContent }) => {
  const Sidebar: React.FC = () => <div className="content--sidebar">{sidebar}</div>;
  const MainContent: React.FC = () => <div className="content--main">{mainContent}</div>;

  return (
    <div className="content">
      <Sidebar />
      <MainContent />
    </div>
  );
};
