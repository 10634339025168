import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { App } from './pages/App';
import './i18n';
import { WagmiConfig } from 'wagmi';
import { wagmiClient } from './wagmi';

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <App />
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById('root'),
);

document.body.className = localStorage.getItem('theme') || 'light';
if (localStorage.getItem('brand_color')) {
  document.body.style.setProperty('--brand-primary', localStorage.getItem('brand_primary'));
  document.body.style.setProperty('--brand-hover', localStorage.getItem('brand_hover'));
  document.body.style.setProperty('--brand-secondary', localStorage.getItem('brand_secondary'));
  document.body.style.setProperty('--brand-thirtiary', localStorage.getItem('brand_thirtiary'));
  document.body.style.setProperty('--brand-element', localStorage.getItem('brand_element'));
  document.body.style.setProperty('--brand-background', localStorage.getItem('brand_background'));
  document.body.style.setProperty('--blue-shadow', '0px 7px 28px ' + localStorage.getItem('brand_color_shadow'));
  document.body.style.setProperty('--blue-tile-shadow', '0px 7px 19px ' + localStorage.getItem('brand_color_shadow'));
  document.body.style.setProperty(
    '--blue-tile-hover-shadow',
    '0px 7px 22px ' + localStorage.getItem('brand_color_shadow'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
