import { ethers, utils } from 'ethers';
import { getNetworkName } from './network';
import { getCoingeckoTokenInfoByTokenAddress } from './coingecko';
import { networkConfigurations } from '../constants/networkConfig';
import { loadERC20 } from './loadERC20';
import axios from 'axios';

export interface TokenInformation {
  network: string;
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  coinGeckoID: string;
  icon?: string;
  projectToken: boolean;
  coingeckoFound?: boolean;
}

interface TokenInformationParams {
  chainId: number;
  tokenAddress: string;
  provider: ethers.providers.Provider;
  rpcUrl?: string;
  coinGeckoId?: string;
}

export const getTokenInformation = async ({
  chainId,
  tokenAddress,
  provider,
  rpcUrl,
  coinGeckoId,
}: TokenInformationParams): Promise<TokenInformation> => {
  if (!utils.isAddress(tokenAddress) || networkConfigurations[chainId].COINGECKO_CHAIN_ID === '') {
    return {
      network: '',
      address: '',
      symbol: '',
      name: '',
      decimals: 18,
      coinGeckoID: '',
      projectToken: false,
    };
  }

  const tokenInfo = await getCoingeckoTokenInfoByTokenAddress(
    networkConfigurations[chainId].COINGECKO_CHAIN_ID,
    tokenAddress,
  );

  if (!tokenInfo || tokenInfo?.error === 'coin not found') {
    const token = loadERC20(tokenAddress);

    if (rpcUrl) {
      if (!token.contract) throw new Error('ERC20 token could not be loaded');
      token.contract = token.contract.connect(provider);
    }

    let response;
    if (!coinGeckoId) {
      try {
        response = await axios.get(process.env.REACT_APP_API + '/logo/coingeckoId', {
          params: {
            address: tokenAddress,
          },
        });

        if (response && response.data.coinGeckoID) coinGeckoId = response.data.coinGeckoID;
      } catch (e) {}
    }

    // retrieve symbol, name and decimals
    let symbol, name, decimals;

    try {
      symbol = await token.getSymbol();
      name = await token.getName();
      decimals = await token.getDecimals();
    } catch (e) {
      return {
        network: '',
        address: '',
        symbol: '',
        name: '',
        decimals: 18,
        coinGeckoID: '',
        projectToken: false,
      };
    }

    return {
      network: await getNetworkName(),
      address: tokenAddress,
      symbol: symbol,
      name: name,
      decimals: decimals,
      coinGeckoID: coinGeckoId || '',
      projectToken: false,
    };
  }

  return {
    network: await getNetworkName(),
    address: tokenAddress,
    symbol: tokenInfo.symbol.toUpperCase(),
    name: tokenInfo.name,
    decimals: tokenInfo.detail_platforms[tokenInfo.asset_platform_id].decimal_place,
    coinGeckoID: tokenInfo.id,
    icon: tokenInfo.image.thumb,
    projectToken: false,
    coingeckoFound: true,
  };
};
