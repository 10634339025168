import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StakingTitle } from '../../../components/StakingTitle';
import { Form } from './FormInput';

export const ContactEmail: React.FC = () => {
  const history = useHistory();
  const state: any = history.location.state;

  useEffect(() => {
    if (!state) history.push('/configuration/brand');
  }, [state]);

  const onSubmit = (data: any): void => {
    const newData = { ...state, contact_email: data };
    // TODO: move this logic to sagas
    localStorage.setItem('contact_email', data);
    history.push('/configuration/third_party_integrations', newData);
  };

  const goBack = (): void => {
    history.push('/configuration/tokens', { ...state });
  };

  return (
    <>
      <div className="container-configuration-form">
        <StakingTitle
          title="Configuration | Contact email"
          message="Please provide your contact email address for users to get help or contact customer service desk."
          steps={8}
          currentStep={5}
        />
        <Form onSubmit={onSubmit} goBack={goBack} savedField={state?.contact_email} />
      </div>
    </>
  );
};
