import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreatePool } from '../../../components/CreatePool';
import { addDecimals } from '../../../sdk/helpers/addDecimals';
import { getNetworkId, getNetworkName } from '../../../sdk/helpers/network';
import { NonCompoundingRewardsPoolInfinite } from '../../../sdk/staking-v2/NonCompoundingRewardsPoolInfinite';
import { CreatePoolState, OnCreatePoolProps, PoolType } from '../../../utils/helpers';

export const CreateInfiniteStakingPool: React.FC = () => {
  const [network, setNetwork] = useState('');
  const history = useHistory();
  const [state, setState] = useState<CreatePoolState | undefined>(undefined);

  useEffect(() => {
    getNetworkName().then(setNetwork);
  }, []);

  useEffect(() => {
    const s = history.location.state as any;
    if (s) {
      const rewardsPool = new NonCompoundingRewardsPoolInfinite();
      rewardsPool
        .load(s.poolAddress)
        .then(() => {
          s.pool = rewardsPool;
          setState(s);
        })
        .catch(console.error);
    }
  }, [history]);

  const createPool = async ({
    protocol,
    tokenAddress,
    rewards,
    userStakingLimit,
    stakingLimit,
    name,
    tokenInformation,
    locked,
  }: OnCreatePoolProps): Promise<any> => {
    const campaign = new NonCompoundingRewardsPoolInfinite();
    await campaign.deploy(
      tokenAddress,
      rewards.map((reward) => reward.info.address),
      addDecimals(userStakingLimit.amount, userStakingLimit.decimals),
      addDecimals(stakingLimit.amount, stakingLimit.decimals),
      name,
      locked,
    );

    if (!campaign.contract) throw new Error('Deploy failed');

    await campaign.contract.deployed();

    const info = {
      type: 'createInfiniteStakingCampaign',
      network,
      protocol,
      tokenAddresses: [tokenAddress],
      lpTokenAddress: tokenAddress,
      rewardsAddresses: rewards.map((reward) => reward.info),
      tokenInformation: tokenInformation,
    };

    const pool = new NonCompoundingRewardsPoolInfinite();
    await pool.load(campaign.contract.address);

    return { pool, info };
  };
  return (
    <React.Fragment>
      <CreatePool
        title="New infinite staking pool"
        subTitle="When a user stakes their tokens in an infinite staking pool, their tokens are locked for the entire durection of an epoch. After each epoch they receive their rewards"
        tokenTitle="Staking token"
        tokenLabel="Token address"
        onCreatePool={createPool}
        rewardsLimit={7}
        state={state}
        type={PoolType.INFINITE_STAKING}
        newForm={!history.location.state}
        singleSidedToken
      />
    </React.Fragment>
  );
};
