import React from 'react';
import { useHistory } from 'react-router-dom';
import { Main } from '../../../containers/Main';
import { StakingTitle } from '../../../components/StakingTitle';

export const CongratulationsScreen: React.FC = () => {
  setTimeout(() => {
    document.location.href = '/';
  }, 1500);
  return (
    <>
      <StakingTitle
        title="Congratulations"
        message="Thank you, your updated settings are saved."
        steps={8}
        currentStep={8}
      />
    </>
  );
};
