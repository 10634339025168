import React, { useEffect, useState } from 'react';
import { createDuration, Duration, durationToUnit, DurationUnits } from '../../sdk/helpers/duration';
import { formatDuration } from '../../utils/formatDuration';
import { formatNumber } from '../../utils/formatNumber';

export const PoolEpoch: React.FC<{ end: Date; start: Date; upcoming: boolean }> = ({ start, end, upcoming }) => {
  const [expirationDuration, setExpirationDuration] = useState(
    createDuration(end.getTime() - Date.now(), DurationUnits.milliseconds),
  );
  const duration = createDuration(end.getTime() - start.getTime(), DurationUnits.milliseconds);

  useEffect(() => {
    const interval = setInterval(() => {
      const _duration = upcoming
        ? start.getTime() - Date.now()
        : (((end.getTime() - Date.now()) % duration.value) + duration.value) % duration.value;

      setExpirationDuration(createDuration(_duration, DurationUnits.milliseconds));
    }, 1000);

    return (): void => clearInterval(interval);
  }, [end]);

  return (
    <div className="pool-expiration">
      <div className="pool-expiration-text flex pool-content-header">Epoch interval</div>
      <div className="pool-expiration-time flex">
        <div className="pool-expiration-time-date">{formatDuration(duration)}</div>
        <div className="pool-expiration-time-time">
          {formatNumber(Math.floor(durationToUnit(duration, DurationUnits.hours) % 24), 0)}h{' '}
          {formatNumber(Math.floor(durationToUnit(duration, DurationUnits.minutes) % 60), 0)}m{' '}
          {formatNumber(Math.floor(durationToUnit(duration, DurationUnits.seconds) % 60), 0)}s
        </div>
      </div>
      <div className="pool-expiration-text flex pool-content-header" style={{ marginTop: 24 }}>
        Time till next epoch
      </div>

      <div className="pool-expiration-time flex">
        <div className="pool-expiration-time-date">{formatDuration(expirationDuration)}</div>
        <div className="pool-expiration-time-time">
          {formatNumber(Math.floor(durationToUnit(expirationDuration, DurationUnits.hours) % 24), 0)}h{' '}
          {formatNumber(Math.floor(durationToUnit(expirationDuration, DurationUnits.minutes) % 60), 0)}m{' '}
          {formatNumber(Math.floor(durationToUnit(expirationDuration, DurationUnits.seconds) % 60), 0)}s
        </div>
      </div>
    </div>
  );
};
