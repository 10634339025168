import React from 'react';
import { Icons } from '../../../assets/icons';
import { Separator } from '../../../components/Separator';
import { Button } from '../../../components/Button';
import { List } from '../../../components/List';
import { ListItemUser } from '../../../components/List/ListItemUser';
import { BackendAdmin } from '../../../ducks/admins/slice';

interface IProps {
  onEditClick: () => void;
  signers: BackendAdmin[];
  processing: boolean;
}

export const AdminsView: React.FC<IProps> = ({ onEditClick, signers, processing }) => {
  return (
    <>
      <div className="admins-section">
        <h3>Admins</h3>
      </div>
      <div>Review or edit current configured administrators of your project&apos;s multisig wallet.</div>
      <List background={false}>
        {signers.map(({ name, address, email }) => (
          <ListItemUser key={address} name={name} address={address} email={email} compact={false} />
        ))}
      </List>

      <div className="admins-section">
        <h3>Transaction approval</h3>
      </div>
      <div className="paragraph-regular">
        51% of Administrators (i.e. 2 out of 2, 3 out of 4 or 4 out of 7) are required for approve a transaction to be
        executed.
      </div>
      <Separator
        leftComponent={<div>Current minimum required for approval</div>}
        rightComponent={<h3 className="admins-count">{Math.floor(signers.length / 2 + 1)} Admins</h3>}
      />
      <div className="admins-button-container">
        {processing ? (
          <Button label="Processing" color="process" size="large" icon="spinner" iconposition="left" spin shadow />
        ) : (
          <Button
            label="Edit"
            color="primary"
            size="large"
            icon="edit"
            iconposition="left"
            onClick={onEditClick}
            shadow
          />
        )}
      </div>
    </>
  );
};
