import React from 'react';
import { StakingPoolList } from '../../../components/StakingPoolList';

export const StakingExpired: React.FC = () => {
  return (
    <StakingPoolList
      title="Expired pools"
      subTitle="List of expired pools"
      filter={(pool: any): boolean => {
        return pool.start.getTime() !== 0 && pool.end < new Date();
      }}
    />
  );
};
