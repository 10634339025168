import React, { ChangeEvent, useState } from 'react';
import cls from 'classnames';
import { Icons } from '../../assets/icons';

export interface TextFieldProps {
  className?: string;
  label?: string;
  type: string;
  value: any;
  readOnly?: boolean;
  required?: boolean;
  icon?: string;
  iconUrl?: string;
  name?: string;
  id?: string;
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rightComponent?: React.ReactNode;
  onValidate?: (value: string) => string;
  onValidationChange?: (valid: boolean) => void;
  icon2?: string;
  disabled?: boolean;
  min?: string;
  step?: string;
  textArea?: boolean;
  maxLength?: number;
}

export const TextField: React.FC<TextFieldProps> = ({
  className,
  label,
  icon,
  iconUrl,
  icon2,
  rightComponent,
  onValidate,
  onValidationChange,
  textArea,
  maxLength,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { onChange, ...withoutOnChange } = props;

  return (
    <div className={cls(`textField ${className}`, { 'textField--rightComponent': rightComponent })}>
      {textArea ? (
        <textarea
          className="textField--textArea"
          placeholder={label}
          maxLength={maxLength}
          {...withoutOnChange}
          onChange={(e): void => {
            const error = onValidate ? onValidate(e.target.value) : '';
            setErrorMessage(error);
            if (onValidationChange) onValidationChange(error === '');
            if (onChange) onChange(e.target.value, e);
          }}
        />
      ) : (
        <input
          style={{ backgroundImage: iconUrl ? `url(${iconUrl})` : icon ? `url(${Icons[icon]})` : '' }}
          className="textField--input"
          placeholder={label}
          maxLength={maxLength}
          {...withoutOnChange}
          onChange={(e): void => {
            const error = onValidate ? onValidate(e.target.value) : '';
            setErrorMessage(error);
            if (onValidationChange) onValidationChange(error === '');
            if (onChange) onChange(e.target.value, e);
          }}
        />
      )}

      {label && <label>{label}</label>}
      <div className="text-field--rightComponent">{rightComponent}</div>
      <span
        className="text-field-validation header-small"
        style={{ backgroundImage: icon2 ? `url(${Icons[icon2]})` : '', backgroundRepeat: 'no-repeat' }}
      >
        {errorMessage}
      </span>
    </div>
  );
};
