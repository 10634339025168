import React from 'react';
import { Icons } from '../../../assets/icons/index';

export interface MetricIndicatorItemProps {
  title: string;
  value: string;
}

/**
 * Primary UI component for user interaction
 */
export const MetricIndicatorItem: React.FC<MetricIndicatorItemProps> = ({ title, value }) => {
  return (
    <div className={'metric-indicator-item'}>
      <span>{title}</span>
      <strong>{value}</strong>
    </div>
  );
};
