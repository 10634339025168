import React from 'react';
import { Icons } from '../../../assets/icons';
import { List } from '../../../components/List';
import { ListItemUser } from '../../../components/List/ListItemUser';
import { Separator } from '../../../components/Separator';
import { Button } from '../../../components/Button';
import { BackendAdmin } from '../../../ducks/admins/slice';

interface IProps {
  onSubmitClick: () => void;
  onBackClick: () => void;
  signers: BackendAdmin[];
  newSigners?: BackendAdmin[];
}

export const AdminsReview: React.FC<IProps> = ({ signers, newSigners = [], onBackClick, onSubmitClick }) => {
  const approves = Math.floor(signers.length / 2 + 1);

  return (
    <>
      <div className="admins-section">
        <h3>Review</h3>
      </div>
      <div>
        51% of Administrators (i.e. 2 out of 2, 3 out of 4 or 4 out of 7) are required for approve a transaction to be
        executed.
      </div>

      <div className="admins-review-section">
        <h5>Admins</h5>
      </div>

      <List background={false}>
        {newSigners.map(({ name, address, email }) => (
          <ListItemUser key={address} name={name} address={address} email={email} compact={false} />
        ))}
      </List>

      <div className="admins-review-section">
        <h5>TRANSACTION</h5>
      </div>
      <div className="admins-review-reviewers">
        <Separator
          leftComponent={
            <div>
              {approves} of {signers.length} administrators need to approve
            </div>
          }
          rightComponent={
            <div className="small-avatar-list">
              {signers.map(({ address }) => (
                <div key={address} className="admins-small-avatar-container">
                  {/*{signer && <img className="avatar-image" src={signer} />}*/}
                </div>
              ))}
            </div>
          }
        />
      </div>

      <div className="admins-button-container">
        <Button
          label="Go back"
          size="large"
          icon="arrow_left_black"
          iconposition="left"
          color="tertiary"
          onClick={onBackClick}
        />
        <Button label="Submit" size="large" icon="arrow_right" iconposition="right" onClick={onSubmitClick} shadow />
      </div>
    </>
  );
};
