import React, { ChangeEvent, useState } from 'react';
import { MetricIndicator } from '../../../components/MetricIndicator';
import { MetricIndicatorItem } from '../../../components/MetricIndicator/MetricIndicatorItem';
import { GraphButton } from '../../../components/GraphButton';
import { GraphButtonItem } from '../../../components/GraphButton/GraphButtonItem';
import { MetricsDataTable } from '../../../components/MetricsDataTable';
import { MetricsDataTableItem } from '../../../components/MetricsDataTable/MetricsDataTableItem';
import { Dropdown } from '../../../components/Dropdown';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { CoinChain } from '../../../components/CoinChain';
import { Icons } from '../../../assets/icons';

enum Screen {
  View,
  Send,
  Receive,
  Review,
}

export const DataTable: React.FC = () => {
  const [screen, setScreen] = useState<Screen>(Screen.View);

  const handleSendClick = (): void => {
    setScreen(Screen.Send);
  };

  const onPoolSelect = (value: string | number): void => {
    return;
  };

  return (
    <>
      <div className="data-table-cards">{/* TODO: its contain multiple card? */}</div>

      <div className="data-table-title">
        <h3>Distribution</h3>
        <div className="input-container">
          <Button color="primary" size="medium" label="Download CSV" icon="download" iconposition="left" />
          <img className="info-icon" src={Icons.info} />
        </div>
      </div>

      <div className="data-table-data">
        <div className="data-table-protocol-choose-container">
          <div className="data-table-dropdown-container">
            <label className="data-table-label">Protocol</label>
            <Dropdown
              height={150}
              label=""
              onChange={onPoolSelect}
              options={[
                {
                  icon: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
                  name: 'Uniswap',
                  value: 'Uniswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
                  name: 'PancakeSwap',
                  value: 'pancakeswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.png',
                  name: 'SushiSwap',
                  value: 'sushiswap',
                },
              ]}
              value="Choose Protocol"
            />
          </div>
          <div className="data-table-dropdown-container">
            <label className="data-table-label">Liquidity pool</label>
            <Dropdown
              height={150}
              label=""
              onChange={onPoolSelect}
              options={[
                {
                  icon: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
                  name: 'Uniswap',
                  value: 'Uniswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
                  name: 'PancakeSwap',
                  value: 'pancakeswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.png',
                  name: 'SushiSwap',
                  value: 'sushiswap',
                },
              ]}
              value="Choose Protocol"
            />
          </div>
        </div>
      </div>

      <div className="data-table-table">
        <MetricsDataTable>
          <MetricsDataTableItem
            userWallet={'User Wallet'}
            protocol={'Protocol'}
            pool={
              <CoinChain
                data={[
                  {
                    icon: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
                    name: 'UNI',
                  },
                  {
                    icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
                    name: 'CAKE',
                  },
                  {
                    icon: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.png',
                    name: 'SUSHI',
                  },
                ]}
              />
            }
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            lptStakedCurrency="ALBT"
            lptStakedAmount="28.3248"
            lptStakedOnCurrency="ALBT"
            lptStakedOnAmount="28.3248"
          />
          <MetricsDataTableItem
            userWallet={'User Wallet'}
            protocol={'Protocol'}
            pool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            lptStakedCurrency="ALBT"
            lptStakedAmount="28.3248"
            lptStakedOnCurrency="ALBT"
            lptStakedOnAmount="28.3248"
          />
          <MetricsDataTableItem
            userWallet={'User Wallet'}
            protocol={'Protocol'}
            pool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            lptStakedCurrency="ALBT"
            lptStakedAmount="28.3248"
            lptStakedOnCurrency="ALBT"
            lptStakedOnAmount="28.3248"
          />
          <MetricsDataTableItem
            userWallet={'User Wallet'}
            protocol={'Protocol'}
            pool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            lptStakedCurrency="ALBT"
            lptStakedAmount="28.3248"
            lptStakedOnCurrency="ALBT"
            lptStakedOnAmount="28.3248"
          />
          <MetricsDataTableItem
            userWallet={'User Wallet'}
            protocol={'Protocol'}
            pool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            lptStakedCurrency="ALBT"
            lptStakedAmount="28.3248"
            lptStakedOnCurrency="ALBT"
            lptStakedOnAmount="28.3248"
          />
        </MetricsDataTable>
      </div>
    </>
  );
};
