import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import artifacts from './LiquidityMiningCampaignFactory.json';
import { BigNumber, Contract } from 'ethers';
import { MULTISIG_DOES_NOT_EXIST } from '../constants/errors';
import { ContractLoader } from '../helpers/ContractLoader';
import { durationToBlocks, getFutureBlockNumber, Duration, dateToBlock } from '../helpers/duration';
import { getNetworkConfig } from '../helpers/network';
import { loadContract } from '../helpers/loadContract';
import { sendMultisig } from '../helpers/sendMultisig';

export class LiquidityMiningCampaignFactory {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'LiquidityMiningCampaignFactoryV1',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async deploy(): Promise<void> {
    this.contract = await this.loader.deploy([]);
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async deployCampaign(
    stakingToken: string,
    rewardsTokens: Array<string>,
    rewards: Array<BigNumber>,
    albtAddress: string,
    stakeLimit: BigNumber,
    contractStakeLimit: BigNumber,
    start: Date,
    end: Date,
  ): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const startBlock = await dateToBlock(start);
    const endBlock = await dateToBlock(end);

    const numBlocks = endBlock - startBlock;
    const rewardsPerBlock = rewards.map((reward) => reward.div(numBlocks).toString());

    return await sendMultisig(this.contract, 'deploy', [
      stakingToken,
      startBlock,
      endBlock,
      rewardsTokens,
      rewardsPerBlock,
      albtAddress,
      stakeLimit,
      contractStakeLimit,
    ]);
  }

  async extendRewardPool(duration: Duration, rewards: Array<BigNumber>, rewardPoolAddress: string): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const numBlocks = await durationToBlocks(duration);
    const rewardsPerBlock = rewards.map((reward) => reward.div(numBlocks).toString());

    const { futureBlockNumber } = await getFutureBlockNumber(duration);

    return await sendMultisig(this.contract, 'extendRewardPool', [
      futureBlockNumber,
      rewardsPerBlock,
      rewardPoolAddress,
    ]);
  }

  async addLockSchemes(lockSchemaAddresses: Array<string>, rewardPoolAddress: string): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'setLockSchemesToLMC', [lockSchemaAddresses, rewardPoolAddress]);
  }

  async withdrawRewardsLeftovers(rewardPoolAddress: string): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'withdrawRewardsLeftovers', [rewardPoolAddress]);
  }

  async transferOwnerShipToMultisig(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const wallet = loadContract('wallet') as MultiSigWallet;

    if (wallet === undefined) {
      throw new Error(MULTISIG_DOES_NOT_EXIST);
    }

    const transferTx = await this.contract.transferOwnership(wallet.address);
    await transferTx.wait();

    return await sendMultisig(this.contract, 'acceptOwnership', []);
  }
}
