import artifacts from './LockScheme.json';
import { Contract } from 'ethers';
import { link } from '../../utils/link';
import { ContractLoader } from '../helpers/ContractLoader';
import { durationToBlocks, Duration } from '../helpers/duration';

export class LockScheme {
  contract: Contract | null;
  loader: ContractLoader;

  constructor(calculatorAddress: string) {
    this.loader = new ContractLoader({
      name: 'LockSchemeV1',
      bytecode: link(artifacts.bytecode, 'PercentageCalculator', calculatorAddress),
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async deploy(lockPeriod: Duration, rampUpPeriod: Duration, bonusPercent: number, lmcContract: string): Promise<void> {
    this.contract = await this.loader.deploy([
      await durationToBlocks(lockPeriod),
      await durationToBlocks(rampUpPeriod),
      Math.floor(bonusPercent * 1000),
      lmcContract,
    ]);
  }

  async deployNoLock(lockPeriod: number, lmcContract: string): Promise<void> {
    this.contract = await this.loader.deploy([lockPeriod, lockPeriod - 1, 0, lmcContract]);
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }
}
