import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AuthState {
  INITIALIZATION = 'initialization',
  AUTHORIZED = 'authorized',
  GUEST = 'guest',
  CHECKING = 'checking',
  DISCONNECTED = 'disconnected',
  UNSUPPORTED_NETWORK = 'unsupported_network',
}

interface AuthReducerState {
  state: AuthState;
  token: null | string;
  roles: string[];
  name: string;
  email: string;
  surname: string;
  publicKey: string;
  network: string;
  explorer: string;
}

const initialState: AuthReducerState = {
  state: AuthState.INITIALIZATION,
  token: null,
  roles: [],
  name: '',
  email: '',
  surname: '',
  publicKey: '',
  network: 'eth',
  explorer: '',
};

interface StoreAuthorizationPayload {
  token: string;
  roles: string[];
  name: string;
  email: string;
  surname: string;
  publicKey?: string;
  network?: string;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorizeState: (state, action: PayloadAction<AuthState>): void => {
      state.state = action.payload;
    },
    addAuthorizationRole: (state, action: PayloadAction<string>): void => {
      state.roles.push(action.payload);
    },
    setPublicKey: (state, action: PayloadAction<string>): void => {
      state.publicKey = action.payload;
    },
    setNetwork: (state, action: PayloadAction<string>): void => {
      state.network = action.payload;
    },
    setExplorer: (state, action: PayloadAction<string>): void => {
      state.explorer = action.payload;
    },
    setName: (state, action: PayloadAction<string>): void => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>): void => {
      state.email = action.payload;
    },
    storeAuthorization: {
      reducer: (state, action: PayloadAction<StoreAuthorizationPayload>): void => {
        state.state = AuthState.AUTHORIZED;
        state.roles = action.payload.roles;
        state.token = action.payload.token;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.surname = action.payload.surname;
        if (action.payload.publicKey) state.publicKey = action.payload.publicKey;
      },
      prepare: (
        token: string,
        roles: string[],
        name: string,
        email: string,
        surname: string,
        publicKey?: string,
      ): { payload: StoreAuthorizationPayload } => ({
        payload: { token, roles, name, email, surname, publicKey },
      }),
    },
    removeAuthorization: (state): void => {
      state.state = AuthState.GUEST;
      state.roles = [];
      state.token = null;
      state.name = '';
      state.email = '';
      state.surname = '';
      state.publicKey = '';
    },
    checkAuthorization: (state): void => {
      state.state = AuthState.CHECKING;
    },
    offAuthorization: (state): void => {
      state.state = AuthState.DISCONNECTED;
    },
  },
});
