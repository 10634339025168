import React from 'react';

export const MetricIndicator: React.FC<any> = ({ children }) => {
  const childrenItems = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }

    return child;
  });

  return <div className="metric-indicator">{childrenItems}</div>;
};
