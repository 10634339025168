import React from 'react';
import { Svg } from '../../assets/svg';

export type TextFieldType = 'text' | 'password';

export interface TextFieldProps {
  value: string;
  selectValue: (e: string) => void;
}

/**
 * Primary UI component for user interaction
 */
const images = [
  '#FA7878',
  '#FFAB6F',
  '#F3D772',
  '#D6F070',
  '#B0F070',
  '#8BFC89',
  '#77FCBC',
  '#F32222',
  '#FF7B1B',
  '#F0BC00',
  '#B1DD01',
  '#7EE01B',
  '#3EE63B',
  '#22EA8A',
  '#6AFBFB',
  '#66DAFF',
  '#669AFF',
  '#9378FF',
  '#BA75FF',
  '#EC75FF',
  '#FF5FA2',
  '#0EE5E5',
  '#05B3EB',
  '#5466FD',
  '#6943FF',
  '#932CFB',
  '#D829F4',
  '#F51573',
];

export const ColorSelector: React.FC<TextFieldProps> = ({ value, selectValue }) => {
  const onClickFunc = (e: any): void => {
    selectValue && e.target.id && selectValue(e.target.id);
  };

  const renderImages = images.map((res, key) => {
    return (
      <div key={key} className="image-container" onClick={onClickFunc}>
        <div className={`color-circle ${value === res ? 'active' : ''}`} id={res} style={{ background: res }}></div>
        {value === res && <img className="check" src={Svg['theme_check_blue']} alt="theme_check_blue" />}
      </div>
    );
  });
  return <div className="color-selector-container">{renderImages}</div>;
};
