import React, { useState, useEffect } from 'react';
import { MetricIndicator } from '../../../components/MetricIndicator';
import { MetricIndicatorItem } from '../../../components/MetricIndicator/MetricIndicatorItem';
import { getProjectConfigAll } from '../../../ducks/project/selectors';
import { Icons } from '../../../assets/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getNetworkId } from '../../../sdk/helpers/network';

enum Screen {
  View,
  Send,
  Receive,
  Review,
}

const metricsInitialState = {
  impermanentLoss: '0',
  netGain: '0',
  tokensStaked: '0',
  tokensStakedUSD: '0',
  tradingFeesUSD: '0',
  transactionsCount: '0',
  walletsCount: '0',
};

export const Overview: React.FC = () => {
  const [screen, setScreen] = useState<Screen>(Screen.View);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const [metrics, setMetrics] = useState(metricsInitialState);

  const config = useSelector(getProjectConfigAll);
  useEffect(() => {
    let isApiSubscribed = true;
    const getMetrics = async (): Promise<void> => {
      try {
        setMetricsLoading(true);

        getNetworkId().then((id) => {
          const apiUrl = process.env.REACT_APP_DATA_API;
          switch (id) {
            case '97':
            case '56':
              axios.get(apiUrl === undefined ? '' : apiUrl + `/bsc/adminpanel?tenant=${config.name}`).then((result) => {
                if (isApiSubscribed && result.data.status) {
                  setMetrics(result.data.data);
                }
              });
              break;
            case '43113':
              axios
                .get(apiUrl === undefined ? '' : apiUrl + `/avalanche/adminpanel?tenant=${config.name}`)
                .then((result) => {
                  if (isApiSubscribed && result.data.status) {
                    setMetrics(result.data.data);
                  }
                });
              break;
            case '137':
              axios
                .get(apiUrl === undefined ? '' : apiUrl + `/polygon/adminpanel?tenant=${config.name}`)
                .then((result) => {
                  if (isApiSubscribed && result.data.status) {
                    setMetrics(result.data.data);
                  }
                });
              break;
            case '246':
              axios.get(apiUrl === undefined ? '' : apiUrl + `/ewc/adminpanel?tenant=${config.name}`).then((result) => {
                if (isApiSubscribed && result.data.status) {
                  setMetrics(result.data.data);
                }
              });
              break;
            case '1284':
              axios
                .get(apiUrl === undefined ? '' : apiUrl + `/moonbeam/adminpanel?tenant=${config.name}`)
                .then((result) => {
                  if (isApiSubscribed && result.data.status) {
                    setMetrics(result.data.data);
                  }
                });
              break;
            case '19':
              axios
                .get(apiUrl === undefined ? '' : apiUrl + `/songbird/adminpanel?tenant=${config.name}`)
                .then((result) => {
                  if (isApiSubscribed && result.data.status) {
                    setMetrics(result.data.data);
                  }
                });
              break;
            default:
              axios.get(apiUrl === undefined ? '' : apiUrl + `/eth/adminpanel?tenant=${config.name}`).then((result) => {
                if (isApiSubscribed && result.data.status) {
                  setMetrics(result.data.data);
                }
              });
          }
        });
      } catch (e) {
        console.error(e);
        setMetricsLoading(false);
      } finally {
        setMetricsLoading(false);
      }
    };
    getMetrics();

    return (): void => {
      setMetrics(metricsInitialState);
      setMetricsLoading(false);
      isApiSubscribed = false;
    };
  }, []);

  const handleSendClick = (): void => {
    setScreen(Screen.Send);
  };

  const onPoolSelect = (value: string | number): void => {
    return;
  };

  const graphSeries = [
    {
      name: 'Transacted',
      data: [0, 400000, 200000, 300000, 500000, 200000, 500000, 700000],
    },
  ];
  const graphOptions = {
    chart: {
      id: 'basic-bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#5466fd'],
    stroke: {
      show: true,
      width: 1,
      dashArray: 0,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      row: {
        colors: ['transparent'],
        opacity: 0.5,
      },
      column: {
        colors: ['transparent'],
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [0, 'Sep 2020', 'Oct 2020', 'Nov 2020', 'Dec 2020', 'Jan 2021', 'Feb 2021', 'Mar 2021', 'Apr 2021'],
    },
  };

  return (
    <>
      <div className="overview-title">
        <h3>Overview</h3>
      </div>

      <div className="overview-data">
        {metricsLoading ? (
          <div>Loading...</div>
        ) : (
          <MetricIndicator>
            <MetricIndicatorItem title="Number of Transactions" value={metrics && metrics.transactionsCount} />
            <MetricIndicatorItem title="Number of Wallets" value={metrics && metrics.walletsCount} />
            <MetricIndicatorItem
              title="Trading Fees"
              value={`$ ${metrics && parseFloat(metrics.tradingFeesUSD).toFixed(2)}`}
            />
            <MetricIndicatorItem
              title="Tokens Staked"
              value={`$ ${metrics && parseFloat(metrics.tokensStakedUSD).toFixed(2)}`}
            />
            <MetricIndicatorItem
              title="Net Gain/Loss"
              value={`$ ${metrics && parseFloat(metrics.netGain).toFixed(2)}`}
            />
            <MetricIndicatorItem
              title="Impermanent Loss"
              value={`$ ${metrics && parseFloat(metrics.impermanentLoss).toFixed(2)}`}
            />
          </MetricIndicator>
        )}
        {/*
        <GraphButton>
          <GraphButtonItem
            onClick={(): void => {
              alert('On click triggered.');
            }}
            title="Total"
            active={true}
          />
          <GraphButtonItem title="Avg" active={false} />
          <GraphButtonItem title="" active={false} />
          <GraphButtonItem title="24h" active={false} />
          <GraphButtonItem title="7d" active={false} />
          <GraphButtonItem title="14d" active={false} />
          <GraphButtonItem title="30d" active={true} />
          <GraphButtonItem title="90d" active={false} />
          <GraphButtonItem title="180d" active={false} />
          <GraphButtonItem title="1y" active={false} />
          <GraphButtonItem title="MAX" active={false} />
        </GraphButton>


        <div className="graph-placeholder">
          <div id="chart">
            <Chart options={graphOptions} series={graphSeries} type="area" height={350} />
          </div>
        </div>


        <div className="overview-protocol-choose-container">
          <div className="overview-dropdown-container">
            <label className="overview-label">Protocol</label>
            <Dropdown
              height={150}
              label=""
              onChange={onPoolSelect}
              options={[
                {
                  icon: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
                  name: 'Uniswap',
                  value: 'Uniswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
                  name: 'PancakeSwap',
                  value: 'pancakeswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.png',
                  name: 'SushiSwap',
                  value: 'sushiswap',
                },
              ]}
              value="Choose Protocol"
            />
          </div>
          <div className="overview-dropdown-container">
            <label className="overview-label">Liquidity pool</label>
            <Dropdown
              height={150}
              label=""
              onChange={onPoolSelect}
              options={[
                {
                  icon: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
                  name: 'Uniswap',
                  value: 'Uniswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
                  name: 'PancakeSwap',
                  value: 'pancakeswap',
                },
                {
                  icon: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.png',
                  name: 'SushiSwap',
                  value: 'sushiswap',
                },
              ]}
              value="Choose Protocol"
            />
          </div>
        </div>
        */}
      </div>
      {/*
      <div className="overview-subtitle">
        <h4>Campaigns</h4>
        <div className="input-container">
          <TextField value={''} label={'Search'} type={'text'} icon={'overview'} />
          <img className="info-icon" src={Icons.info} />
        </div>
      </div>

      <div className="overview-table">
        <MetricsTable>
          <MetricsTableItem
            protocol={'Protocol'}
            liquidityPool={
              <CoinChain
                data={[
                  {
                    icon: 'https://cryptologos.cc/logos/uniswap-uni-logo.png',
                    name: 'UNI',
                  },
                  {
                    icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png',
                    name: 'CAKE',
                  },
                  {
                    icon: 'https://cryptologos.cc/logos/sushiswap-sushi-logo.png',
                    name: 'SUSHI',
                  },
                ]}
              />
            }
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            rewardsCurrency="ALBT"
            rewardsAmount="28.3248"
          />
          <MetricsTableItem
            protocol={'Protocol'}
            liquidityPool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            rewardsCurrency="ALBT"
            rewardsAmount="28.3248"
          />
          <MetricsTableItem
            protocol={'Protocol'}
            liquidityPool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            rewardsCurrency="ALBT"
            rewardsAmount="28.3248"
          />
          <MetricsTableItem
            protocol={'Protocol'}
            liquidityPool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            rewardsCurrency="ALBT"
            rewardsAmount="28.3248"
          />
          <MetricsTableItem
            protocol={'Protocol'}
            liquidityPool={'CoinChain'}
            startsDate={'Mar 21, 21'}
            startsTime={'11:56 CET'}
            endsDate={'Mar 21, 21'}
            endsTime={'11:56 CET'}
            rewardsCurrency="ALBT"
            rewardsAmount="28.3248"
          />
        </MetricsTable>
      </div>
      */}
    </>
  );
};
